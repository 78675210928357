/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
import { useEffect } from 'react'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { heapEnvId } from '@/utils/constants.ts'

export const useHeapAnalytics = () => {
  const { user } = useDantiAuth()

  useEffect(() => {
    // @ts-expect-error heap is untyped
    if (window.heap && window.heap.load) {
      // @ts-expect-error heap is untyped
      window.heap.load(heapEnvId)
    }
  }, [])

  useEffect(() => {
    // @ts-expect-error heap is untyped
    if (window.heap && window.heap.identify) {
      // @ts-expect-error heap is untyped
      window.heap.identify(user?.profile.email, {})
    }
  }, [user?.profile.email])
}
