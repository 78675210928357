import type { VisualizationModeOptions } from '@/stores/map-store.ts'
import type mapboxgl from 'mapbox-gl'
import type { MutableRefObject } from 'react'

export const addLayers = (
  map: MutableRefObject<mapboxgl.Map | null>,
  visualizationMode: VisualizationModeOptions,
) => {
  if (!map.current) {
    return
  }

  if (map.current?.getLayer('extents-fills')) {
    return
  }

  const layers = map.current?.getStyle().layers
  // Find the index of the first symbol layer in the map style.
  let firstSymbolId
  for (const layer of layers) {
    if (layer.type === 'symbol') {
      firstSymbolId = layer.id
      break
    }
  }

  map.current?.addLayer(
    {
      id: 'extents-fills',
      type: 'fill',
      source: 'extents',
      layout: {
        visibility: visualizationMode === 'extents' ? 'visible' : 'none',
      },
      paint: {
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0,
          0.1,
        ],
      },
    },
    firstSymbolId,
  )

  map.current?.addLayer(
    {
      id: 'extents-outlines',
      type: 'line',
      source: 'extents',
      layout: {
        visibility: visualizationMode === 'extents' ? 'visible' : 'none',
      },
      paint: {
        'line-color': '#202020',
        'line-opacity': 0.8,
      },
    },
    firstSymbolId,
  )

  map.current?.addLayer(
    {
      id: 'news-point-layer',
      type: 'circle',
      source: 'news-point',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-color': [
          'case',
          ['==', ['get', 'category'], 'PUBLICATION'],
          '#FFA726',
          '#FFA726',
        ],
        'circle-radius': 4,
      },
    },
    firstSymbolId,
  )

  map.current?.addLayer(
    {
      id: 'fire-point-layer',
      type: 'circle',
      source: 'fire-point',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-color': 'rgba(211, 47, 47, 1)',
        'circle-radius': 4,
      },
    },
    firstSymbolId,
  )

  map.current?.addLayer(
    {
      id: 'ship-point-layer',
      type: 'circle',
      source: 'ship-point',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-color': '#00A5B0',
        'circle-radius': 4,
      },
    },
    firstSymbolId,
  )

  map.current?.addLayer(
    {
      id: 'social-point-layer',
      type: 'circle',
      source: 'social-point',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-color': 'rgba(94, 53, 177, 1)',
        'circle-radius': 4,
      },
    },
    firstSymbolId,
  )

  map.current?.addLayer(
    {
      id: 'hexgrid-fills',
      type: 'fill',
      source: 'hexgrid',
      layout: { visibility: visualizationMode === 'grid' ? 'visible' : 'none' },
      paint: { 'fill-opacity': 0.5 },
    },
    firstSymbolId,
  )
  map.current?.addLayer(
    {
      id: 'hexgrid-outlines',
      type: 'line',
      source: 'hexgrid',
      layout: { visibility: visualizationMode === 'grid' ? 'visible' : 'none' },
      paint: {
        'line-color': '#202020',
        'line-opacity': 0,
        'line-width': 3,
      },
    },
    firstSymbolId,
  )
}
