import { type FC, useEffect, useState } from 'react'
import { Group, Image, SimpleGrid, Stack, Text } from '@mantine/core'
import { AutoAskWidget } from '@/components/lib/single-address/AutoAskWidget.tsx'
import type { PropertyData } from '@/hooks/use-single-address-components.ts'

type BasicInfoPanelProps = {
  propertyData: PropertyData
  mlsImages: string[]
}
export const BasicInfoPanel: FC<BasicInfoPanelProps> = ({
  propertyData,
  mlsImages,
}) => {
  const [mainImage, setMainImage] = useState(mlsImages[0])

  useEffect(() => {
    mlsImages[0] ? setMainImage(mlsImages[0]) : setMainImage('')
  }, [mlsImages])
  return (
    <Stack gap={2} style={{ borderBottom: '1px solid #bdbdbd', padding: 8 }}>
      <Group gap="xs">
        <Text style={{ fontWeight: 'bold' }}>Basic Info</Text>
        <AutoAskWidget prompt="Please give me a summary of this property, the lot, and the buildings." />
      </Group>
      <Group gap={18} style={{ position: 'relative', fontSize: 12 }}>
        <Image src={mainImage} style={{ width: '30%' }} />
        <SimpleGrid
          cols={4}
          verticalSpacing="xs"
          spacing={1}
          style={{ alignSelf: 'start', marginTop: 12 }}
          w="65%"
        >
          <Text>BEDS</Text>
          <Text>
            {propertyData.attomProperty?.building?.rooms?.beds ??
              propertyData.attomPermit?.building?.rooms?.beds}
          </Text>

          <Text>LOT SIZE</Text>
          <Text>{propertyData.attomProperty?.lot?.lotSize2}</Text>

          <Text>BATHS</Text>
          <Text>
            {propertyData.attomProperty?.building?.rooms?.bathsTotal ??
              propertyData.attomPermit?.building?.rooms?.beds}
          </Text>

          <Text>YEAR BUILT</Text>
          <Text>
            {propertyData.attomProperty?.summary?.yearBuilt ??
              propertyData.attomPermit?.summary?.yearBuilt}
          </Text>

          <Text>SQ. FT.</Text>
          <Text>
            {propertyData.attomProperty?.building?.size?.bldgSize ??
              propertyData.attomPermit?.building?.size?.universalSize}
          </Text>

          <Text>ROOF:</Text>
          <Text>
            {propertyData.attomProperty?.building?.construction?.roofCover}
          </Text>
        </SimpleGrid>
      </Group>
      <Text style={{ fontSize: 12, fontWeight: 'bold' }}>PROPERTY IMAGES</Text>
      <SimpleGrid cols={8} verticalSpacing={2} spacing={8}>
        {mlsImages.map((im) => (
          <Image
            key={`im_${im}`}
            src={im}
            style={{
              opacity: im === mainImage ? 1 : 0.5,
              cursor: im === mainImage ? 'auto' : 'pointer',
            }}
            onClick={() => setMainImage(im)}
          />
        ))}
      </SimpleGrid>
    </Stack>
  )
}
