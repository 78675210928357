import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Avatar,
  Badge,
  Center,
  Flex,
  Group,
  Paper,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultImage } from '@/components/lib/ResultImage.tsx'
import { openAddToCollectionModal } from '@/components/modals/openers.tsx'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import type React from 'react'

import styles from './thumbnail.module.css'

export interface ThumbnailBaseProps
  extends React.HTMLAttributes<HTMLImageElement> {
  item: OrthoImageryLayerGroup | ResultWithId
  children?: React.ReactNode
  menu?: React.ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const ThumbnailBase = ({
  item,
  children,
  menu,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ThumbnailBaseProps) => {
  const { source, authoredOn } = item

  return (
    <Paper
      className={styles['hiddenPanel']}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Badge className={styles['dateBadge']} size="sm" color="gray.0">
        {dayjs(authoredOn).utc().format('MMM D, YYYY HH:mm').toUpperCase()} Z
      </Badge>

      <Center h="100%" onClick={onClick}>
        <Center inline h="100%" pos="relative">
          {children}
        </Center>
      </Center>

      <Flex className={styles['reveal']} pos="absolute" inset={0}>
        <Flex pos="relative" h="100%" w="100%">
          {menu}
          <Group pos="absolute" bottom=".5rem" left=".5rem" gap={0}>
            <Avatar radius="100%" size="md" mr="xs">
              {source
                .split(/[^\dA-Za-z]/g)
                .map((word) => word.charAt(0))
                .join('')}
            </Avatar>
            <Text
              unselectable="on"
              size="sm"
              c="gray.0"
              style={{ textShadow: '1px 1px 2px #000a' }}
            >
              {source}
            </Text>
          </Group>
        </Flex>
      </Flex>
    </Paper>
  )
}

export interface ThumbnailProps extends React.HTMLAttributes<HTMLImageElement> {
  item: ResultWithId
  onClick?: () => void
}

export const Thumbnail = ({ item, onClick }: ThumbnailProps) => {
  const navigate = useNavigate()

  return (
    <ThumbnailBase
      item={item}
      onClick={onClick ?? (() => navigate(`/results/${item.id}`))}
      menu={
        <ActionIcon
          className={styles['clickable']}
          variant="white"
          title="Add to Collection"
          c="gray"
          onClick={() => openAddToCollectionModal(item)}
          data-cy="add-to-collection"
        >
          <Icon name="add" />
        </ActionIcon>
      }
    >
      <ResultImage
        item={item}
        className={styles['zoom']}
        style={{ cursor: 'pointer' }}
      />
    </ThumbnailBase>
  )
}
