import type { Point, Polygon } from 'geojson'

export type AssetType = {
  title: string
  type: string
  description?: string
  href: string
  name?: string
}

export interface AnalyticProperty {
  class_name: string
  geometry: Polygon
  score?: number | null
}

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[]
  | AnalyticProperty[]

export enum CategoryTypes {
  IMAGE = 'IMAGE',
  DATA = 'DATA',
  PUBLICATION = 'WEB_ARTICLE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  INSIGHT = 'INSIGHT',
  RELATED_IMAGERY = 'RELATED_IMAGERY',
  ANALYTIC = 'ANALYTIC',
}

// TODO: Complete Provider enum with full list of options before using
// export enum Provider {
//   GDELT = 'GDELT',
//   SEERIST = 'SEERIST',
//   LANDSAT = 'LANDSAT',
//   PLANET = 'Planet Labs',
//   GEGD = 'GEGD',
//   FIRMS = 'FIRMS',
//   SPIRE = 'SPIRE',
// }

export interface RawSearchResult {
  title: string
  subtitle: string
  filetype: string
  category: CategoryTypes
  geometry: Polygon | Point
  documentId: string
  score: number
  asset: { [key: string]: AssetType }
  // TODO: There is a lot of repetition in these properties, from the root level
  //  to `properties` to some `_d` prefixed properties. We should work on
  //  consolidating these into a coherent object and removing the `_d` prefix.
  //  There is also a lot of inconsistency of data types in similar fields.
  properties: {
    _dCategory: CategoryTypes
    _dProvider: string

    _dId?: string
    _dPublic?: boolean
    _dRecordType?: string
    _dSource?: string
    _dCollect?: string
    _dTimeOfIngest?: string
    _dKeywords?: string[] | { _dKeywords: string[] | { _dKeywords: string[] } }
    _dPreview?: string
    _dQuality?: number
    _dCloudCover?: number
    _dAsset?: string
    _dSensor?: string
    _dAnalytic?: AnalyticProperty[]

    id?: string
    datetime?: string
    link?: string
    layername?: string
    aoi?: string
    child_aoi?: string
    description?: string | null
    featureId?: string
    legacyDescription?: string
    companyName?: string

    firms_metadata?: {
      latitude: number
      longitude: number
      scan: number
      track: number
      acq_date: string
      acq_time: number
      bright_ti4: number
      bright_ti5: number
      frp: number
      instrument: string
      satellite: string
      confidence: string
      version: string
      daynight: string
    }

    recordType?: string

    start_datetime?: string | null
    end_datetime?: string | null
    created?: string | null
    updated?: string | null
    platform?: string | null
    instruments?: string | null
    constellation?: string | null
    mission?: string | null
    providers?: string[] | null
    gsd?: string | null
    topic_scores_v2?:
      | { name: string; score: number }[]
      | {
          topic_scores_v2:
            | { name: string; score: number }[]
            | { topic_scores_v2: { name: string; score: number }[] | null }
            | null
        }
      | null
    labeled_categories?:
      | { name: string; score: number }[]
      | {
          labeled_categories:
            | { name: string; score: number }[]
            | { labeled_categories: { name: string; score: number }[] | null }
            | null
        }
      | null
    _timestamp?: string | null
    _completed?: string | null
    cluster_id?: string | null
    cluster_size?: number | null
    sentiment?: number | null
    emotions?:
      | string[]
      | { emotions: string[] | { emotions: string[] | null } | null }
      | null
    veracity?: string | null
    lang?: string | null
    filtered_cluster_size?: number | null
  }
  authoredOn: string
  source: string
  tags: string[]
  thumbnail: string | null
  license?: string
  description?: string
}

export interface ResultWithId extends RawSearchResult {
  id: string
}

export interface DataResult extends ResultWithId {
  id: string
  category: CategoryTypes.DATA
}

export function isDataResult(result: RawSearchResult): result is DataResult {
  return result.category === CategoryTypes.DATA
}

export interface FireResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: CategoryTypes.DATA
    _dProvider: 'FIRMS'
    firms_metadata: {
      latitude: number
      longitude: number
      scan: number
      track: number
      acq_date: string
      acq_time: number
      bright_ti4: number
      bright_ti5: number
      frp: number
      instrument: string
      satellite: string
      confidence: string
      version: string
      daynight: string
    }
  }
}

export function isFireResult(result: ResultWithId): result is FireResult {
  return ['FIRMS'].includes(result.properties._dProvider)
}

export interface VesselResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: CategoryTypes.DATA
    _dProvider: 'SPIRE'
    recordType: string
    datetime: string
    updateTimestamp: string
    staticData: {
      name: string | null
      imo: number | null
      mmsi: number
    }
    lastPositionUpdate: {
      latitude: number
      longitude: number
      navigationalStatus: string
      timestamp: string
      updateTimestamp: string
    }
    currentVoyage: {
      destination: string
      draught: number
      eta: string
      timestamp: string
      updateTimestamp: string
    } | null
  }
}

export function isVesselResult(result: ResultWithId): result is VesselResult {
  return (
    ['SPIRE'].includes(result.properties._dProvider) &&
    result.properties.recordType === 'vessel'
  )
}

export interface PortResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: CategoryTypes.DATA
    _dProvider: 'SPIRE'
    state: string
    recordType: string
    timestamp: string
    datetime: string
    updateTimestamp: string
    ata: string
    atd: string
    draughtAta: string
    draughtAtd: string
    draughtChange: number
    duration: number
    location: {
      name: string
      centerPoint: {
        latitude: number
        longitude: number
      }
      country: string
      id: string
      type: string
      unlocode: string
    }
    vessel: {
      id: string
      staticData: {
        callsign: string
        imo: number
        mmsi: number
        name: string
        shipType: string
      }
    }
  }
}

export function isPortResult(result: ResultWithId): result is PortResult {
  return (
    ['SPIRE'].includes(result.properties._dProvider) &&
    result.properties.recordType === 'portEvent'
  )
}

export type ShipResult = VesselResult | PortResult

export function isShipResult(result: ResultWithId): result is ShipResult {
  return isVesselResult(result) || isPortResult(result)
}

export interface ImageryResult extends ResultWithId {
  id: string
  thumbnail: string
  properties: ResultWithId['properties'] & {
    _dQuality: number
  }
  category:
    | CategoryTypes.IMAGE
    | CategoryTypes.RELATED_IMAGERY
    | CategoryTypes.ANALYTIC
}

export function isImageryResult(result: ResultWithId): result is ImageryResult {
  return [
    CategoryTypes.IMAGE,
    CategoryTypes.RELATED_IMAGERY,
    CategoryTypes.ANALYTIC,
  ].includes(result.category)
}
export interface AnalyticResult extends ImageryResult {
  id: string
  thumbnail: string
  properties: ImageryResult['properties'] & {
    _dQuality?: number
    _dAnalytic: AnalyticProperty[]
  }
  category: CategoryTypes.ANALYTIC
}

export function isAnalyticResult(
  result: ResultWithId,
): result is AnalyticResult {
  return [CategoryTypes.ANALYTIC].includes(result.category)
}

export interface RelatedImageryResult extends Omit<ImageryResult, 'category'> {
  category: CategoryTypes.RELATED_IMAGERY
}

export interface AnalyticResult extends Omit<ImageryResult, 'category'> {
  category: CategoryTypes.ANALYTIC
}

export function isRelatedImageryResult(
  result: ResultWithId,
): result is RelatedImageryResult {
  return result.category === CategoryTypes.RELATED_IMAGERY
}

export interface InsightResult extends ResultWithId {
  description: string
  category: CategoryTypes.INSIGHT
}

export function isInsightResult(result: ResultWithId): result is InsightResult {
  return result.category === CategoryTypes.INSIGHT
}

export interface PublicationResult extends ResultWithId {
  description: string
  category: CategoryTypes.PUBLICATION
}

export function isPublicationResult(
  result: ResultWithId,
): result is PublicationResult {
  return result.category === CategoryTypes.PUBLICATION
}

export interface SocialMediaResult extends ResultWithId {
  description: string
  category: CategoryTypes.SOCIAL_MEDIA
  asset: { thumbnail: AssetType }
  properties: ResultWithId['properties'] & {
    _dCategory: CategoryTypes.SOCIAL_MEDIA
    id: string
    title: string
    source: string
    summary: string
    src_image_url?: string
    start_datetime?: string | null
    end_datetime?: string | null
    created?: string | null
    updated?: string | null
    platform?: string | null
    instruments?: string | null
    constellation?: string | null
    mission?: string | null
    providers?: string[] | null
    gsd?: string | null
    topic_scores_v2?:
      | { name: string; score: number }[]
      | {
          topic_scores_v2:
            | { name: string; score: number }[]
            | { topic_scores_v2: { name: string; score: number }[] | null }
            | null
        }
      | null
    labeled_categories?:
      | { name: string; score: number }[]
      | {
          labeled_categories:
            | { name: string; score: number }[]
            | { labeled_categories: { name: string; score: number }[] | null }
            | null
        }
      | null
    _timestamp?: string | null
    _completed?: string | null
    cluster_id?: string | null
    cluster_size?: number | null
    sentiment?: number | null
    emotions?:
      | string[]
      | { emotions: string[] | { emotions: string[] | null } | null }
      | null
    veracity?: string | null
    lang?: string | null
    filtered_cluster_size?: number | null
  }
}

export function isSocialMediaResult(
  result: ResultWithId,
): result is SocialMediaResult {
  return result.category === CategoryTypes.SOCIAL_MEDIA
}
