import type { HexGrid } from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import type { MutableRefObject } from 'react'

const gradientStops = [
  '#800026',
  '#9e0c2b',
  '#b8242f',
  '#ce3e34',
  '#e05838',
  '#ef743c',
  '#f99040',
  '#ffae44',
  '#ffcc49',
  '#ffe94d',
]

export const setHexGridFills = (
  map: MutableRefObject<mapboxgl.Map | null>,
  hexGrid: HexGrid,
) => {
  const minValue = hexGrid.features.reduce(
    (previousMin, current) =>
      Math.min(previousMin, current.properties.resultCount),
    1e6,
  )

  const maxValue = hexGrid.features.reduce(
    (previousMax, current) =>
      Math.max(previousMax, current.properties.resultCount),
    0,
  )

  if (maxValue - minValue < 0.0001) {
    return
  }

  const gradientStep = (maxValue - minValue) / gradientStops.length
  const gradient: (number | string)[] = []

  gradientStops.forEach((color, index) => {
    gradient.push(minValue + index * gradientStep, color)
  })

  map.current?.setPaintProperty('hexgrid-fills', 'fill-color', [
    'interpolate-hcl',
    ['linear'],
    ['get', 'resultCount'],
    ...gradient,
  ])

  map.current?.setPaintProperty('hexgrid-outlines', 'line-color', [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    '#202020',
    ['boolean', ['feature-state', 'hovered'], false],
    '#FFFF00',
    '#000000',
  ])

  map.current?.setPaintProperty('hexgrid-outlines', 'line-opacity', [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    1,
    ['boolean', ['feature-state', 'hovered'], false],
    0.9,
    0,
  ])
}
