import { Divider, Stack } from '@mantine/core'
import { ExploreNavBar } from './ExploreNavBar'
import { ExploreSuggestionRow } from './ExploreSuggestionRow'

export interface ExploreHeaderProps {
  suggestions: string[]
  onSelect: (suggestiion: string) => void
}

export default function ExploreHeader({
  suggestions,
  onSelect,
}: ExploreHeaderProps) {
  return (
    <Stack gap={12}>
      <ExploreNavBar />
      <ExploreSuggestionRow suggestions={suggestions} onSelect={onSelect} />
      <Divider />
    </Stack>
  )
}
