import React, { type ChangeEvent } from 'react'
import {
  ActionIcon,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Group,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import type { ContextModalProps } from '@mantine/modals'
import { Icon } from '@/components/lib/Icon.tsx'
import { postServiceRequest } from '@/utils/data-fetching/config.ts'
import type { ImageryResult } from '@/utils/types/result-types'
import { ErrorBoundary } from '@sentry/react'
import { groupBy } from 'lodash'

import '@mantine/core/styles.css'

const analysisTypes = [
  {
    value: 'xviewDetector',
    label: 'xView General Detector',
    group: 'General Detectors',
  },
  {
    value: 'aircraft.descartes',
    label: 'Descartes labs foreign aircraft v4.1.0',
    group: 'Aircraft',
  },
  {
    value: 'aircraft.danti',
    label: 'Danti aircraft detection v1.0 (YOLOv5)',
    group: 'Aircraft',
  },
]

interface AnalysisType {
  value: string
  label: string
}

interface AnalysisGroup {
  group: string
  items: AnalysisType[]
}

const analysisGroups: AnalysisGroup[] = Object.entries(
  groupBy(analysisTypes, 'group'),
).map(([groupName, items]) => ({
  group: groupName,
  items: items.map(({ label, value }) => ({ label, value })),
}))

function CheckboxGroup({
  group,
  items,
  selected,
  onClick,
}: AnalysisGroup & { selected: string[]; onClick: (value: string) => void }) {
  const [isOpen, { toggle }] = useDisclosure()

  const indeterminate = items.some(
    ({ value }) => selected.includes(value) && !selected.includes(group),
  )

  function handleClick(event: ChangeEvent<HTMLInputElement>) {
    onClick(event.currentTarget.value)
  }

  return (
    <Group gap={0}>
      <Group justify="space-between" p="xs" w="100%" m={0}>
        <Checkbox
          value={group}
          indeterminate={indeterminate}
          checked={selected.includes(group)}
          label={group}
          onChange={handleClick}
        />
        <ActionIcon onClick={toggle} variant="transparent" c="gray">
          <Icon name={isOpen ? 'expand_less' : 'expand_more'} />
        </ActionIcon>
      </Group>
      <Collapse in={isOpen || true}>
        {items.map(({ value, label }) => (
          <Checkbox
            checked={selected.includes(value) || selected.includes(group)}
            key={value}
            value={value}
            label={label}
            onChange={handleClick}
            p="xs"
            ml="2.25rem"
          />
        ))}
      </Collapse>
    </Group>
  )
}

export function AnalyzeItemModal({
  context,
  id: modalId,
  innerProps: { item },
}: ContextModalProps<{ item: ImageryResult }>) {
  const [selectedAnalytics, setSelectedAnalytics] = React.useState<string[]>([
    'General Detectors',
  ])

  async function handleAnalyzeClick() {
    await postServiceRequest('/download/analytic/', {
      id: item.properties.featureId,
      provider: item.source,
      // eslint-disable-next-line camelcase
      // collection_id: item.workspaceId, TODO implement including collection id
    })
    context.closeModal(modalId)
  }

  function handleClick(value: string) {
    if (selectedAnalytics.includes(value)) {
      setSelectedAnalytics((selected) =>
        selected.filter((selectedValue) => selectedValue !== value),
      )
    } else {
      setSelectedAnalytics((selected) => [...selected, value])
    }
  }

  return (
    <>
      {/* No fallback necessary yet. We can just skip rendering the search. */}
      <ErrorBoundary fallback={<span />}>
        <Autocomplete
          leftSection={<Icon name="search" />}
          placeholder="Search for imagery analysis models"
          data={analysisGroups}
        />
      </ErrorBoundary>
      <Box
        mt="sm"
        mih="10rem"
        style={(theme) => ({
          overflowY: 'auto',
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        {analysisGroups.map(({ group, items }) => (
          <CheckboxGroup
            key={group}
            group={group}
            items={items}
            selected={selectedAnalytics}
            onClick={handleClick}
          />
        ))}
      </Box>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button onClick={() => void handleAnalyzeClick()}>
          Start Analysis
        </Button>
      </Group>
    </>
  )
}
