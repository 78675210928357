export const regridSample7434 = {
  documentId: '562b707d-ec7d-4d76-a0b9-ad20f328196b',
  title: 'Regrid Parcel record',
  subtitle: '',
  description: '',
  thumbnail: '',
  filetype: '',
  category: 'DATA',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-122.726692, 45.4711905],
        [-122.7266805, 45.4711755],
        [-122.726672, 45.471159],
        [-122.726214, 45.471315],
        [-122.726253, 45.471335],
        [-122.726243, 45.4717805],
        [-122.726501, 45.47178],
        [-122.7265115, 45.471288],
        [-122.7267245, 45.4712155],
        [-122.726707, 45.471204],
        [-122.726692, 45.4711905],
      ],
    ],
  },
  score: 0.5,
  authoredOn: '2023-10-18T02:13:28',
  source: 'REGRID',
  tags: [],
  license: 'unknown',
  asset: '',
  properties: {
    context: {
      active: true,
      headline: 'Portland West, OR',
      name: 'Portland West, OR',
      path: '/us/or/multnomah/portland-west',
    },
    fields: {
      address: '7434 SW 49TH CT',
      address_source: 'county;accuzip',
      alt_parcelnumb1: 'R110949',
      alt_parcelnumb2: 'R049350240',
      census_block: '410510066011002',
      census_blockgroup: '410510066011',
      census_tract: '41051006601',
      city: 'portland-west',
      county: 'multnomah',
      geoid: '41051',
      gisacre: 0.3,
      improvval: 525430,
      landval: 321000,
      lat: '45.471490',
      legaldesc: 'AZALEA PARK, LOT 8',
      ll_gisacre: 0.31958,
      ll_gissqft: 13921,
      ll_last_refresh: '2023-09-26',
      ll_stable_id: 'parcelnumb',
      ll_updated_at: '2023-10-17 18:29:38 -0400',
      ll_uuid: '1f6d45b1-8710-493e-814f-b03730625167',
      lon: '-122.726401',
      lot: '8',
      mail_city: 'PORTLAND',
      mail_state2: 'OR',
      mail_zip: '97219',
      mailadd: '7434 SW 49TH CT',
      numunits: 1,
      ogc_fid: 170469,
      original_address:
        '{"address":"7434 SW 49TH CT ","saddno":"7434","saddpref":"SW","saddstr":"49TH","saddsttyp":"CT","sunit":"","scity":"PORTLAND","szip":"97219"}',
      owner: 'ISAACSON, ANDREW & ISAACSON, AMY',
      parcelnumb: '1S1E19AD  -02301',
      parcelnumb_no_formatting: '1S1E19AD02301',
      parval: 453850,
      parvaltype: 'MAXIMUM ASSESSED VALUE',
      path: '/us/or/multnomah/portland-west/170469',
      plss_range: '001E',
      plss_section: 'Section 19',
      plss_township: '001S',
      qoz: 'No',
      recrdareano: 2981,
      saddno: '7434',
      saddpref: 'SW',
      saddstr: '49TH',
      saddsttyp: 'CT',
      saledate: '2020-07-31',
      saleprice: 810000,
      scity: 'PORTLAND',
      sqft: 13068,
      state2: 'OR',
      structno: 1,
      szip: '97219-1492',
      taxyear: '2022',
      usecode: '101',
      usedesc: 'RP Residential',
      yearbuilt: 1999,
      zoning: 'R7',
    },
    headline: '7434 Sw 49th Ct',
    ll_uuid: '1f6d45b1-8710-493e-814f-b03730625167',
    path: '/us/or/multnomah/portland-west/170469',
    score: 92,
  },
  id: '562b707d-ec7d-4d76-a0b9-ad20f328196b',
}
