import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from '@mantine/hooks'
import { makeImageryResultsBoundingBox } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useAllResults } from '@/hooks/results/use-all-results.ts'
import { mapStore } from '@/stores/map-store.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  EventDrivenQuerySourceKeys,
  QuerySourceState,
} from '@/utils/types/message-types.ts'
import { isImageryResult } from '@/utils/types/result-types.ts'

export enum SearchStatuses {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_RECENTLY = 'STARTED_RECENTLY',
  STARTED_LONG_AGO = 'STARTED_LONG_AGO',
  EDS_IN_PROGRESS = 'EDS_IN_PROGRESS',
  NO_RESULTS = 'NO_RESULTS',
  READY = 'READY',
}

const RESULT_MINIMUM_FOR_INTERACTION = 5
const TAKING_A_WHILE_THRESHOLD_MS = 12000
const QUERY_TIMEOUT_MS = 60000
export const useSearchStatus = () => {
  const currentQueryId = resultsStore((s) => s.currentQueryId)
  const results = useAllResults()
  const [isTakingAWhile, setIsTakingAWhile] = useState(false)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [queryStatus, currentQueryStart] = queryStore((s) => [
    s.queryStatus,
    s.currentQueryStart,
  ])
  const [mapBbox, setMapBbox] = mapStore((s) => [s.mapBbox, s.setMapBbox])

  useEffect(() => {
    setIsTakingAWhile(false)
    setIsTimedOut(false)
    const takingAWhile = setTimeout(() => {
      setIsTakingAWhile(true)
    }, TAKING_A_WHILE_THRESHOLD_MS)
    const timeout = setTimeout(() => {
      setIsTimedOut(true)
    }, QUERY_TIMEOUT_MS)

    return () => {
      clearTimeout(takingAWhile)
      clearTimeout(timeout)
    }
  }, [currentQueryId])

  const imageResults = useMemo(() => results.filter(isImageryResult), [results])
  const previousImageResults = usePrevious(imageResults)

  useEffect(() => {
    if (
      (!previousImageResults || previousImageResults.length < 3) &&
      imageResults &&
      imageResults.length >= 3 &&
      !mapBbox
    ) {
      setMapBbox(makeImageryResultsBoundingBox(imageResults) || null)
    }
  }, [imageResults, mapBbox, previousImageResults, setMapBbox])

  const isSearching = currentQueryStart !== null
  const hasEnoughResults = results.length >= RESULT_MINIMUM_FOR_INTERACTION
  const allSourcesCompleted = Object.values(queryStatus).every((v) =>
    [QuerySourceState.COMPLETED, QuerySourceState.ERROR].includes(v),
  )

  const isReadyForInteraction =
    !isSearching || hasEnoughResults || allSourcesCompleted || isTimedOut

  const isEdsInProgress = Object.values({ ...EventDrivenQuerySourceKeys }).some(
    (k) => queryStatus[k] === QuerySourceState.IN_PROGRESS,
  )

  // Communicate specific states that can't collide
  let status = SearchStatuses.NOT_STARTED
  if (isSearching) {
    if (results.length === 0) {
      if (allSourcesCompleted || isTimedOut) {
        status = SearchStatuses.NO_RESULTS
      } else if (isTakingAWhile) {
        status = isEdsInProgress
          ? SearchStatuses.EDS_IN_PROGRESS
          : SearchStatuses.STARTED_LONG_AGO
      } else {
        status = SearchStatuses.STARTED_RECENTLY
      }
    } else {
      status = SearchStatuses.READY
    }
  }

  return {
    isSearching,
    isReadyForInteraction,
    status,
  }
}
