import { Grid } from '@mantine/core'
import { ContentBox } from './ContentBox'

export interface ExploreContentTwoProps {
  isLoading: boolean
}

export function ExploreContentTwo({ isLoading }: ExploreContentTwoProps) {
  return (
    <Grid gutter={12} h={{ base: 'auto', md: '290px' }}>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <ContentBox
          transition="fade"
          imageSrc="/demo/grid/v2/0.png"
          h={{ base: '400px', md: '290px' }}
          isLoading={isLoading}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Grid gutter={8}>
          {[
            '/demo/grid/v2/1.png',
            '/demo/grid/v2/2.png',
            '/demo/grid/v2/3.png',
            '/demo/grid/v2/4.png',
          ].map((x) => (
            <Grid.Col span={6} key={x}>
              <ContentBox
                transition="slide-down"
                imageSrc={x}
                isLoading={isLoading}
                h={{ base: '220px', md: '140px' }}
              />
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <ContentBox
          transition="pop"
          imageSrc="/demo/grid/v2/5.png"
          h={{ base: '400px', md: '290px' }}
          isLoading={isLoading}
        />
      </Grid.Col>
    </Grid>
  )
}
