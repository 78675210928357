import { Chip, Group } from '@mantine/core'
import { filtersStore, toggleSensors } from '@/stores/filters-store.ts'
import { Sensor } from '@/utils/types/filter-types.ts'

export function Sensors() {
  const sensors = filtersStore((state) => state.sensors)

  const labelToSensorValue = (label: string) => {
    const sensorValue =
      Object.keys(Sensor).find(
        (key: any) => Sensor[key as keyof typeof Sensor] === label,
      ) ?? ''
    return sensorValue
  }

  return (
    <Chip.Group multiple value={[...sensors]}>
      <Group gap="xs">
        {Object.entries(Sensor).map(([value, label]) => (
          <Chip
            key={value}
            variant={sensors.includes(label) ? 'filled' : 'outline'}
            value={value}
            onClick={() => toggleSensors(labelToSensorValue(label))}
            size="xs"
          >
            {label}
          </Chip>
        ))}
      </Group>
    </Chip.Group>
  )
}
