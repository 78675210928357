import { Box, Card, Grid, Group, Stack, Text } from '@mantine/core'
import { Thumbnail } from '@/components/lib/Thumbnail.tsx'
import type {
  Collection,
  CollectionItem,
} from '@/utils/types/collection-types.ts'
import { wordBreak } from '@/utils/ui-helpers.tsx'
import dayjs from 'dayjs'
import type React from 'react'

export function CollectionItemGrid({
  collection,
  menu: Menu,
  onClick,
}: {
  collection: Collection & { items: CollectionItem[] }
  menu: React.ComponentType<{ collectionId: string; item: CollectionItem }>
  onClick: (id: string) => void
}) {
  return (
    <Grid columns={60} gutter="sm" mt="lg" style={{ overflow: 'initial' }}>
      {collection.items.map((item) => (
        <Grid.Col
          key={item.id}
          span={{ base: 60, xs: 60, sm: 60 / 2, md: 60 / 3, lg: 60 / 4 }}
        >
          <Card style={{ height: '100%' }} withBorder py="xs" px="sm">
            <Stack justify="space-between" h="100%">
              <div>
                <Group justify="flex-end">
                  <Menu collectionId={collection.id} item={item} />
                </Group>
                <Box style={{ marginBottom: 8 }}>
                  <Thumbnail item={item.doc} onClick={() => onClick(item.id)} />
                </Box>
                <Text>{wordBreak(item.doc.title)}</Text>
              </div>
              <Group justify="space-between" w="100%">
                <Text size="sm" fw="bold">
                  {item.doc.source}
                </Text>
                <Text size="sm">{dayjs(item.doc.authoredOn).fromNow()}</Text>
              </Group>
            </Stack>
          </Card>
        </Grid.Col>
      ))}
    </Grid>
  )
}
