import type { DataResult, ResultWithId } from '@/utils/types/result-types.ts'
import { parseUsAddress } from '@/utils/types/single-address/us-address-types.ts'
import type { ParsedAddress } from 'vladdress'

export interface HazardPropertyRecord {
  assessment: {
    Building_Area: number
    LotSize_Acres: number
    Roof_Cover: string
    Standardized_Land_Use_Code: string
    Year_Built: string
  }
  images: string[]
  lat: number
  lng: number
  property: {
    address: string
  }
}

export type HazardPropertyResult = DataResult & {
  properties: HazardPropertyRecord
}

export const isHazardPropertyResult = (
  r: ResultWithId,
): r is HazardPropertyResult => {
  return (
    r.source.toLowerCase() === 'hazard' &&
    Object.keys(r.properties).includes('assessment')
  )
}

export const parseHazardPropertyAddress = (
  r: HazardPropertyRecord,
): Partial<ParsedAddress> => {
  return parseUsAddress(r.property.address)
}
