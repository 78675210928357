import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Box, Flex, Title } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { useOrthoLayers } from '@/components/lib/map-search/hooks/use-ortho-layers.ts'
import { useStyleLoadedCallback } from '@/components/lib/map-search/hooks/use-style-loaded-callback.ts'
import { useZoomToNewOrtholayer } from '@/components/lib/map-search/hooks/use-zoom-to-new-ortholayer.ts'
import { ResultsPanel } from '@/components/lib/results/ResultsPanel.tsx'
import { useSearchStatus } from '@/hooks/use-search-status.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { mapboxToken } from '@/utils/constants'
import mapboxgl from 'mapbox-gl'
import { useBaseLayerSelection } from './hooks/use-base-layer-selection.ts'
import { useCellSelectionBehavior } from './hooks/use-cell-selection-behavior.tsx'
import {
  useFileDragging,
  useMapFlyToOnDrag,
} from './hooks/use-file-dragging.ts'
import { useHexGrid } from './hooks/use-hex-grid.ts'
import { useInitialBehavior } from './hooks/use-initial-behavior.ts'
import { useLayerToggling } from './hooks/use-layer-toggling.ts'
import { useMapboxMap } from './hooks/use-mapbox-map.ts'
import { useSearchDrawing } from './hooks/use-search-drawing.ts'
import { MapControlPanel } from './MapControlPanel.tsx'
import { MapSearchHereButton } from './MapSearchHereButton.tsx'
import { MapSwitchToListButton } from './MapSwitchToListButton.tsx'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { usePointData } from '@/components/lib/map-search/hooks/use-point-data.ts'

mapboxgl.accessToken = mapboxToken

export const MapSearchPanel = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const searchHereDiv = useRef<HTMLDivElement | null>(null)
  const nav = useNavigate()

  const [isResultsExpanded, setIsResultsExpanded] = React.useState(false)
  const { isSearching } = useSearchStatus()
  const currentQuery = queryStore((s) => s.currentQuery)
  React.useEffect(() => {
    setIsResultsExpanded(isSearching)
  }, [isSearching])
  const showMapControls = true
  useMapboxMap(map, mapContainer, showMapControls)

  useInitialBehavior(map)
  const { hexGrid, recomputeHexGrid } = useHexGrid(map)
  useStyleLoadedCallback({
    map,
    hexGrid,
  })
  useLayerToggling(map)
  useCellSelectionBehavior(map, hexGrid)
  usePointData(map)

  useBaseLayerSelection(map, hexGrid)
  const { onClickCallback } = useSearchDrawing(map, searchHereDiv)
  const { getRootProps, getInputProps, open } = useFileDragging()
  useMapFlyToOnDrag(map)
  useOrthoLayers(map)
  useZoomToNewOrtholayer(map)

  return (
    <Box>
      <MapControlPanel
        map={map}
        openFileDialog={open}
        recomputeHexGrid={recomputeHexGrid}
      />
      <ActionIcon
        onClick={() => setIsResultsExpanded((state) => !state)}
        pos="absolute"
        radius="xs"
        top={140}
        right={isResultsExpanded ? 'calc(40% + 0.5rem)' : '0.5rem'}
        p="0"
        bg="#eee"
        style={{
          zIndex: 1,
          transform: `translateX(-100%), rotate(${
            isResultsExpanded ? '180deg' : '0deg'
          })`,
          color: '#666',
          transition: 'right 0.25s',
          boxShadow:
            '0px 0px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.22) inset',
        }}
      >
        {isResultsExpanded ? (
          <Icon name="right_panel_close" />
        ) : (
          <Icon name="right_panel_open" />
        )}
      </ActionIcon>
      <MapSwitchToListButton onClick={() => nav('/results')} />
      <Flex
        m="-xl"
        top="4rem"
        w="calc(100% + 6rem)"
        h="calc(100vh - 7.25rem)"
        style={{ position: 'relative' }}
      >
        <div
          style={{
            flex: 1,
            height: '100%',
            position: 'relative',
          }}
        >
          <div
            {...getRootProps()}
            style={{
              position: 'absolute',
              inset: 0,
            }}
          >
            <ActionIcon
              onClick={() => {
                nav(-1)
              }}
              pos="absolute"
              radius="xs"
              top={10}
              left={5}
              p="0"
              bg="#eee"
              style={{
                zIndex: 1,
                transform: `translateX(-100%), rotate('0deg'
          })`,
                color: '#666',
                transition: 'right 0.25s',
                boxShadow:
                  '0px 0px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.22) inset',
              }}
            >
              <Icon name="arrow_back" />
            </ActionIcon>
            <input {...getInputProps()} />

            <div
              ref={mapContainer}
              className="map-container"
              style={{
                height: 'calc(100vh - 7.25rem)',
              }}
            />
          </div>
        </div>
        <Box
          p={isResultsExpanded ? 'sm' : 0}
          bg="white"
          w={isResultsExpanded ? '40%' : 0}
          style={{
            overflowY: 'scroll',
            border: '1px solid #e5e5e5',
            borderRadius: '0.25rem',
          }}
        >
          <Title>
            {currentQuery === 'polygon' ? 'Polygon Search' : currentQuery}
          </Title>
          <ResultsPanel isEmbedded onlyOrtho />
        </Box>
      </Flex>
      <div style={{ display: 'none' }}>
        <div ref={searchHereDiv}>
          <MapSearchHereButton onClick={onClickCallback} />
        </div>
      </div>
    </Box>
  )
}

export default MapSearchPanel
