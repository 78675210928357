import { useVexcel } from '@/hooks/ortho-imagery/use-vexcel.ts'
import { useAttom } from '@/hooks/single-address/use-attom.ts'
import { useHazard } from '@/hooks/single-address/use-hazard.ts'
import { useRegrid } from '@/hooks/single-address/use-regrid.ts'
import type { AttomPermitRecord } from '@/utils/types/single-address/attom-permit-types.ts'
import type { AttomPropertyRecord } from '@/utils/types/single-address/attom-property-types.ts'
import type { HazardPropertyRecord } from '@/utils/types/single-address/hazard-property-types.ts'
import type { HazardRiskRecord } from '@/utils/types/single-address/hazard-risk-types.ts'
import type { RegridRecord } from '@/utils/types/single-address/regrid-types.ts'

export interface PropertyData {
  attomProperty: AttomPropertyRecord | undefined
  attomPermit: AttomPermitRecord | undefined
  hazardProperty: HazardPropertyRecord | undefined
  hazardRisk: HazardRiskRecord | undefined
  regrid: RegridRecord | undefined
}

export const useSingleAddressComponents = (isMock: boolean) => {
  const { attomPermitRecord, attomPropertyRecord, permitRecords } =
    useAttom(isMock)

  const {
    hazardRiskRecord,
    hazardPropertyRecord,
    riskRecords,
    risksScoredF,
    risksScoredD,
    risksScoredC,
    mlsImages,
  } = useHazard(isMock)

  const { regridRecord, parcelPolygon, makeTileLayerOptions } =
    useRegrid(isMock)

  const { orthoCoverage } = useVexcel(parcelPolygon)

  return {
    propertyData: {
      attomProperty: attomPropertyRecord,
      attomPermit: attomPermitRecord,
      hazardProperty: hazardPropertyRecord,
      hazardRisk: hazardRiskRecord,
      regrid: regridRecord,
    } as PropertyData,
    mlsImages,
    permitRecords,
    parcelPolygon,
    riskRecords,
    risksScoredF,
    risksScoredD,
    risksScoredC,
    makeTileLayerOptions,
    orthoCoverage,
  }
}
