import { type FC, useCallback } from 'react'
import { Checkbox, Menu, Stack, Text } from '@mantine/core'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Dictionary } from 'lodash'

type PostfilterCheckboxButtonProps = {
  histogrammedOptions: Dictionary<ImageryResult[]>
  omittedOptions: string[]
  setNewOmittedOptions: (omittedOptions: string[]) => void
  label: string
  isMenu?: boolean
}

export const PostfilterCheckboxButton: FC<PostfilterCheckboxButtonProps> = ({
  histogrammedOptions,
  omittedOptions,
  setNewOmittedOptions,
  label,
  isMenu,
}) => {
  const makeCheckbox = useCallback(
    (optionKey: string) => {
      return (
        <Checkbox
          size="xs"
          key={`option_${optionKey}`}
          label={`${optionKey} (${histogrammedOptions[optionKey].length})`}
          checked={!omittedOptions.includes(optionKey)}
          onChange={() =>
            omittedOptions.includes(optionKey)
              ? setNewOmittedOptions(
                  omittedOptions.filter((o) => o != optionKey),
                )
              : setNewOmittedOptions([...omittedOptions, optionKey])
          }
        />
      )
    },
    [histogrammedOptions, omittedOptions, setNewOmittedOptions],
  )

  return (
    <Stack gap="xs">
      <Text size="s">{label}</Text>
      <Stack gap="xs">
        {Object.keys(histogrammedOptions)
          .sort()
          .map((optionKey) =>
            isMenu ? (
              <Menu.Item key={optionKey} closeMenuOnClick={false}>
                {makeCheckbox(optionKey)}
              </Menu.Item>
            ) : (
              makeCheckbox(optionKey)
            ),
          )}
      </Stack>
    </Stack>
  )
}
