import { Button, Group, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import {
  type RemoveFromCollectionVariables,
  useCollections,
} from '@/utils/data-fetching/collections.ts'

export function RemoveFromCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId, itemId },
}: ContextModalProps<RemoveFromCollectionVariables>) {
  const { removeItem } = useCollections()

  async function handleDelete() {
    await removeItem({ collectionId, itemId })
    context.closeModal(modalId)
  }

  return (
    <>
      <Text>Are you sure you want to remove this from the collection?</Text>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button color="red" onClick={() => void handleDelete()}>
          Remove
        </Button>
      </Group>
    </>
  )
}
