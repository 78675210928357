import { type MutableRefObject, useCallback, useEffect } from 'react'
import { useOrthoImagerySources } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import { mapStore } from '@/stores/map-store.ts'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types.ts'
import * as turf from '@turf/turf'
import type { Feature, MultiPolygon } from 'geojson'
import type mapboxgl from 'mapbox-gl'

const GROUP_EXTENT_SOURCE_ID = 'highlighted_group_extent'
const RESULT_SOURCE_PREFIX = 'orthoresult_'
export const useOrthoLayers = (map: MutableRefObject<mapboxgl.Map | null>) => {
  const [activeOrthoLayerGroupIds, hoveredLayerId] = mapStore((s) => [
    s.activeOrthoLayerGroupIds,
    s.hoveredLayerId,
  ])
  const { orthoLayers, orthoLayerGroups } = useOrthoImagerySources()
  const syncLayers = useCallback(() => {
    if (!map.current) {
      return
    }
    const expectedTileUrls = new Set(
      activeOrthoLayerGroupIds
        .map((id) => orthoLayerGroups.find((olg) => id === olg.id))
        .filter((olg): olg is OrthoImageryLayerGroup => !!olg)
        .flatMap((olg) => olg.layers)
        .map((ol) => ol.tileUrl),
    )

    const currentMapSources = Object.entries(map.current.getStyle().sources)
      .filter(
        (pairArray): pairArray is [string, mapboxgl.RasterSource] =>
          pairArray[1].type === 'raster',
      )
      .filter(([id, _]) => id?.startsWith(RESULT_SOURCE_PREFIX))
      .map(([id, rs]) => ({ ...rs, id }))

    // Make sure we're only grabbing our layers
    const actualTileUrls = new Set(
      currentMapSources.map((rs) => (rs.tiles && rs.tiles[0]) || ''),
    )

    const sourcesToRemove = currentMapSources.filter(
      (rs) => !expectedTileUrls.has((rs.tiles && rs.tiles[0]) || ''),
    )

    const tileUrlsToAdd = [...expectedTileUrls].filter(
      (url) => !actualTileUrls.has(url),
    )

    if (tileUrlsToAdd.length === 0 && sourcesToRemove.length === 0) {
      return
    }

    sourcesToRemove.forEach((rs) => {
      if (rs.id) {
        console.log(`Removing layer ${rs.id}`)
        map.current?.removeLayer(`${rs.id}_layer`)
        map.current?.removeSource(rs.id)
      }
    })

    tileUrlsToAdd.forEach((tileUrl) => {
      const ol = orthoLayers.find((ol) => ol.tileUrl === tileUrl)
      if (!ol) {
        console.warn(`Could not find orthoLayer for URL ${tileUrl}`)
        return
      }

      const sourceId = `${RESULT_SOURCE_PREFIX}${ol.id}`

      console.log('adding source', { sourceId })
      map.current?.addSource(sourceId, {
        type: 'raster',
        tiles: [tileUrl],
        bounds: turf.bbox(ol.imageResult.geometry),
      })
      map.current?.addLayer({
        type: 'raster',
        source: sourceId,
        id: `${sourceId}_layer`,
      })
    })
  }, [activeOrthoLayerGroupIds, map, orthoLayerGroups, orthoLayers])

  const waitForMapStyle = useCallback(() => {
    if (!map.current?.isStyleLoaded()) {
      setTimeout(() => waitForMapStyle(), 100)
      return
    }
    syncLayers()
  }, [map, syncLayers])

  useEffect(waitForMapStyle, [waitForMapStyle])

  useEffect(() => {
    map.current?.on('styledata', () => {
      if (!map.current?.getSource(GROUP_EXTENT_SOURCE_ID)) {
        map.current?.addSource(GROUP_EXTENT_SOURCE_ID, {
          type: 'geojson',
          data: turf.featureCollection([]),
        })

        map.current?.addLayer({
          type: 'line',
          id: `${GROUP_EXTENT_SOURCE_ID}_layer`,
          source: GROUP_EXTENT_SOURCE_ID,
          paint: {
            'line-width': 2,
            'line-color': '#11CC00',
            'line-opacity': 0.8,
          },
        })
      }
    })
  }, [map])

  useEffect(() => {
    const groupToHighlight = orthoLayerGroups.find(
      (lg) => lg.id === hoveredLayerId,
    )
    const source = map.current?.getSource(
      GROUP_EXTENT_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource
    if (source) {
      source.setData(
        turf.feature(groupToHighlight?.geometry) as Feature<MultiPolygon>,
      )
    }
  }, [hoveredLayerId, map, orthoLayerGroups])
}
