import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '@/utils/data-fetching/config.ts'
import type {
  Collection,
  CollectionItem,
} from '@/utils/types/collection-types.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'

export interface CollectionsResponse {
  items: Collection[]
}

export interface CollectionItemsResponse {
  items: CollectionItem[]
}

export interface AddToCollectionVariables {
  collectionId: string
  document: ResultWithId
}

export interface ShareCollectionVariables {
  emails: string
  message: string
  collectionId: string
}

export interface RemoveFromCollectionVariables {
  collectionId: string
  itemId: string
}

export interface ModifyCollectionVariables {
  id: string
  name?: string
  desc?: string
  tags?: string[]
}

export interface DeleteCollectionVariables {
  collectionId: string
}

export interface DeleteItemVariables {
  item: string
}

export interface CreateCollectionVariables {
  name: string
  desc: string
  tags?: string[]
}

interface UseCollectionsReturn {
  data: (Collection & {
    items: CollectionItem[]
  })[]
  createCollection: (variables: CreateCollectionVariables) => Promise<void>
  addItem: (variables: AddToCollectionVariables) => Promise<void>
  shareCollectionWithVendor: (
    variables: ShareCollectionVariables,
  ) => Promise<void>
  removeItem: (variables: RemoveFromCollectionVariables) => Promise<void>
  updateCollection: (variables: ModifyCollectionVariables) => Promise<void>
  deleteCollection: (variables: DeleteCollectionVariables) => Promise<void>
}

async function getCollections(): Promise<Collection[]> {
  return getRequest<CollectionsResponse>('/collection').then((response) =>
    response.data.items.sort(
      (a, b) =>
        new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
    ),
  )
}

async function getCollectionItems(
  collectionId: string,
): Promise<CollectionItem[]> {
  return postRequest<CollectionItemsResponse>('/workspace/item/list/proxy', {
    workspaceId: collectionId,
  }).then((response) => response.data.items)
}

async function postNewCollection({
  name,
  desc,
  tags,
}: CreateCollectionVariables) {
  return postRequest(`/collection`, { name, desc, tags })
}

async function shareCollection({
  emails,
  message,
  collectionId,
}: ShareCollectionVariables) {
  return postRequest(`/collection/${collectionId}/share`, {
    emails,
    message,
    collectionId,
  })
}

async function postAddCollectionItem({
  collectionId,
  document,
}: AddToCollectionVariables) {
  return postRequest(`/workspace/item/create/proxy`, {
    workspaceId: collectionId,
    doc: document,
  })
}

async function deleteCollectionItem({ itemId }: RemoveFromCollectionVariables) {
  return postRequest(`/workspace/item/delete/proxy`, { id: itemId })
}

async function postUpdateCollection({
  id,
  name,
  desc,
}: ModifyCollectionVariables) {
  return postRequest(`/collection/update`, { id, name, desc })
}

async function deleteCollection({ collectionId }: DeleteCollectionVariables) {
  return deleteRequest(`/collection/${collectionId}`)
}

export function useCollections(): UseCollectionsReturn {
  const { isAuthenticated } = useDantiAuth()
  const collections = useQuery({
    queryKey: ['collections'],
    queryFn: getCollections,
    retry: 1,
    enabled: isAuthenticated,
  })

  const collectionItems = useQueries({
    queries:
      collections.data?.map(({ id }) => ({
        queryKey: ['collection', id],
        queryFn: () => getCollectionItems(id),
        retry: 1,
        enabled: collections.data?.length > 0,
      })) ?? [],
  })

  const createMutation = useMutation({ mutationFn: postNewCollection })
  const shareMutation = useMutation({ mutationFn: shareCollection })
  const addMutation = useMutation({ mutationFn: postAddCollectionItem })
  const deleteMutation = useMutation({ mutationFn: deleteCollectionItem })
  const updateMutation = useMutation({ mutationFn: postUpdateCollection })
  const deleteCollectionMutation = useMutation({ mutationFn: deleteCollection })

  // TODO: We should probably target specific collections to refetch, but this
  //  just fetches all of them for now
  function refreshCollections() {
    void collections.refetch()
    for (const item of collectionItems) {
      void item.refetch()
    }
  }

  async function createCollection({
    name,
    desc,
    tags,
  }: CreateCollectionVariables) {
    await createMutation.mutateAsync(
      { name, desc, tags },
      { onSuccess: refreshCollections },
    )
  }

  async function shareCollectionWithVendor({
    emails,
    message,
    collectionId,
  }: ShareCollectionVariables) {
    await shareMutation.mutateAsync(
      { emails, message, collectionId },
      {
        onSuccess: refreshCollections,
      },
    )
  }

  async function addItem({ collectionId, document }: AddToCollectionVariables) {
    await addMutation.mutateAsync(
      { collectionId, document },
      { onSuccess: refreshCollections },
    )
  }

  async function removeItem({
    collectionId,
    itemId,
  }: RemoveFromCollectionVariables) {
    await deleteMutation.mutateAsync(
      { collectionId, itemId },
      { onSuccess: refreshCollections },
    )
  }

  async function updateCollection({
    id,
    name,
    desc,
    tags,
  }: ModifyCollectionVariables) {
    await updateMutation.mutateAsync(
      { id, name, desc, tags },
      { onSuccess: refreshCollections },
    )
  }

  async function deleteCollectionFn({
    collectionId,
  }: DeleteCollectionVariables) {
    await deleteCollectionMutation.mutateAsync(
      { collectionId: collectionId },
      { onSuccess: refreshCollections },
    )
  }

  return {
    shareCollectionWithVendor,
    createCollection,
    addItem,
    removeItem,
    updateCollection,
    deleteCollection: deleteCollectionFn,
    data:
      collections.data?.map((collection, index) => ({
        ...collection,
        items: collectionItems[index]?.data ?? [],
      })) ?? [],
  }
}
