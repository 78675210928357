import type { Filters } from '@/stores/filters-store.ts'
import { parseSearchQuery } from '@/utils/search-filters/parse-filters.ts'

export const makeSemanticQueryFilters = (query: string): Partial<Filters> => {
  const extractionResult = parseSearchQuery(query)

  // map over the extractionResult providers and replace "PLANET" with "Planet Labs"
  if (extractionResult.providers) {
    extractionResult.providers.ids = extractionResult.providers.ids.map(
      (provider) => (provider === 'PLANET' ? 'Planet Labs' : provider),
    )
  }

  const timePreset: Partial<Filters> = extractionResult.dateRange
    ? { timePreset: 'Custom' }
    : {}
  const customTimeStart: Partial<Filters> = extractionResult.dateRange
    ? { customTimeStart: new Date(extractionResult.dateRange.start) }
    : {}
  const customTimeEnd: Partial<Filters> = extractionResult.dateRange
    ? { customTimeEnd: new Date(extractionResult.dateRange.end) }
    : {}
  const providers: Partial<Filters> = extractionResult.providers
    ? { providers: extractionResult.providers.ids }
    : {}
  const sensors: Partial<Filters> = extractionResult.sensors
    ? { sensors: extractionResult.sensors.sensors }
    : {}
  const location: Partial<Filters> = extractionResult.location
    ? { location: extractionResult.location }
    : {}
  const locationType: Partial<Filters> = extractionResult.locationType
    ? { locationType: extractionResult.locationType }
    : {}
  const quality: Partial<Filters> = extractionResult.quality
    ? { quality: extractionResult.quality.gsd }
    : {}
  const cloudCover: Partial<Filters> = extractionResult.cloudCover
    ? { cloudCover: extractionResult.cloudCover.coverage }
    : {}
  const angle: Partial<Filters> = extractionResult.angle
    ? { angle: extractionResult.angle.angle }
    : {}
  const orientations: Partial<Filters> = extractionResult.orientations
    ? { orientations: extractionResult.orientations.args }
    : {}
  const keywords: Partial<Filters> = extractionResult.keywords
    ? { keywords: extractionResult.keywords.keywords }
    : {}

  return {
    ...timePreset,
    ...customTimeStart,
    ...customTimeEnd,
    ...providers,
    ...sensors,
    ...location,
    ...locationType,
    ...quality,
    ...cloudCover,
    ...angle,
    ...orientations,
    ...keywords,
  }
}
