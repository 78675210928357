import React from 'react'
import { SimpleGrid } from '@mantine/core'
import { ShipResult } from '@/components/lib/results/Result'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  isShipResult,
  type ShipResult as ShipResultType,
} from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'
import { PanelBase } from './'

interface ShipPanelProps {
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
}

export function ShipPanel({ isFullscreen = false, position }: ShipPanelProps) {
  const sortPreference = queryStore((state) => state.sortPreference)
  const unfilteredShipResults: ShipResultType[] = resultsStore((state) =>
    state.categorized.dataResults.filter(isShipResult),
  )
  const filteredShipResults =
    useTimelineFilteredResults().categorized.dataResults.filter(isShipResult)
  const shipResults = isFullscreen ? filteredShipResults : unfilteredShipResults

  const sortedResults = React.useMemo(() => {
    return orderBy(shipResults, sortPreference, 'desc')
  }, [shipResults, sortPreference])

  const shouldDisplay = shipResults.length > 0 || isFullscreen

  return (
    shouldDisplay && (
      <PanelBase
        titleShort="Ship"
        titleLong="All Ship Data"
        isFullscreen={isFullscreen}
        isolateType={isShipResult}
        link="/ships"
        position={position}
      >
        <SimpleGrid
          cols={isFullscreen ? { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 } : 3}
          spacing={isFullscreen ? 'xl' : 'lg'}
          verticalSpacing={isFullscreen ? 'md' : 70}
        >
          {sortedResults
            .slice(0, isFullscreen ? undefined : 3)
            .map((result) => (
              <ShipResult
                key={result.documentId}
                result={result}
                isFullscreen
              />
            ))}
        </SimpleGrid>
      </PanelBase>
    )
  )
}
