import { useCallback, useEffect } from 'react'
import { useWebsocket } from '@/hooks/use-websocket.ts'
import { addChatMessage, clearChat } from '@/stores/chat-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { userStore } from '@/stores/user-store.ts'
import { singletonHook } from 'react-singleton-hook'

const useChatImpl = () => {
  const { sendMessage } = useWebsocket()
  const queryId = resultsStore((state) => state.currentQueryId)
  const userId = userStore((state) => state.email)

  const sendPrompt = useCallback(
    (prompt: string) => {
      if (queryId) {
        const action = 'chatRequest'
        const message = { prompt, queryId, userId }
        sendMessage(action, message)
        addChatMessage({ action, event: action, ...message })
      }
    },
    [queryId, sendMessage, userId],
  )

  useEffect(() => {
    if (queryId) {
      clearChat()
    }
  }, [queryId])

  return { sendPrompt }
}

export const useChat = singletonHook<{
  sendPrompt: (prompt: string) => void
}>(() => ({ sendPrompt: () => {} }), useChatImpl)
