import { useState } from 'react'
import {
  Button,
  Checkbox,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { isEmail, useForm } from '@mantine/form'
import type { ContextModalProps } from '@mantine/modals'
import { useCollections } from '@/utils/data-fetching/collections.ts'
import type {
  Collection,
  ShareWithVendorForm,
} from '@/utils/types/collection-types.ts'
import { captureException } from '@sentry/react'

export function DownloadCollectionModal({
  context,
  id,
  innerProps: { collection },
}: ContextModalProps<{ collection: Collection }>) {
  const { shareCollectionWithVendor } = useCollections()
  const [isErrorState, setErrorState] = useState(false)
  const collectionId = collection.id

  async function handleShare(values: ShareWithVendorForm) {
    const { emails, message } = values
    try {
      await shareCollectionWithVendor({ emails, message, collectionId })
      context.closeModal(id)
    } catch {
      setErrorState(true)
      captureException(
        `Could not share with vendor, fields: ${emails}, ${message}, ${collectionId}`,
      )
      console.dir('error in share collection')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
  const form = useForm<ShareWithVendorForm>({
    initialValues: { emails: '', consent: false, message: '' },

    // functions will be used to validate values at corresponding key
    validate: {
      message: (value: string) =>
        value.length < 2 ? 'Must include instructions' : null,
      emails: isEmail('Invalid email'),
      consent: (value) => (value ? null : 'Must acknowledge consent'),
    },
  })

  return (
    <>
      {isErrorState ? (
        <>Error on submit</>
      ) : (
        <form
          onSubmit={form.onSubmit(
            (values: ShareWithVendorForm) => void handleShare(values),
          )}
        >
          <Stack p="12px">
            <Text fw={500} size="22px">
              {collection?.name}
            </Text>
          </Stack>
          <Stack p="12px">
            <TextInput
              label="Email"
              placeholder="Enter Email"
              required
              withAsterisk
              {...form.getInputProps('emails')}
            />
          </Stack>
          <Stack p="12px">
            <Checkbox
              label="I acknowledge that recipients of this email are authorized to view this imagery"
              required
              {...form.getInputProps('consent')}
            />
          </Stack>
          <Stack p="12px">
            <Textarea
              label="Instructions for Vendor"
              labelProps={{ fontSize: '16px', fontWeight: '700' }}
              size="sm"
              required
              withAsterisk
              {...form.getInputProps('message')}
              data-cy="share-collection-emails"
            />
          </Stack>
          <Group justify="space-between" mt="md">
            <Button variant="subtle" onClick={() => context.closeModal(id)}>
              Cancel
            </Button>
            <Button type="submit">Share</Button>
          </Group>
        </form>
      )}
    </>
  )
}
