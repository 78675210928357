import React from 'react'
import { Box, Button, Group, Radio, Stack } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useCollections } from '@/utils/data-fetching/collections.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import { openCreateCollectionModal } from './openers'

import styles from './add-to-collection-modal.module.css'

export function AddToCollectionModal({
  context,
  id,
  innerProps: { item },
}: ContextModalProps<{ item: ResultWithId }>) {
  const { addItem, data: collections } = useCollections()
  const [selectedCollectionId, setSelectedCollectionId] =
    React.useState<string>(collections[0]?.id ?? '')

  React.useLayoutEffect(() => {
    setSelectedCollectionId(collections[0]?.id ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections.length])

  async function handleAdd() {
    await addItem({ collectionId: selectedCollectionId, document: item })
    context.closeModal(id)
  }

  return (
    <>
      <Stack>
        <Button
          size="xs"
          onClick={openCreateCollectionModal}
          variant="white"
          ml="auto"
        >
          New Collection
        </Button>
      </Stack>
      <Box className={styles['collectionsContainer']} mah={300} px="md" py="sm">
        <Radio.Group
          value={selectedCollectionId}
          onChange={setSelectedCollectionId}
        >
          <Stack gap="sm">
            {collections.map((collection) => (
              <Radio
                key={collection.id}
                value={collection.id}
                label={collection.name}
                data-cy="collection-option"
              />
            ))}
          </Stack>
        </Radio.Group>
      </Box>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button onClick={() => void handleAdd()}>Add</Button>
      </Group>
    </>
  )
}
