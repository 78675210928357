import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Button,
  Card,
  Flex,
  Group,
  Menu,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  // openAnalyzeCollectionModal,
  openCreateCollectionModal,
  openDeleteCollectionModal,
  openDownloadCollectionModal,
} from '@/components/modals/openers.tsx'
import { useCollections } from '@/utils/data-fetching/collections.ts'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default function Collections() {
  const { data } = useCollections()
  const navigate = useNavigate()

  return (
    <>
      <Group justify="space-between" mt="xl">
        <Title c="#424242">
          Collections{' '}
          <Text
            component="span"
            fw="normal"
            ml="md"
            style={{ fontSize: '1.825rem' }}
          >
            {data.length}
          </Text>
        </Title>
        <Button
          variant="filled"
          size="sm"
          leftSection={<Icon name="add" />}
          onClick={openCreateCollectionModal}
        >
          New Collection
        </Button>
      </Group>
      <SimpleGrid cols={3} spacing="sm" mt="xl">
        {data.map((collection) => (
          <Card
            key={collection.id}
            p="sm"
            withBorder
            radius="md"
            style={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F8F8F8',
              },
            }}
          >
            <Flex direction="column" justify="space-between" h="100%">
              <Stack
                gap="xs"
                onClick={() => navigate(`/collections/${collection.id}`)}
              >
                <Title order={2} c="#424242">
                  {collection.name}
                </Title>
                <Text size="sm" c="dimmed" mt="xs">
                  {collection.desc}
                </Text>
                <Text size="xs" c="dimmed" mt="xs">
                  {collection.modifiedOn
                    ? `Updated ${dayjs(collection.modifiedOn).fromNow()}`
                    : `Created ${dayjs(collection.createdOn).fromNow()}`}
                </Text>
              </Stack>
              <Group justify="space-between" mt="xl">
                <Group gap="0.25rem" style={{ pointerEvents: 'none' }}>
                  <Icon name="draft" />
                  <Text lh={1}>{collection.items.length}</Text>
                </Group>
                <Menu shadow="md" withinPortal>
                  <Menu.Target>
                    <ActionIcon variant="subtle" data-cy="collection-menu">
                      <Icon name="more_horiz" />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    {/* TODO update again when collection wide analysis is online
                    <Menu.Item
                      leftSection={<Icon name="analytics" />}
                      onClick={() => openAnalyzeCollectionModal(collection.id)}
                      data-cy="collection-analyze"
                    >
                      Image Detections
                    </Menu.Item> */}
                    <Menu.Item
                      leftSection={<Icon name="send" />}
                      onClick={() => openDownloadCollectionModal(collection)}
                      data-cy="collection-download"
                    >
                      Send to Vendor
                    </Menu.Item>
                    <Menu.Item
                      leftSection={<Icon name="delete" />}
                      color="red"
                      onClick={() => openDeleteCollectionModal(collection.id)}
                      data-cy="collection-delete"
                    >
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Flex>
          </Card>
        ))}
      </SimpleGrid>
    </>
  )
}
