import type {
  SearchApiFilterValue,
} from '@/utils/types/filter-types.ts'

export const makeProviderTypeFilter = (
  providerTypes: string[],
): SearchApiFilterValue => {
  return {
    type: 'PROVIDER',
    ids: providerTypes.map((providerType) => {
      if (providerType === 'PLANET') {
        return 'Planet Labs'
      }
      return providerType
    }),
  }
}
