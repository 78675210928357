import { featureHubApiKey } from '@/utils/constants.ts'
import { FeatureHub } from 'featurehub-react-sdk'
import type { PropsWithChildren } from 'react'
import { useAuth } from 'react-oidc-context'

export const DantiFeatureHubProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth()

  return (
    <FeatureHub
      url="https://app.featurehub.io/vanilla"
      apiKey={featureHubApiKey}
      // FIXME: Neither of the following props appear to work. The above `apiKey`
      //  prop is appended as a query parameter to the `url` prop, but these two
      //  are mysteriously ignored and no record of them is sent in the request.
      userKey={user?.profile.email}
      pollInterval={600000}
    >
      {children}
    </FeatureHub>
  )
}
