import { notifications } from '@mantine/notifications'
import { useWebsocket } from '@/hooks/use-websocket.ts'
import { clearChat } from '@/stores/chat-store.ts'
import { resetFilters } from '@/stores/filters-store.ts'
import { mapDrawStore } from '@/stores/map-draw-store.ts'
import { mapStore } from '@/stores/map-store.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsFilterStore } from '@/stores/results-filter-store.ts'
import { resultsStore } from '@/stores/results-store.ts'

export function useClearAll() {
  const [
    clearSearchPolygon,
    clearSearchHereMarker,
    stopDrawingPolygon,
    setIsDrawing,
    setLastSearchPolygon,
  ] = mapDrawStore((s) => [
    s.clearSearchPolygon,
    s.clearSearchHereMarker,
    s.stopDrawingPolygon,
    s.setIsDrawing,
    s.setLastSearchPolygon,
  ])
  const [
    toggleSelectedHexGridId,
    selectedHexGridId,
    setHoveredHexGridId,
    clearOrthoLayerIds,
    setGeoLocation,
  ] = mapStore((s) => [
    s.toggleSelectedHexGridId,
    s.selectedHexGridId,
    s.setHoveredHexGridId,
    s.clearOrthoLayerGroupIds,
    s.setMapBbox,
  ])
  const [clearResults, setCurrentQueryId] = resultsStore((s) => [
    s.clearResults,
    s.setCurrentQueryId,
  ])
  const clearQuery = queryStore((s) => s.clear)
  const resetPostFilters = resultsFilterStore((s) => s.resetPostfilters)
  const { closeSocket } = useWebsocket()

  // First: closes socket to stop receiving results
  // Clears all search state: results, query, filters, chat, and map
  function clearAll(preservePolygon = false) {
    notifications.clean()
    closeSocket()
    clearResults()
    setCurrentQueryId(null)
    clearQuery()
    resetFilters()
    clearChat()
    stopDrawingPolygon()
    setIsDrawing(false)
    if (selectedHexGridId) {
      toggleSelectedHexGridId(selectedHexGridId)
    }
    setHoveredHexGridId(null)
    setGeoLocation(null)

    clearOrthoLayerIds()

    if (!preservePolygon) {
      clearSearchPolygon()
      setLastSearchPolygon(null)
    }
    clearSearchHereMarker()

    resetPostFilters()
  }

  return clearAll
}
