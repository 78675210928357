import type { DataResult, ResultWithId } from '@/utils/types/result-types.ts'
import { parseUsAddress } from '@/utils/types/single-address/us-address-types.ts'
import type { Polygon } from 'geojson'
import type { ParsedAddress } from 'vladdress'

export interface RegridRecord {
  fields: {
    lat: string
    lon: string
    mailadd: string
    mail_city: string
    mail_state2: string
    mail_zip: string
    saddno: string
    saddpref: string | undefined
    saddstr: string
    saddsttyp: string
    saddstsuf: string | undefined
    scity: string
    state2: string
    szip: string
  }
  geometry: Polygon
}

export type RegridResult = DataResult & { properties: RegridRecord }

export const isRegridResult = (r: ResultWithId): r is RegridResult => {
  return r.source.toLowerCase() === 'regrid'
}

export const parseRegridAddress = (r: RegridRecord): Partial<ParsedAddress> => {
  return parseUsAddress(
    `${r.fields.saddno} ${r.fields.saddpref ? `${r.fields.saddpref} ` : ''} ${
      r.fields.saddstr
    } ${r.fields.saddsttyp}${
      r.fields.saddstsuf ? ` ${r.fields.saddstsuf}` : ''
    }, ${r.fields.scity}, ${r.fields.state2} ${r.fields.szip}`,
  )
}
