import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Card,
  type CardProps,
  Flex,
  Menu,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  type ActionMenuOption,
  actionMenuOptions,
  type ActionMenuType,
  isActionMenuOption,
} from '@/components/lib/results/Result/action-menu-options.ts'
import { openResultDetailsModal } from '@/components/modals/openers.tsx'
import { makeOrthoLayer } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable.ts'
import { useRelatedImagerySearch } from '@/hooks/use-related-imagery-search.ts'
import { configStore } from '@/stores/config-store.ts'
import { mapStore } from '@/stores/map-store.ts'
import {
  isImageryResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { classes } from '@/utils/ui-helpers.tsx'
import { captureException } from '@sentry/react'
import { useFeature } from 'featurehub-react-sdk'

import styles from './result-base.module.css'

interface ResultBaseProps extends CardProps {
  result: ResultWithId
  children?: React.ReactNode
  hideActions?: ActionMenuOption[]
  onClick?: ActionMenuOption | (() => void)
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  className?: string
}

export const ResultBase = React.forwardRef(
  (
    {
      result,
      children,
      hideActions = [],
      onClick,
      onMouseEnter,
      onMouseLeave,
      className,
      ...props
    }: ResultBaseProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const navigate = useNavigate()
    const { doRelatedImagerySearch } = useRelatedImagerySearch()
    const [toggleOrthoLayerGroupId, clearOrthoLayerGroupIds] = mapStore((s) => [
      s.toggleOrthoLayerGroupId,
      s.clearOrthoLayerGroupIds,
    ])
    const { data: isDownloadable } = useIsDownloadable(result)
    const shouldShowScores = useFeature('SHOW_SCORES')
    const showScores =
      shouldShowScores && configStore((state) => state.showScores)

    const adjustedActionMenuOptions: Record<string, ActionMenuType> = {
      ...actionMenuOptions,
      downloadImage: {
        ...actionMenuOptions['downloadImage'],
        disabled: !isDownloadable,
        title: isDownloadable ? undefined : 'Not available for download',
      },
      findRelatedImages: {
        ...actionMenuOptions['findRelatedImages'],
        onClick: (clickedResult: ResultWithId) =>
          void doRelatedImagerySearch(clickedResult.thumbnail ?? ''),
      },
      viewOnMap: {
        ...actionMenuOptions['viewOnMap'],
        onClick: (clickedResult: ResultWithId) => {
          if (isImageryResult(clickedResult)) {
            const layer = makeOrthoLayer(clickedResult)
            if (layer) {
              clearOrthoLayerGroupIds()
              toggleOrthoLayerGroupId(layer.groupKey)
              navigate(`/map/${clickedResult.id}`)
            } else {
              captureException(
                `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
              )
            }
          }
        },
      },
      viewDetails: {
        ...actionMenuOptions['viewDetails'],
        onClick: (clickedResult: ResultWithId) => {
          if (isImageryResult(clickedResult)) {
            const layer = makeOrthoLayer(clickedResult)
            if (layer) {
              clearOrthoLayerGroupIds()
              toggleOrthoLayerGroupId(layer.groupKey)
              navigate(`/results/${clickedResult.id}`)
            } else {
              captureException(
                `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
              )
            }
          } else {
            openResultDetailsModal(clickedResult)
          }
        },
      },
    }

    const isAnImageResult = isImageryResult(result)

    if (!isAnImageResult) {
      hideActions.push('downloadItem')
    }

    const onClickAction = isActionMenuOption(onClick)
      ? adjustedActionMenuOptions[onClick].onClick
      : onClick

    const actions = Object.keys(adjustedActionMenuOptions)
      .filter((option) => !hideActions.includes(option))
      .map((action) => adjustedActionMenuOptions[action])

    return (
      <Card
        ref={forwardedRef}
        onClick={() => onClickAction?.(result)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes(styles['hidden-panel'], className)}
        radius={0}
        pos="relative"
        p={0}
        pb={isAnImageResult && showScores ? 'sm' : 0}
        fz="sm"
        c="gray.5"
        {...props}
      >
        {children}
        {showScores && (
          <Flex
            pos="absolute"
            bottom={0}
            left={isAnImageResult ? 0 : 'var(--mantine-spacing-sm)'}
          >
            <Text size="xs" fw={700}>
              score: {result.score}
            </Text>
          </Flex>
        )}
        <Flex className={styles['reveal']} pos="absolute" inset={0}>
          <Flex pos="relative" h="100%" w="100%">
            <Menu trigger="hover" shadow="md">
              <Menu.Target data-cy="resultActionMenu">
                <ActionIcon
                  variant="white"
                  className={styles['clickable']}
                  onClick={(event) => event.stopPropagation()}
                >
                  <Icon name="more_vert" size="xl" />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {actions.map(
                  ({ label, icon, onClick: actionOnClick, ...passProps }) => {
                    return label === 'Download' && !isDownloadable ? null : (
                      <Menu.Item
                        key={label}
                        {...passProps}
                        leftSection={<Icon name={icon} />}
                        onClick={(event) => {
                          event.stopPropagation()
                          void actionOnClick(result)
                        }}
                        p="xs"
                        className={styles['menu-hover']}
                      >
                        {label}
                      </Menu.Item>
                    )
                  },
                )}
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
      </Card>
    )
  },
)

ResultBase.displayName = 'ResultBase'
