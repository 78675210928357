import React from 'react'
import { openSessionConfirmationModal } from '@/components/modals/openers.tsx'
import { useAuth } from 'react-oidc-context'

export function useDantiAuth() {
  const auth = useAuth()
  const { isLoading, isAuthenticated, error, user, events } = auth

  function logIn() {
    void auth.signinRedirect()
  }
  async function logOut() {
    window.localStorage.removeItem('UserStore')
    window.localStorage.removeItem('Results')
    window.localStorage.removeItem('ChatStore')
    window.document.cookie = ''
    await auth.revokeTokens()
    await auth.removeUser()
  }

  React.useEffect(
    () =>
      events.addAccessTokenExpired(() => {
        openSessionConfirmationModal()
      }),
    [events],
  )

  return {
    user,
    logIn,
    logOut,
    isLoading,
    isAuthenticated,
    error,
  }
}
