import { Anchor, Group, SimpleGrid, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { AutoAskWidget } from '@/components/lib/single-address/AutoAskWidget.tsx'
import { useChat } from '@/hooks/use-chat.ts'
import type { PropertyData } from '@/hooks/use-single-address-components.ts'
import type { LabeledScoredRisk } from '@/utils/types/single-address/hazard-risk-types.ts'
import type { FC } from 'react'

type RiskPanelProps = {
  propertyData: PropertyData
  risksScoredC: LabeledScoredRisk[]
  risksScoredD: LabeledScoredRisk[]
  risksScoredF: LabeledScoredRisk[]
}
export const RiskPanel: FC<RiskPanelProps> = ({
  risksScoredC,
  risksScoredD,
  risksScoredF,
}) => {
  const { sendPrompt } = useChat()
  const [expanded, expandHandlers] = useDisclosure(false)
  const makeRiskBox = (r: LabeledScoredRisk, index: number) => {
    const color =
      r.record.score === 'F'
        ? '#e65100'
        : r.record.score === 'D'
        ? '#ed6c02'
        : 'black'
    return (
      <Stack
        key={`risk_d_text_${index}`}
        gap={2}
        style={{
          boxShadow:
            '0px -1px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.27) inset',
          padding: 8,
          borderRadius: 8,
          cursor: 'help',
        }}
        onClick={() =>
          sendPrompt(`Please tell me more about the ${r.label} risk.`)
        }
      >
        <Text c={color} size="sm">
          {r.label}: {r.record.score}
        </Text>
        <Text c="#8f8f8f" size="xs">
          {r.record.text}
        </Text>
      </Stack>
    )
  }

  const combinedRisks = [...risksScoredF, ...risksScoredD, ...risksScoredC]
  const shownRisks = expanded ? combinedRisks : combinedRisks.slice(0, 4)
  return (
    <Stack gap={8}>
      <Group gap="xs">
        <Text fw="bold">Risk</Text>
        <AutoAskWidget prompt="Please give me a paragraph summarizing the risks rated 'D' and 'F'." />
      </Group>
      <SimpleGrid cols={2} spacing={8} style={{ margin: 8 }}>
        {shownRisks.map(makeRiskBox)}
      </SimpleGrid>
      {combinedRisks.length > 4 && (
        <Anchor size="xs" onClick={() => expandHandlers.toggle()}>
          {expanded ? 'Collapse' : `And ${combinedRisks.length - 4} more...`}
        </Anchor>
      )}
    </Stack>
  )
}
