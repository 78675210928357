import { useEffect, useMemo, useState } from 'react'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import {
  getVexcelOrthoCoverage,
  type VexcelOrthoCoverageAoi,
} from '@/utils/data-fetching/vexcel-ortho-coverage.ts'
import type { Polygon } from 'geojson'

export const useVexcel = (parcelPolygon: Polygon | undefined) => {
  const {
    categorized: { imageResults },
  } = useTimelineFilteredResults()
  const [orthoCoverage, setOrthoCoverage] = useState<VexcelOrthoCoverageAoi[]>(
    [],
  )

  useEffect(() => {
    if (parcelPolygon) {
      //Wait for auth to be ready
      void new Promise((resolve, _) => {
        setTimeout(resolve, 500)
      })
        .then(() => {
          return getVexcelOrthoCoverage(parcelPolygon)
        })
        .then((aois: VexcelOrthoCoverageAoi[]) => {
          setOrthoCoverage(
            aois.sort(
              (a, b) =>
                new Date(b.minDate).valueOf() - new Date(a.minDate).valueOf(),
            ),
          )
        })

      return
    }
  }, [parcelPolygon])

  const sortedVexcelImages = useMemo(() => {
    return imageResults
      .filter((ir) => ir.source.toLowerCase() === 'vexcel')
      .sort(
        (a, b) =>
          new Date(b.authoredOn).valueOf() - new Date(a.authoredOn).valueOf(),
      )
  }, [imageResults])

  return { sortedVexcelImages, orthoCoverage }
}
