import { useMemo } from 'react'
import {
  isGegd,
  makeGegdOrthoLayer,
  useGegd,
} from '@/hooks/ortho-imagery/use-gegd.ts'
import {
  isPlanet,
  makePlanetOrthoLayer,
  usePlanet,
} from '@/hooks/ortho-imagery/use-planet.ts'
import { queryStore } from '@/stores/queries-store.ts'
import type {
  OrthoImageryLayer,
  OrthoImageryLayerGroup,
} from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'
import * as turf from '@turf/turf'
import type { MultiPolygon } from 'geojson'
import { groupBy, sortBy } from 'lodash'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'

export const makeOrthoLayer = (
  ir: ImageryResult,
): OrthoImageryLayer | undefined => {
  if (isGegd(ir)) {
    return makeGegdOrthoLayer(ir)
  }
  if (isPlanet(ir)) {
    return makePlanetOrthoLayer(ir)
  }
  Sentry.captureMessage(
    `Could not find ortho layer provider for Image ID ${ir.documentId}`,
  )
  return undefined
}
export const useOrthoImagerySources = () => {
  const [sortPreference] = queryStore((state) => [state.sortPreference])

  const { planetOrthoLayers } = usePlanet()
  const { gegdOrthoLayers } = useGegd()

  const orthoLayers = useDeepCompareMemoize([
    ...planetOrthoLayers,
    ...gegdOrthoLayers,
  ])
  // Creates Mosiac of Layers
  const orthoLayerGroups: OrthoImageryLayerGroup[] = useMemo(() => {
    const groups = groupBy(orthoLayers, (ol) => ol.groupKey)

    const sortedResults = sortBy(
      Object.keys(groups).map((groupKey) => {
        const layers = groups[groupKey]
        const skySatCollectLayer = layers.find((l) =>
          l.tileUrl.includes('SkySatCollect'),
        )
        if (skySatCollectLayer) {
          return {
            source: skySatCollectLayer.source,
            formattedSource: skySatCollectLayer.formattedSource,
            layers: [skySatCollectLayer],
            id: groupKey,
            authoredOn: skySatCollectLayer.authoredOn,
            score: skySatCollectLayer.score,
            geometry: skySatCollectLayer.geometry,
          }
        }
        return {
          source: layers[0].source,
          formattedSource: layers[0].formattedSource,
          layers,
          id: groupKey,
          authoredOn: layers[0].authoredOn,
          score: layers[0].score,
          geometry: layers
            .map((l) => l.geometry)
            .reduce((result, nextGeometry) => {
              try {
                return turf.union(nextGeometry, result)
                  ?.geometry as MultiPolygon
              } catch (error) {
                Sentry.captureException(error, { extra: { layers, groupKey } })
                console.error('Failed to union geometries', error)
                return result
              }
            }, turf.multiPolygon([]).geometry),
        }
      }),
      (olg) => olg[sortPreference],
    )
    return sortPreference === 'authoredOn'
      ? sortedResults.reverse()
      : sortedResults
  }, [orthoLayers, sortPreference])

  return {
    orthoLayerGroups,
    orthoLayers,
  }
}
