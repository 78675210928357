import { createStore } from '@/utils/create-store.ts'
import type { CategoryTypes } from '@/utils/types/result-types'
import type { BBox } from 'geojson'
import type mapboxgl from 'mapbox-gl'

export type HexGridCellPropertyResultKeys = Exclude<
  CategoryTypes,
  'INSIGHT' | 'RELATED_IMAGERY'
>
export type HexGridCellProperties = {
  id: number
  resultIds: { [key in HexGridCellPropertyResultKeys]: string[] }
  resultCount: number
}

export type VisualizationModeOptions = 'grid' | 'extents' | 'none'
export type BaseLayerOption = 'light-v11' | 'dark-v11' | 'satellite-streets-v9'
export type GridCellProperties = {
  [key: string]: HexGridCellProperties
}

export interface MapState {
  visualizationMode: VisualizationModeOptions
  baseLayer: BaseLayerOption
  gridCellProperties: {
    [key: string]: HexGridCellProperties
  }

  currentMapBounds: mapboxgl.LngLatBounds | null
  selectedHexGridId: number | null
  selectedPointId: string | null
  hoveredHexGridId: number | null
  selectedExtentIds: string[]
  hoveredExtentIds: string[]
  setVisualizationMode: (mode: VisualizationModeOptions) => void
  setBaseLayer: (baseLayer: BaseLayerOption) => void
  setCurrentMapBounds: (currentMapBounds: mapboxgl.LngLatBounds | null) => void
  toggleSelectedHexGridId: (selectedHexGridId: number | null) => void
  setHoveredHexGridId: (hoveredHexGridId: number | null) => void
  setSelectedPointId: (selectedPointId: string | null) => void
  setSelectedExtentIds: (extentIds: string[]) => void
  setHoveredExtentIds: (extentIds: string[]) => void
  setGridCellProperties: (gridCellProperties: GridCellProperties) => void

  activeOrthoLayerGroupIds: string[]
  setActiveOrthoLayerGroupIds: (ids: string[]) => void
  toggleOrthoLayerGroupId: (id: string) => void
  clearOrthoLayerGroupIds: () => void

  hoveredLayerId: string | null
  setHoveredLayerId: (prefix: string | null) => void

  mapBbox: BBox | null
  setMapBbox: (bbox: BBox | null) => void
}

export const  mapStore = createStore<MapState>({
  initialState: {
    visualizationMode: 'extents',
    baseLayer: 'light-v11',
    gridCellProperties: {},
    currentMapBounds: null,
    hoveredHexGridId: null,
    selectedHexGridId: null,
    selectedExtentIds: [],
    hoveredExtentIds: [],
    activeOrthoLayerGroupIds: [],
    hoveredLayerId: null,
    selectedPointId: null,
    mapBbox: null,
  },
  actions: (setState, getState) => ({
    setVisualizationMode: (visualizationMode) =>
      setState({ visualizationMode }),

    setBaseLayer: (baseLayer: BaseLayerOption) => setState({ baseLayer }),
    setCurrentMapBounds: (currentMapBounds: mapboxgl.LngLatBounds | null) =>
      setState({ currentMapBounds }),
    toggleSelectedHexGridId: (selectedHexGridId: number | null) =>
      setState({
        selectedHexGridId:
          getState().selectedHexGridId === selectedHexGridId
            ? null
            : selectedHexGridId,
      }),
    setHoveredHexGridId: (hoveredHexGridId: number | null) =>
      setState({ hoveredHexGridId }),
    setSelectedPointId: (selectedPointId: string | null) =>
      setState({ selectedPointId }),
    setHoveredExtentIds: (extentIds: string[]) => setState({ hoveredExtentIds: extentIds }),
    setSelectedExtentIds: (extentIds: string[]) => setState({ selectedExtentIds: extentIds }),
    setGridCellProperties: (gridCellProperties: GridCellProperties) =>
      setState({ gridCellProperties }),
    setActiveOrthoLayerGroupIds: (ids: string[]) =>
      setState({ activeOrthoLayerGroupIds: [...ids] }),
    toggleOrthoLayerGroupId: (id: string) => {
      const activeOrthoLayerIds = new Set(getState().activeOrthoLayerGroupIds)
      activeOrthoLayerIds?.has(id)
        ? activeOrthoLayerIds?.delete(id)
        : activeOrthoLayerIds?.add(id)
      setState({
        activeOrthoLayerGroupIds: [...activeOrthoLayerIds],
      })
    },
    clearOrthoLayerGroupIds: () => setState({ activeOrthoLayerGroupIds: [] }),
    setHoveredLayerId: (hoveredLayerIdPrefix) =>
      setState({ hoveredLayerId: hoveredLayerIdPrefix }),
    setMapBbox: (bbox) => setState({ mapBbox: bbox }),
  }),
})
