import { type MutableRefObject, useEffect } from 'react'
import { makeAnalyticResultPolygons } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import {
  type AnalyticResult,
  type ImageryResult,
  isAnalyticResult,
} from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'

export const useAnalyticLayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
  analytics: AnalyticResult | ImageryResult,
) => {
  const ANALYTIC_POLYGONS = 'analytic_polygons'

  useEffect(() => {
    if (analytics && isAnalyticResult(analytics)) {
      const analyticLayer = makeAnalyticResultPolygons(
        analytics.properties._dAnalytic,
      )

      map.current?.on('styledata', () => {
        if (!map.current?.getSource(ANALYTIC_POLYGONS) && analyticLayer) {
          const bounds = turf.bbox(analyticLayer) as [
            number,
            number,
            number,
            number,
          ]
          map.current?.addSource(ANALYTIC_POLYGONS, {
            type: 'geojson',
            data: analyticLayer,
          })

          map.current?.addLayer({
            type: 'line',
            source: ANALYTIC_POLYGONS,
            id: `${ANALYTIC_POLYGONS}_layer`,
            paint: {
              'line-color': '#0091EA',
            },
          })
          map.current?.fitBounds(bounds)
        }
      })
    }
  }, [analytics, map])
}
