import { useLocation, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Group,
  Menu,
  Paper,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { userStore } from '@/stores/user-store.ts'
import type { SymbolName } from '@/utils/types/material-icons'

import styles from './header.module.css'

interface NavItem {
  icon: SymbolName
  label: string
}

const leftNavItems: NavItem[] = [
  {
    icon: 'search',
    label: 'Explore',
  },
  {
    icon: 'map',
    label: 'Map',
  },
  {
    icon: 'collections_bookmark',
    label: 'Collections',
  },
  {
    icon: 'history',
    label: 'History',
  },
]

const rightNavItems: NavItem[] = [
  {
    icon: 'person',
    label: 'My Account',
  },
  {
    icon: 'question_mark',
    label: 'Help Center',
  },
  {
    icon: 'admin_panel_settings',
    label: 'Admin',
  },
  {
    icon: 'logout',
    label: 'Sign Out',
  },
]

// explore / map/ collection / history

export function HeaderMobile() {
  const { pathname } = useLocation()
  const { logIn } = useDantiAuth()
  const navigate = useNavigate()

  const isLogIn = pathname === '/log-in'
  const [avatarBackgroundColor, userInitials] = userStore((state) => [
    state.avatarBackgroundColor,
    state.firstName.charAt(0) + state.lastName.charAt(0),
  ])

  return (
    <Paper
      pos="fixed"
      bg="#000000B2"
      fz={14}
      top={0}
      left={0}
      right={0}
      shadow="xs"
      radius={0}
      style={{ zIndex: 3, height: '70px' }}
    >
      <header className={styles['header']}>
        <Group justify="space-between" py="0.25rem" w="100%">
          <Group h="100%" gap="sm">
            <Menu
              trigger="click"
              transitionProps={{ duration: 100 }}
              offset={18}
              position="bottom-start"
            >
              <Menu.Target>
                <Icon name="subject" style={{ color: 'white' }} size={38} />
              </Menu.Target>
              <Menu.Dropdown
                bg="transparent"
                styles={{
                  dropdown: {
                    border: 'none',
                    backdropFilter: 'blur(1.5px)',
                    maxHeight: '90vh',
                    overflowY: 'auto',
                  },
                }}
              >
                <Paper
                  bg="rgba(0, 0, 0, 0.733)"
                  p="5px"
                  style={{ borderRadius: '10px' }}
                >
                  {leftNavItems.map(({ label, icon }) => {
                    return (
                      <Menu.Item
                        key={label}
                        onClick={() => {
                          if (label === 'Explore') {
                            navigate('/')
                          }
                        }}
                        c="white"
                        classNames={{ item: styles['menuItem'] }}
                      >
                        <Group gap={6}>
                          <Icon name={icon} style={{ color: 'white' }} />
                          <Text fs="18px">{label}</Text>
                        </Group>
                      </Menu.Item>
                    )
                  })}
                </Paper>
              </Menu.Dropdown>
            </Menu>
          </Group>
          {isLogIn ? (
            <Button variant="primary" onClick={logIn}>
              Log In
            </Button>
          ) : (
            <>
              <Group justify="center" gap={30}>
                <Box style={{ position: 'relative', top: 4 }}>
                  <Icon
                    name="notifications"
                    style={{ color: 'white' }}
                    size={25}
                  />
                  <Badge
                    size="xs"
                    circle
                    color="red"
                    style={{ position: 'absolute', top: -4, right: -4 }}
                  >
                    1
                  </Badge>
                </Box>

                <Menu
                  trigger="click"
                  transitionProps={{ duration: 100 }}
                  position="bottom-end"
                >
                  <Menu.Target>
                    <Avatar
                      size="30px"
                      variant="filled"
                      color={avatarBackgroundColor}
                      radius="50%"
                    >
                      {userInitials}
                    </Avatar>
                  </Menu.Target>
                  <Menu.Dropdown
                    bg="transparent"
                    styles={{
                      dropdown: {
                        border: 'none',
                        backdropFilter: 'blur(1.5px)',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                      },
                    }}
                  >
                    <Paper
                      bg="rgba(0, 0, 0, 0.733)"
                      p="5px"
                      style={{ borderRadius: '10px' }}
                    >
                      {rightNavItems.map(({ label, icon }) => {
                        return (
                          <Menu.Item
                            key={label}
                            // onClick={() => void handleSetQuery(query)}
                            c="white"
                            classNames={{ item: styles['menuItem'] }}
                          >
                            <Group gap={6}>
                              <Icon name={icon} style={{ color: 'white' }} />
                              <Text fs="18px">{label}</Text>
                            </Group>
                          </Menu.Item>
                        )
                      })}
                    </Paper>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </>
          )}
        </Group>
      </header>
    </Paper>
  )
}
