import { Box, Group, Stack } from '@mantine/core'
import { AddressDisplayPanel } from '@/components/lib/single-address/AddressDisplayPanel.tsx'
import { BasicInfoPanel } from '@/components/lib/single-address/BasicInfoPanel.tsx'
import { ChatPanel } from '@/components/lib/single-address/ChatPanel.tsx'
import { MapPanel } from '@/components/lib/single-address/MapPanel.tsx'
import { PermitPanel } from '@/components/lib/single-address/PermitPanel.tsx'
import { RiskPanel } from '@/components/lib/single-address/RiskPanel.tsx'
import { StatusDisplay } from '@/components/lib/single-address/StatusDisplay.tsx'
import { useSingleAddressComponents } from '@/hooks/use-single-address-components.ts'
import { queryStore } from '@/stores/queries-store.ts'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import type React from 'react'

dayjs.extend(relativeTime)

type SingleAddressPanelProps = {
  mock: boolean | undefined
}

export const SingleAddressPanel: React.FC<SingleAddressPanelProps> = ({
  mock,
}) => {
  const {
    propertyData,
    mlsImages,
    permitRecords,
    risksScoredF,
    risksScoredD,
    risksScoredC,
    parcelPolygon,
    makeTileLayerOptions,
    orthoCoverage,
  } = useSingleAddressComponents(!!mock)
  const currentQuery = queryStore((s) => s.currentQuery)
  return (
    <Group gap={0} mah="90vh" w="100%" align="start">
      <Stack gap={18} w="75%">
        <Group>
          <AddressDisplayPanel
            propertyData={propertyData}
            currentQuery={currentQuery}
          />
          <Box style={{ flex: 1 }} />
          <Box style={{ marginRight: 18 }}>
            <StatusDisplay
              propertyData={propertyData}
              parcelPolygon={parcelPolygon}
              orthoCoverage={orthoCoverage}
            />
          </Box>
        </Group>

        <Group align="start" style={{ position: 'relative' }}>
          <Stack w="60%">
            <MapPanel
              propertyData={propertyData}
              parcelPolygon={parcelPolygon}
              makeTileLayerOptions={makeTileLayerOptions}
              orthoCoverage={orthoCoverage}
            />
            <BasicInfoPanel
              propertyData={propertyData}
              mlsImages={mlsImages || []}
            />
          </Stack>
          <Stack w="35%">
            <RiskPanel
              propertyData={propertyData}
              risksScoredC={risksScoredC}
              risksScoredD={risksScoredD}
              risksScoredF={risksScoredF}
            />
            <PermitPanel
              propertyData={propertyData}
              permitRecords={permitRecords}
            />
          </Stack>
        </Group>
      </Stack>
      <Box w="25%" h="100vh">
        <ChatPanel />
      </Box>
    </Group>
  )
}
