import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { useAnalyticLayer } from '@/components/lib/map-search/hooks/use-analytic-layer.tsx'
import { useMapboxMap } from '@/components/lib/map-search/hooks/use-mapbox-map.ts'
import { useSingleOrthoLayer } from '@/hooks/ortho-imagery/use-single-ortho-layer.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'
interface SingleImageMapProps {
  isFullScreen?: boolean
  item: ImageryResult
}
export function SingleImageMap({ isFullScreen, item }: SingleImageMapProps) {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const nav = useNavigate()
  useMapboxMap(map, mapContainer)
  useSingleOrthoLayer(map, item)
  useAnalyticLayer(map, item)

  return (
    <div
      style={{
        position: isFullScreen ? 'absolute' : 'relative',
        inset: 0,
      }}
    >
      {isFullScreen && (
        <ActionIcon
          onClick={() => {
            nav(-1)
          }}
          pos="absolute"
          radius="xs"
          top={isFullScreen ? 80 : 5}
          left={5}
          p="0"
          bg="#eee"
          style={{
            zIndex: 1,
            transform: `translateX(-100%), rotate('0deg'
          })`,
            color: '#666',
            transition: 'right 0.25s',
            boxShadow:
              '0px 0px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.22) inset',
          }}
        >
          <Icon name="arrow_back" />
        </ActionIcon>
      )}
      <div
        ref={mapContainer}
        className="map-container"
        style={{
          height: isFullScreen ? '100vh' : '600px',
        }}
      />
    </div>
  )
}
