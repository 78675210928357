/* eslint-disable camelcase */
export const hazardPropertySample7434 = {
  title: 'Resource from Hazard Hub',
  subtitle: '',
  geometry: {
    coordinates: ['0', '0'],
    type: 'Point',
  },
  documentId: '35dfdbab-a0e2-42ca-a044-469e91336ad7',
  score: 0.5,
  description: 'Resource from Hazard Hub',
  category: 'DATA',
  thumbnail: '',
  filetype: '',
  authoredOn: '2023-10-04T16:09:26',
  source: 'HAZARD',
  tags: null,
  license: 'unknown',
  properties: {
    assessment: {
      Air_Conditioning: null,
      Air_Conditioning_Type: null,
      Amenities: null,
      Amenities_2: null,
      Assessed_Improvement_Value: 0,
      Assessed_Land_Value: 0,
      Assessment_Year: '2022',
      Assessors_Parcel_Number: '1S1E19AD 02301',
      Basement: null,
      Building_Area: 2981,
      Building_Area_1: 1289,
      Building_Class: null,
      Building_Condition: null,
      Building_Quality: null,
      Condo_Project_Bldg_Name: null,
      Current_Owner_Name: 'ISAACSON, ANDREW & ISAACSON, AMY',
      Fireplace: '1',
      Garage_Cars: 2,
      Garage_Type: 'Attached Garage',
      Heating: 'Forced air unit',
      LSale_Document_Number: '2020-097221',
      LSale_Price: '810000',
      LSale_Price_Code: 'Full amount stated on Document.',
      LSale_Recording_Date: '20200804',
      LValid_Price: '810000',
      Legal_Brief_Description_FULL:
        'SEC/TWN/RNG/MER:SEC 19 TWN 01S RNG 01E AZALEA PARK, LOT 8',
      Legal_Subdivision_Name: 'AZALEA PARK',
      LotSize_Acres: 0.302,
      LotSize_Depth_Feet: '0',
      LotSize_Frontage_Feet: '0',
      LotSize_Square_Feet: 13147,
      Main_Building_Area_Indicator: 'Living Area',
      N_of_Plumbing_Fixtures: '0',
      Neighborhood_Code: 'R420',
      No_of_Buildings: 0,
      No_of_Stories: null,
      Number_of_Baths: 2,
      Number_of_Bedrooms: 4,
      Number_of_Partial_Baths: 1,
      Number_of_Units: 0,
      Owner1FirstName: 'ANDREW',
      Owner1LastName: 'ISAACSON',
      Owner2Firstname: 'AMY',
      Owner2LastName: 'ISAACSON',
      Owner_Occupied: 'Owner-occupied property (SFR/Condo)',
      PSale_Price: '560000',
      Pool: null,
      Roof_Cover: 'Composition Shingle',
      Roof_Type: null,
      School_Tax_District_1: null,
      School_Tax_District_1_Indicator: null,
      School_Tax_District_2: null,
      School_Tax_District_2_Indicator: null,
      School_Tax_District_3: null,
      School_Tax_District_3_Indicator: null,
      Site_Influence: null,
      Standardized_Land_Use_Code: 'Single Family Residential',
      Tax_Amount: 12107.47,
      Tax_Delinquent_Year: null,
      Tax_Rate_Code_Area: '1',
      Topography: null,
      Total_Assessed_Value: 453850,
      Total_Market_Value: 846430,
      Total_Number_of_Rooms: '0',
      Type_Construction: null,
      Year_Built: '1999',
      tax_marketvalue: 453850,
    },
    images: [
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/images/20396756.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-1.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-2.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-3.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-4.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-5.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-6.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-7.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-8.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-9.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-10.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-11.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-12.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-13.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-14.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-15.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-16.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-17.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-18.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-19.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-20.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-21.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-22.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-23.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-24.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-25.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-26.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-27.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-28.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-29.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-30.jpg',
      'https://xomesearch.propertiescdn.com/ListingImages/ORRMLS-U/addl_picts/20396756-31.jpg',
    ],
    lat: 45.47148,
    listing_record: {
      list_date: '6/25/2020',
      list_price: 785000,
      status: 'Sold',
    },
    lng: -122.72638,
    mls_listing_record_details: {
      Ad_geo_altitude: null,
      ad_zone_features: null,
      ex_construction_features: 'Stem Wall',
      ex_exterior_wall_features: null,
      ex_fence_features: 'Fenced (any type)',
      ex_foundation_features: null,
      ex_garage_features: 'Tandem, Garage Door Opener',
      ex_garage_spaces: null,
      ex_general_features: 'Dog Run, Garden, Landscaped',
      ex_location_features: 'Cul-De-Sac',
      ex_lot_size_acres: 0.33,
      ex_lot_size_features: 'Wooded',
      ex_lot_size_square_feet: null,
      ex_parking_features: 'Driveway',
      ex_parking_spaces: null,
      ex_patio_features: 'Deck, Porch',
      ex_patio_yn: 'YES',
      ex_pool_features: null,
      ex_pool_yn: null,
      ex_road_features: 'PR,PB',
      ex_roof_features: null,
      ex_sewer_features: null,
      ex_spa_yn: null,
      ex_style_features: 'Craftsman, Custom',
      ex_view_features: null,
      ex_wateraccess_features: null,
      ex_waterfront_features: null,
      if_appliance_features:
        'Washer/Dryer, Cooktop Downdraft, Disposal, Gas Appliances',
      if_basement_features: null,
      if_basement_yn: null,
      if_cooling_features: 'Air Conditioning',
      if_cooling_yn: 'YES',
      if_fireplace_features: 'Gas Burning, Heatilator',
      if_fireplace_number: 1,
      if_fireplace_yn: 'YES',
      if_floor_features: 'Hardwood, Tile, Wall to Wall Carpet',
      if_general_features:
        'Walk-In Closets, French Doors, Wainscoting, Sound System, High Ceiling, Pantry',
      if_heating_features: 'Forced Air, Natural Gas',
      if_levels_features: null,
      if_security_features: null,
      if_security_system_yn: null,
      if_utilities_features: null,
      if_water_features: 'Public Water Service',
      if_window_features: null,
      in_association_dues1: null,
      in_association_features: null,
      in_association_yn: null,
      in_living_square_feet: 2860,
      in_property_type: 'Residential',
      in_public_remarks:
        "The one you've been waiting for! This exquisitely-built custom craftsman home is tucked away in a private wooded setting on a quiet cul de sac, yet walking distance to Multnomah Village, Maplewood Cafe and Gabriel Park. Light filled and well laid out with custom finishes and features. Gorgeous vaulted family room w/library and bonus office or 5th bedroom on main. Incredible outdoor spaces with gardens, fenced yard & dog run, stone patio w/ fire pit, deck off the kitchen, and even a treehouse!",
      in_sold_date: null,
      in_sold_price: null,
      in_subtype: null,
      in_year_built: 1999,
      rm_baths_full: 2,
      rm_baths_half: 1,
      rm_baths_total: 2.1,
      rm_bedrooms_total: 4,
      rm_dining_features: 'Formal',
      rm_family_yn: 'YES',
      rm_general_features: null,
      rm_kitchen_features: 'Island, Gourmet Kitchen',
      rm_laundry_features: null,
      rm_room11_features: 'Upper Level',
      rm_room11_type: 'BD',
      rm_room13_features: 'Main Level',
      rm_room13_type: 'DI',
      rm_rooms_total: null,
      sc_school_district: null,
    },
    mortgage_info: {
      Current_Est_LTV_Combined: '71.8943',
      Mtg01_Curr_Est_Bal: '626263',
      Mtg01_Loan_Amount: '651200',
      Mtg01_Title_Company_Name: 'WFG TITLE',
      Mtg01_lender_name_beneficiary: 'PRIMELENDING',
      Mtg01_loan_type: 'New Conventional',
      Total_Open_Lien_Balance: '686263',
    },
    property: {
      address: '7434 SW 49TH CT, PORTLAND, OR 97219',
      apn: '1S1E19AD 02301',
      owner: 'ISAACSON, ANDREW & AMY',
      street_address: '7434 SW 49TH CT',
      use_code: 'Single Family Residence',
      zip: '97219',
    },
    valuation: {
      price_range_max: 1004850,
      price_range_min: 838911,
    },
  },
  asset: '',
}
