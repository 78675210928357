import { Box, MantineProvider, Stack, Text } from '@mantine/core'
import pluralize from 'pluralize'
import type React from 'react'

type MapHexgridInfoTooltipProps = {
  imageCount: number
}

const transparentGray = 'rgba(97, 97, 97, 0.9)'
export const MapHexgridInfoTooltip: React.FC<MapHexgridInfoTooltipProps> = ({
  imageCount,
}) => {
  return (
    <MantineProvider>
      <div style={{ pointerEvents: 'none' }}>
        <Stack style={{ alignItems: 'center' }} gap={0}>
          <Box
            style={{
              backgroundColor: transparentGray,
              borderRadius: '4px',
              padding: '4px 8px',
              color: 'white',
            }}
          >
            <Stack gap={1}>
              <Text>{`${imageCount} ${pluralize('Image', imageCount)}`}</Text>
            </Stack>
          </Box>
          <svg height="25" width="25">
            <polygon
              points="0,0 25,0 12.5,12.5"
              style={{ fill: transparentGray }}
              className="triangle"
            />
          </svg>
        </Stack>
      </div>
    </MantineProvider>
  )
}
