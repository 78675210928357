import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom'
import MainLayout from '@/components/layout/MainLayout.tsx'
import { SingleImage } from '@/components/lib/map-search/SingleImage.tsx'
import {
  FirePanel,
  ImagePanel,
  NewsPanel,
  ShipPanel,
  SocialPanel,
} from '@/components/lib/results/Panel'
import AcceptShare from '@/pages/AcceptShare.tsx'
import AccessDenied from '@/pages/AccessDenied.tsx'
import Admin from '@/pages/Admin.tsx'
import CollectionDetail from '@/pages/CollectionDetail.tsx'
import Collections from '@/pages/Collections.tsx'
import Error from '@/pages/Error.tsx'
import Explore from '@/pages/Explore.tsx'
import ItemDetail from '@/pages/ItemDetail.tsx'
import MapSearch from '@/pages/MapSearch.tsx'
import NotFound from '@/pages/NotFound.tsx'
import Redirect from '@/pages/Redirect.tsx'
import ResultsOverview from '@/pages/ResultsOverview.tsx'
import { SingleAddress } from '@/pages/SingleAddress.tsx'
import { REDIRECT_PATH } from '@/utils/constants.ts'
import { Authenticated } from '@/utils/routing/Authenticated.tsx'
import { FlaggedRoute } from '@/utils/routing/FlaggedRoute.tsx'
import { NewExplore } from '@/pages/NewExplore'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<MainLayout />}>
        <Route path="/log-in" element={<Explore />} />
        <Route path={REDIRECT_PATH} element={<Redirect />} />

        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/error" element={<Error />} />
        <Route
          element={
            <Authenticated>
              <Outlet />
            </Authenticated>
          }
        >
          <Route path="/" element={<Explore />} />
          <Route path="/new" element={<NewExplore />} />
          <Route path="/results/:itemId" element={<ItemDetail />} />
          <Route path="/overview" element={<ResultsOverview />} />
          <Route path="/news" element={<NewsPanel isFullscreen />} />
          <Route path="/images" element={<ImagePanel isFullscreen />} />
          <Route path="/fires" element={<FirePanel isFullscreen />} />
          <Route path="/ships" element={<ShipPanel isFullscreen />} />
          <Route path="/social" element={<SocialPanel isFullscreen />} />
          <Route
            path="/accept-share/:collectionId/:shareToken"
            element={<AcceptShare />}
          />
          <Route path="/collections" element={<Collections />} />
          <Route
            path="/collections/:collectionId"
            element={<CollectionDetail />}
          />
          <Route
            path="/collections/:collectionId/item/:itemId"
            element={<ItemDetail />}
          />
          <Route
            path="/map"
            element={
              <FlaggedRoute
                feature="MAP_SEARCH"
                element={<MapSearch />}
                isProtected={true}
              />
            }
          />
          <Route
            path="/map/:itemId"
            element={
              <FlaggedRoute
                feature="RESULTS_OVERVIEW"
                element={<SingleImage />}
                isProtected={true}
              />
            }
          />
          <Route path="/address/:mock?" element={<SingleAddress />} />
          <Route path="/terrapin/:terrapinItemId" element={<ItemDetail />} />
          <Route path="/admin" element={<Admin />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </>,
  ),
)
