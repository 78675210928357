import { type MutableRefObject, useCallback, useEffect } from 'react'
import {
  makeImageryResultPolygons,
  makeImageryResultsBoundingBox,
} from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { safeSetMapSourceData } from '@/components/lib/map-search/hooks/util/safe-map-setters.ts'
import { mapStore } from '@/stores/map-store.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'

export const useInitialBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const [categorized] = resultsStore((s) => [s.categorized])
  const [currentQueryFilters] = queryStore((s) => [s.currentQueryFilters])
  const [mapBbox] = mapStore((s) => [s.mapBbox])

  useEffect(() => {
    if (map.current) {
      const polygons = makeImageryResultPolygons(categorized.imageResults)
      void safeSetMapSourceData(map, 'extents', polygons)
    }
  }, [categorized.imageResults, map])

  const flyToResults = useCallback(
    (results: ImageryResult[]) => {
      const bbox = makeImageryResultsBoundingBox(
        results,
      ) as mapboxgl.LngLatBoundsLike

      map.current?.fitBounds(bbox, {}, { recomputeHexGrid: true })
    },
    [map],
  )

  useEffect(() => {
    if (categorized.imageResults.length > 0) {
      flyToResults(categorized.imageResults)
    }
    if (categorized.dataResults.length > 0) {
      const coordinates = categorized.dataResults[0].geometry
        .coordinates as number[]
      map.current?.flyTo({ center: [coordinates[0], coordinates[1]] })
    }
  }, [
    categorized.dataResults,
    categorized.imageResults,
    currentQueryFilters,
    flyToResults,
    map,
    mapBbox,
  ])
}
