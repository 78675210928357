import { type MantineStyleProp, Select } from '@mantine/core'
import { queryStore } from '@/stores/queries-store'

interface SortSelectProps {
  style?: MantineStyleProp
}

export function SortSelect({ style }: SortSelectProps) {
  const [sortPreference, setSortPreference] = queryStore((state) => [
    state.sortPreference,
    state.setSortPreference,
  ])

  return (
    <Select
      size="xs"
      value={sortPreference}
      onChange={(value) =>
        value
          ? setSortPreference(value as 'authoredOn' | 'score')
          : setSortPreference('score')
      }
      data={[
        { label: 'Date', value: 'authoredOn' },
        { label: 'Relevance', value: 'score' },
      ]}
      maw={115}
      data-cy="sort-results"
      style={style}
    />
  )
}
