import React from 'react'
import { useParams } from 'react-router-dom'
import { SingleImageMap } from '@/components/lib/map-search/SingleImageMap.tsx'
import { Skeleton } from '@/components/lib/Skeleton.tsx'
import { useAllResults } from '@/hooks/results/use-all-results.ts'
import { useCollections } from '@/utils/data-fetching/collections.ts'
import { addIdToResult, getRequest } from '@/utils/data-fetching/config.ts'
import {
  isImageryResult,
  type RawSearchResult,
  type ResultWithId
} from '@/utils/types/result-types.ts'

export const SingleImage = () => {
  const { data: collections } = useCollections()
  const results = useAllResults()
  const [item, setItem] = React.useState<ResultWithId>()
  const { collectionId, itemId, terrapinItemId } = useParams()

  async function fetchItem(id: string) {
    const { data } = await getRequest<RawSearchResult>(`/results/${id}`)
    setItem(addIdToResult(data))
  }

  React.useEffect(() => {
    let item: ResultWithId | undefined
    if (collectionId) {
      const collection = collections.find(({ id }) => id === collectionId)
      if (collection) {
        const collectionItem = collection.items.find(({ id }) => id === itemId)
        if (collectionItem) {
          item = collectionItem.doc
        }
      }
    } else if (itemId) {
      const result = results.find(({ id }) => id === itemId)
      if (result) {
        item = result
      }
    } else if (terrapinItemId) {
      void fetchItem(terrapinItemId)
    }
    if (item) {
      setItem(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, itemId])

  return item && isImageryResult(item) ? <SingleImageMap isFullScreen={true} item={item} /> : <Skeleton />
}
