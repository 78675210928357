import { mapboxToken } from '@/utils/constants.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import polyline from '@mapbox/polyline'

interface Polyline {
  encode: (coordinates: Array<[number, number]>) => string
}

interface StaticMapOptions {
  geometry: ResultWithId['geometry']
  baseMap?: 'streets-v11' | 'satellite' | 'hybrid'
  size?: [number, number]
  padding?: number
}

export function createStaticMapUrl({
  geometry,
  baseMap = 'streets-v11',
  size = [480, 200],
  padding = 40,
}: StaticMapOptions) {
  const [width, height] = size.map(Math.floor)
  if (geometry.type === 'Point') {
    const urlEncodedPoint = encodeURIComponent(
      JSON.stringify({ type: 'Point', coordinates: geometry.coordinates }),
    )
    return (
      `https://api.mapbox.com/styles/v1/mapbox/${baseMap}` +
      `/static/geojson(${urlEncodedPoint})/` +
      `${geometry.coordinates[0]},${geometry.coordinates[1]},12` +
      `/${width}x${height}@2x?access_token=${mapboxToken}`
    )
  } else {
    const path = (polyline as Polyline).encode(
      geometry.coordinates[0].map(([lon, lat]) => [lat, lon]),
    )
    const urlEncodedPath = encodeURIComponent(path)
    return (
      `https://api.mapbox.com/styles/v1/mapbox/${baseMap}/static/` +
      `path-2+fa531c(${urlEncodedPath})/auto/${width}x${height}@2x?padding=${padding}` +
      `&access_token=${mapboxToken}`
    )
  }
}
