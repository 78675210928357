import { useEffect } from 'react'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { appEnv } from '@/utils/constants.ts'
import * as Sentry from '@sentry/react'
// import {
//   browserTracingIntegration,
//   feedbackIntegration,
//   replayIntegration,
// } from '@sentry/react'

export const configureSentry = () => {
  if (window.location.hostname === 'localhost' || appEnv === 'local') {
    console.log("Not configuring Sentry because we're on localhost")
    return
  }

  // Sentry.addTracingExtensions()
  // Sentry.init({
  //   dsn: 'https://555b81ad4cf64420916662f38d4f8c4e@o4505330790825984.ingest.sentry.io/4505330797445120',
  //   integrations: [
  //     replayIntegration({
  //       maskAllText: false,
  //       sessionSampleRate: isProduction || isGov ? 0.1 : 1,
  //       errorSampleRate: 1,
  //     }),
  //     feedbackIntegration({
  //       buttonLabel: 'Feedback?',
  //       submitButtonLabel: 'Submit feedback',
  //       formTitle: 'Send Danti feedback',
  //       messagePlaceholder:
  //         "Describe what you are experiencing, and tell us what you'd prefer.",
  //       isEmailRequired: false,
  //       isNameRequired: false,
  //       showBranding: false,
  //     }),
  //     browserTracingIntegration({}),
  //   ],
  //   environment: appEnv,
  //   // Performance Monitoring
  //   tracesSampleRate: isProduction || isGov ? 0.2 : 1, // Capture 100% of the transactions, reduce in production!
  // })
}

export const useSentryMetadataSyncing = () => {
  const { user } = useDantiAuth()
  const query = queryStore((s) => s.currentQuery)
  const queryId = resultsStore((s) => s.currentQueryId)

  useEffect(() => {
    Sentry.setUser({ email: user?.profile.email })
  }, [user?.profile.email])

  useEffect(() => {
    Sentry.setContext('searchQuery', { query, queryId })
  }, [query, queryId])
}
