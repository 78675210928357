import { Grid } from '@mantine/core'
import { ContentBox } from './ContentBox'

export interface ExploreContentOneProps {
  isLoading: boolean
}

export function ExploreContentOne({ isLoading }: ExploreContentOneProps) {
  return (
    <Grid gutter={12} h={{ base: 'auto', md: '290px' }}>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <ContentBox
          transition="fade"
          imageSrc="/demo/grid/v1/0.png"
          h={{ base: '400px', md: '290px' }}
          isLoading={isLoading}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <ContentBox
          transition="pop"
          imageSrc="/demo/grid/v1/1.png"
          h={{ base: '400px', md: '290px' }}
          isLoading={isLoading}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Grid gutter={8}>
          <Grid.Col span={6}>
            <Grid gutter={8}>
              {[
                '/demo/grid/v1/2.png',
                '/demo/grid/v1/3.png',
                '/demo/grid/v1/4.png',
                '/demo/grid/v1/5.png',
              ].map((x) => (
                <Grid.Col span={6} key={x}>
                  <ContentBox
                    transition="slide-down"
                    imageSrc={x}
                    h={{ base: '100px', md: '65px' }}
                    isLoading={isLoading}
                  />
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
          <Grid.Col span={6}>
            <ContentBox
              transition="slide-left"
              imageSrc="/demo/grid/v1/6.png"
              h={{ base: '220px', md: '140px' }}
              isLoading={isLoading}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ContentBox
              transition="slide-right"
              imageSrc="/demo/grid/v1/7.png"
              h={{ base: '220px', md: '140px' }}
              isLoading={isLoading}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ContentBox
              transition="slide-up"
              imageSrc="/demo/grid/v1/8.png"
              h={{ base: '220px', md: '140px' }}
              isLoading={isLoading}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}
