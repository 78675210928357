import type { ResultWithId } from '@/utils/types/result-types.ts'
import type React from 'react'
import { CompactPanelHeader } from './CompactPanelHeader'
import { FullScreenPanelHeader } from './FullScreenPanelHeader'

interface PanelBaseProps {
  children: React.ReactNode
  isolateType: (result: ResultWithId) => boolean
  titleShort: string
  titleLong: string
  link: string
  menu?: React.ReactNode
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
}

export function PanelBase({
  children,
  isFullscreen = false,
  ...props
}: PanelBaseProps) {
  return (
    <>
      {isFullscreen ? (
        <FullScreenPanelHeader {...props} />
      ) : (
        <CompactPanelHeader {...props} />
      )}
      {children}
    </>
  )
}
