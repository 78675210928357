import { modals } from '@mantine/modals'
import type { Collection } from '@/utils/types/collection-types.ts'
import type { ImageryResult, ResultWithId } from '@/utils/types/result-types.ts'

export function openCreateCollectionModal() {
  modals.openContextModal({
    modal: 'createCollection',
    title: 'Create a new Collection',
    centered: true,
    innerProps: {},
  })
}

export function openAddToCollectionModal(item: ResultWithId) {
  modals.openContextModal({
    modal: 'addToCollection',
    title: 'Select a Collection',
    centered: true,
    innerProps: { item },
  })
}

export function openRemoveFromCollectionModal(
  collectionId: string,
  itemId: string,
) {
  modals.openContextModal({
    modal: 'removeFromCollection',
    title: 'Remove from Collection',
    centered: true,
    innerProps: { collectionId, itemId },
  })
}

export function openDeleteCollectionModal(collectionId: string) {
  modals.openContextModal({
    modal: 'deleteCollection',
    title: 'Delete Collection',
    centered: true,
    innerProps: { collectionId },
  })
}

export function openShareCollectionModal(collectionId: string) {
  modals.openContextModal({
    modal: 'shareCollection',
    title: 'Share Collection',
    centered: true,
    innerProps: { collectionId },
  })
}

export function openAnalyzeCollectionModal(collectionId: string) {
  modals.openContextModal({
    modal: 'analyzeCollection',
    title: 'What would you like to identify?',
    centered: true,
    innerProps: { collectionId },
  })
}

export function openAnalyzeItemModal(item: ImageryResult) {
  modals.openContextModal({
    modal: 'analyzeItem',
    title: 'What would you like to identify?',
    centered: true,
    innerProps: { item },
  })
}

export function openDownloadItemModal(item: ImageryResult) {
  modals.openContextModal({
    modal: 'downloadItem',
    title: 'Request Download Transfer',
    centered: true,
    innerProps: { item },
  })
}

export function openDownloadCollectionModal(collection: Collection) {
  modals.openContextModal({
    modal: 'downloadCollection',
    title: 'Send To Vendor',
    centered: true,
    innerProps: { collection },
  })
}

export function openResultDetailsModal(result: ResultWithId) {
  modals.openContextModal({
    modal: 'resultDetails',
    title: 'Article Details',
    size: 'xl',
    centered: true,
    innerProps: { result },
  })
}

export function openSessionConfirmationModal() {
  modals.openContextModal({
    modal: 'sessionConfirmation',
    title: 'Your session has expired',
    centered: true,
    innerProps: undefined,
    withCloseButton: false,
  })
}
