import type { HexGridCellProperties } from '@/stores/map-store.ts'
import * as turf from '@turf/turf'
import type { FeatureCollection, Polygon } from 'geojson'
import type mapboxgl from 'mapbox-gl'

export const HEX_GRID_INSET_PCT = 0.95
export const HEX_GRID_CELLS = 50
export const HEX_GRID_SCALE_FACTOR = 0.95

export type HexGrid = FeatureCollection<Polygon, HexGridCellProperties>

type MakeBareHexGridProps = {
  currentMapBounds: mapboxgl.LngLatBounds | null
}
export const makeBareHexGrid: (props: MakeBareHexGridProps) => HexGrid = ({
  currentMapBounds,
}) => {
  if (!currentMapBounds) {
    return turf.featureCollection([])
  }

  const vertInset =
    HEX_GRID_INSET_PCT *
    (currentMapBounds.getNorth() - currentMapBounds.getSouth())

  const horizInset =
    HEX_GRID_INSET_PCT *
    (currentMapBounds.getEast() - currentMapBounds.getWest())

  const bbox: turf.BBox = [
    currentMapBounds.getWest() + horizInset,
    currentMapBounds.getSouth() + vertInset,
    currentMapBounds.getEast() - horizInset,
    currentMapBounds.getNorth() - vertInset,
  ]

  const cellSizeMeters =
    turf.distance(
      turf.point([bbox[0], bbox[1]]),
      turf.point([bbox[2], bbox[3]]),
      { units: 'meters' },
    ) / HEX_GRID_CELLS

  const grid = turf.hexGrid(bbox, cellSizeMeters, {
    units: 'meters',
    properties: {
      id: 0,
      resultIds: {
        IMAGE: [],
        DATA: [],
        WEB_ARTICLE: [],
        SOCIAL_MEDIA: [],
        ANALYTIC: [],
      },
      resultCount: 0,
    } as HexGridCellProperties,
  })

  turf.featureEach(grid, (f, index) => (f.id = f.properties.id = index))

  turf.transformScale(grid, HEX_GRID_SCALE_FACTOR, { mutate: true })

  return grid
}
