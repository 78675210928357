import { type MutableRefObject,useEffect } from 'react'
import { makeOrthoLayer } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'

export const useSingleOrthoLayer = (map: MutableRefObject<mapboxgl.Map | null>, item: ImageryResult | undefined) => {
  const SINGLE_IMAGE = 'single_image'
  const orthoLayer = item ? makeOrthoLayer(item) : undefined

  useEffect(() => {
    map.current?.on('styledata', () => {
      if (!map.current?.getSource(SINGLE_IMAGE) && orthoLayer) {
        const bounds = turf.bbox(orthoLayer?.geometry) as [number, number, number, number]
        map.current?.addSource(SINGLE_IMAGE, {
          type: 'raster',
          tiles: [orthoLayer?.tileUrl], bounds,
        })

        map.current?.addLayer({
          type: 'raster',
          source: SINGLE_IMAGE,
          id: `${SINGLE_IMAGE}_layer`,
        })
        map.current?.fitBounds(bounds)
      }
    })
  }, [map, orthoLayer])
}