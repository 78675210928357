import { getRequest } from '@/utils/data-fetching/config.ts'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { PropsWithChildren } from 'react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url] }) => {
        if (typeof url === 'string') {
          const { data } = await getRequest(url)
          return data
        }
        throw new Error('Invalid query key')
      },
      staleTime: 300_000, // 5 minutes (1000 * 60 * 5)
      gcTime: 3_600_000, // 1 hour (1000 * 60 * 60)
      refetchOnWindowFocus: false,
    },
  },
})

export function QueryProvider({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
