import type { HexGridCellProperties } from '@/stores/map-store.ts'
import type { MutableRefObject } from 'react'

let listenersAdded = false

type AddInteractionListenersProps = {
  map: MutableRefObject<mapboxgl.Map | null>
  toggleSelectedHexGridId: (id: number | null) => void
  setHoveredHexGridId: (id: number | null) => void
  setSelectedExtentIds: (ids: string[]) => void
  setHoveredExtentIds: (ids: string[]) => void
  setSelectedPointId: (id: string | null) => void
}
export const addInteractionListeners: (
  props: AddInteractionListenersProps,
) => void = ({
  map,
  toggleSelectedHexGridId,
  setHoveredHexGridId,
  setSelectedPointId,
}) => {
  if (listenersAdded) {
    return
  }
  const clickCallback = (event: mapboxgl.MapLayerMouseEvent) => {
    if (event.features && event.features[0]) {
      const hexId = (event.features[0].properties as HexGridCellProperties).id

      if (hexId) {
        toggleSelectedHexGridId(hexId)
      }
    }
  }

  map.current?.on(
    'mouseover',
    'hexgrid-fills',
    (event: mapboxgl.MapLayerMouseEvent) => {
      event.target.getCanvas().style.cursor = 'pointer'
      if (event.features && event.features[0]) {
        const hexId = (event.features[0].properties as HexGridCellProperties).id
        setHoveredHexGridId(hexId)
      }
    },
  )

  map.current?.on(
    'mouseleave',
    'hexgrid-fills',
    (event: mapboxgl.MapLayerMouseEvent) => {
      event.target.getCanvas().style.cursor = ''
      setHoveredHexGridId(null)
    },
  )

  map.current?.on('click', 'hexgrid-fills', (event) => clickCallback(event))
  listenersAdded = true

  map.current?.on(
    'click',
    [
      'news-point-layer',
      'fire-point-layer',
      'ship-point-layer',
      'social-point-layer',
    ],
    (event: mapboxgl.MapLayerMouseEvent) => {
      event.target.getCanvas().style.cursor = 'pointer'
      if (event.features) {
        const id = event.features[0].properties?.['resultId'] as string | null
        setSelectedPointId(id)
      }
    },
  )

  // map.current?.on(
  //   'mouseover',
  //   'extents-fills',
  //   (event: mapboxgl.MapLayerMouseEvent) => {
  //     event.target.getCanvas().style.cursor = 'pointer'
  //     if (event.features) {
  //       const ids = event.features.map((f) =>  f.properties?.['documentId'] as string | undefined).filter((f):f is string => !!f)
  //       setHoveredExtentIds(ids)
  //     }
  //   }
  // )

  // map.current?.on(
  //   'mouseleave',
  //   'extents-fills',
  //   (event: mapboxgl.MapLayerMouseEvent) => {
  //     event.target.getCanvas().style.cursor = ''
  //     setHoveredExtentIds([])
  //   }
  // )
}
