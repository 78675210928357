import React, { type MutableRefObject, useCallback, useEffect } from 'react'
import { addLayers } from '@/components/lib/map-search/hooks/util/add-layers.ts'
import { addSources } from '@/components/lib/map-search/hooks/util/add-sources.ts'
import {
  makeImageryResultPolygons,
  makeResultPinPoints,
} from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  type FireResult as FireResultType,
  isFireResult,
  isShipResult,
  type ShipResult as ShipResultType,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'
import type mapboxgl from 'mapbox-gl'

type UseStyleLoadedCallbackProps = {
  map: MutableRefObject<mapboxgl.Map | null>
}

export const useEmbeddedStyleLoadedCallback: (
  props: UseStyleLoadedCallbackProps,
) => void = ({ map }) => {
  const {
    categorized: { publicationResults },
  } = useTimelineFilteredResults()
  const fireResults: FireResultType[] = resultsStore((state) =>
    state.categorized.dataResults.filter(isFireResult),
  )
  const shipResults: ShipResultType[] = resultsStore((state) =>
    state.categorized.dataResults.filter(isShipResult),
  )

  const socialResults: SocialMediaResult[] = resultsStore(
    (state) => state.categorized.socialMediaResults,
  )

  const sortPreference = queryStore((state) => state.sortPreference)

  const imageResults = usePostfilterImageResults()

  const sortedResults = React.useMemo(() => {
    return orderBy(imageResults, sortPreference || undefined, 'desc')
  }, [imageResults, sortPreference])
  const extentsData = makeImageryResultPolygons(sortedResults.slice(0, 6))
  const newsData = makeResultPinPoints(publicationResults)
  const fireData = makeResultPinPoints(fireResults)
  const shipData = makeResultPinPoints(shipResults)
  const socialData = makeResultPinPoints(socialResults)

  const styleDataCallback = useCallback(() => {
    addSources({ map, extentsData, newsData, fireData, shipData, socialData })
    addLayers(map, 'extents')
  }, [extentsData, fireData, map, newsData, shipData, socialData])

  useEffect(() => {
    if (map.current?.isStyleLoaded()) {
      addSources({ map, extentsData, newsData, fireData, shipData })
    }
  }, [extentsData, fireData, map, newsData, shipData])

  useEffect(() => {
    map.current?.on('styledata', styleDataCallback)
  }, [map, styleDataCallback])
}
