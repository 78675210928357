import React from 'react'
import { useClearAll } from '@/hooks/use-clear-all.ts'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { useWebsocket } from '@/hooks/use-websocket.ts'
import { addChatMessage } from '@/stores/chat-store.ts'
import { type Filters, getFilters, setFilters } from '@/stores/filters-store.ts'
import { mapStore } from '@/stores/map-store.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { postRequest } from '@/utils/data-fetching/config.ts'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters.ts'
import { makeSemanticQueryFilters } from '@/utils/search-filters/make-semantic-filters.ts'
import {
  CategoryTypes,
  type RawSearchResult,
} from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'
import type { Position } from 'geojson'

const MAX_RESULTS_TO_PROCESS = 5000

export function useSearch() {
  const {
    lastResults,
    flushLastResults,
    lastStatusMessages,
    flushLastStatusMessages,
    lastChatMessages,
    flushLastChatMessages,
    closeSocket,
  } = useWebsocket()
  const { isAuthenticated } = useDantiAuth()

  const [setCurrentQueryId, addResults, addRelatedImageryResults] =
    resultsStore((state) => [
      state.setCurrentQueryId,
      state.addResults,
      state.addRelatedImageryResults,
    ])
  const [
    setQuery,
    setQueryFilters,
    setStatus,
    totalProcessedResults,
    setTotalProcessedResults,
  ] = queryStore((state) => [
    state.setQuery,
    state.setQueryFilters,
    state.setStatus,
    state.totalProcessedResults,
    state.setTotalProcessedResults,
  ])
  const [setMapBbox] = mapStore((s) => [s.setMapBbox])

  const clearAll = useClearAll()

  React.useEffect(() => {
    if (totalProcessedResults > MAX_RESULTS_TO_PROCESS) {
      console.warn('Reached maximum result processing limit.')
      closeSocket()
      setCurrentQueryId(null)
    }
    if (lastResults.length > 0) {
      const separatedResults = lastResults.reduce(
        (accumulator, result) => {
          if (result.category === CategoryTypes.RELATED_IMAGERY) {
            accumulator.related.push(result)
          } else {
            accumulator.results.push(result)
          }
          return accumulator
        },
        { related: [] as RawSearchResult[], results: [] as RawSearchResult[] },
      )
      addResults(separatedResults.results)
      addRelatedImageryResults(separatedResults.related)

      flushLastResults()
      setTotalProcessedResults(totalProcessedResults + lastResults.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastResults])

  React.useEffect(() => {
    if (lastChatMessages.length > 0) {
      lastChatMessages.forEach(addChatMessage)
      flushLastChatMessages()
    }
  }, [flushLastChatMessages, lastChatMessages])

  React.useEffect(() => {
    if (lastStatusMessages.length > 0) {
      lastStatusMessages.forEach((lastStatusMessage) =>
        setStatus(lastStatusMessage.source, lastStatusMessage.state),
      )
      flushLastStatusMessages()
    }
  }, [flushLastStatusMessages, lastStatusMessages, setStatus])

  const [currentQuery, setQueryStarted] = queryStore((s) => [
    s.currentQuery,
    s.setQueryStarted,
  ])

  const doSearch = React.useCallback(
    async (params: { query: string; filters: Partial<Filters> }) => {
      if (!isAuthenticated) {
        throw new Error('Attempted to search before login')
      }

      clearAll(params.query === 'polygon')
      setTotalProcessedResults(0)

      setFilters(params.filters)
      const apiFilters = makeApiFilters(params.filters)

      setQuery(params.query)
      setQueryFilters(apiFilters)
      setQueryStarted()

      console.log(
        `Running search for ${params.query} with filters ${JSON.stringify(
          apiFilters,
        )}`,
      )

      const response = await postRequest<{ queryId: string }>(
        '/neural/search/proxy',
        {
          query: params.query,
          filters: apiFilters,
        },
      )

      setCurrentQueryId(response.data.queryId)
      if (
        params.filters.locationType === 'polygon' &&
        params.filters.location
      ) {
        setMapBbox(
          turf.bbox(
            turf.polygon([JSON.parse(params.filters.location) as Position[]]),
          ),
        )
      }

      return response.data.queryId
    },
    [
      isAuthenticated,
      clearAll,
      setTotalProcessedResults,
      setQuery,
      setQueryFilters,
      setQueryStarted,
      setCurrentQueryId,
      setMapBbox,
    ],
  )

  const doTextSearch = React.useCallback(
    async (searchString: string) => {
      const semanticFilters = makeSemanticQueryFilters(searchString)

      console.log(`Have incoming filter values`, {
        semanticFilters,
      })

      return doSearch({ query: searchString, filters: semanticFilters })
    },
    [doSearch],
  )

  const doLocationSearch = React.useCallback(
    async (location: string, locationType: string) => {
      const locationFilter: Partial<Filters> = { location, locationType }
      return doSearch({ query: '', filters: locationFilter })
    },
    [doSearch],
  )

  const doSplitSearch = React.useCallback(
    async (location: string, formatted: string, query: string) => {
      const { locationType, keywords } = makeSemanticQueryFilters(
        `${location}${query ? ` ${query}` : ''}`,
      )
      return doSearch({
        query: `${formatted}${query ? ` - ${query}` : ''}`,
        filters: {
          location,
          locationType,
          keywords,
        },
      })
    },
    [doSearch],
  )

  const doLastSearchWithFilters = React.useCallback(async () => {
    const filters = getFilters()
    return doSearch({ query: currentQuery, filters })
  }, [currentQuery, doSearch])

  return {
    doTextSearch,
    doLocationSearch,
    doSplitSearch,
    doLastSearchWithFilters,
  }
}
