import React from 'react'
import { useMantineTheme } from '@mantine/core'
import type { SymbolName } from '@/utils/types/material-icons.ts'
import { classes } from '@/utils/ui-helpers.tsx'

interface SymbolProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: SymbolName
  size?: number | string
  filled?: boolean
  weight?: number
  grade?: number
  opticalSize?: number
}

export const Icon = React.forwardRef<HTMLSpanElement, SymbolProps>(
  (
    {
      name,
      size = '1rem',
      filled = false,
      weight = 300,
      grade = 0,
      opticalSize = 24,
      style,
      className,
      ...rest
    }: SymbolProps,
    ref,
  ) => {
    const theme = useMantineTheme()
    let realSize = size

    if (typeof size === 'string') {
      if (Object.keys(theme.fontSizes).includes(size)) {
        realSize = theme.fontSizes[size]
      }
      realSize = `calc(4/3 * ${realSize})`
    }

    return (
      <span
        ref={ref}
        style={{
          fontVariationSettings: `"FILL" ${
            filled ? 1 : 0
          }, "wght" ${weight}, "GRAD" ${grade}, "opsz" ${opticalSize}`,
          fontSize: realSize ?? 'inherit',
          ...style,
        }}
        className={classes('material-symbols-outlined', className)}
        {...rest}
      >
        {name}
      </span>
    )
  },
)

Icon.displayName = 'Icon'
