import React from 'react'
import {
  Badge,
  Group,
  Select,
  SimpleGrid,
  Tabs,
  type TabsPanelProps,
  type TabsTabProps,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { FilterToggleButton } from '@/components/filters/FilterToggleButton.tsx'
import { FilterPanel } from '@/components/lib/FilterPanel.tsx'
import { ImagesPanel } from '@/components/lib/results/ImagesPanel.tsx'
import { InsightsPanel } from '@/components/lib/results/InsightsPanel.tsx'
import { OrthoThumbnail } from '@/components/lib/results/OrthoThumbnail.tsx'
import {
  FireResult,
  NewsResult,
  ShipResult,
  SocialResult,
} from '@/components/lib/results/Result'
import { BAR_FILL_COLOR } from '@/components/lib/results/results-timeline/bar-fill-color.tsx'
import { ResultsTimelineContainer } from '@/components/lib/results/results-timeline/ResultsTimelineContainer.tsx'
import { Thumbnail } from '@/components/lib/Thumbnail.tsx'
import { useOrthoImagerySources } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { queryStore } from '@/stores/queries-store.ts'
import {
  type CategorizedResults,
  resultsStore,
} from '@/stores/results-store.ts'
import type { GroupedResults } from '@/utils/data-fetching/config.ts'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types.ts'
import {
  type FireResult as FireResultType,
  type ImageryResult,
  isFireResult,
  isShipResult,
  type ShipResult as ShipResultType,
} from '@/utils/types/result-types'
import { useFeature } from 'featurehub-react-sdk'
import { orderBy } from 'lodash'

const COLOR_SIZE = 8
const TABS_MAP = {
  images: 'IMAGE',
  ortho: 'ORTHO',
  news: 'PUBLICATION',
  social: 'SOCIAL_MEDIA',
  data: 'DATA',
  fire: 'FIRE',
  ship: 'SHIP',
}

function Tab({
  total,
  value,
  isEmbedded,
  ...props
}: TabsTabProps & {
  total: number
  value: keyof typeof TABS_MAP
  isEmbedded: boolean
}) {
  const tabConst = TABS_MAP[value] as keyof typeof BAR_FILL_COLOR
  const tabColor = BAR_FILL_COLOR[tabConst].normal

  return (
    <Tabs.Tab
      {...props}
      value={value}
      tt="uppercase"
      style={{
        maxHeight: '2.875rem',
        fontSize: isEmbedded ? '0.625rem' : 'var(--mantine-font-size-xs)',
        padding: isEmbedded
          ? 'var(--mantine-spacing-xs)'
          : 'var(--mantine-spacing-sm) var(--mantine-spacing-md)',
        '&:disabled': {
          cursor: 'initial',
        },
      }}
      leftSection={
        <span
          style={{
            display: 'block',
            width: COLOR_SIZE,
            height: COLOR_SIZE,
            backgroundColor: tabColor,
          }}
        />
      }
      rightSection={
        total > 0 ? (
          <Badge
            variant="filled"
            fw="bold"
            size="xs"
            miw={16}
            mih={14}
            p={2}
            data-cy="result-tab-count"
          >
            {total}
          </Badge>
        ) : undefined
      }
      disabled={total === 0}
      data-cy="result-tab"
    >
      {value}
    </Tabs.Tab>
  )
}

function TabPanel({ children, ...props }: TabsPanelProps) {
  return (
    <Tabs.Panel {...props} mt="sm" data-cy="result-panel">
      {children}
    </Tabs.Panel>
  )
}

interface ResultsPanelProps {
  isEmbedded?: boolean
  onlyOrtho: boolean
}

export function ResultsPanel({
  isEmbedded = false,
  onlyOrtho,
}: ResultsPanelProps) {
  const [totalProcessedResults, sortPreference, setSortPreference] = queryStore(
    (state) => [
      state.totalProcessedResults,
      state.sortPreference,
      state.setSortPreference,
    ],
  )
  const currentQueryId = resultsStore((state) => state.currentQueryId)

  const { categorized: timelineCategorized, ids } = useTimelineFilteredResults()
  const imageResults = usePostfilterImageResults()
  const categorized: CategorizedResults = {
    ...timelineCategorized,
    imageResults: imageResults,
  }
  const { orthoLayerGroups } = useOrthoImagerySources()
  const fireResults: FireResultType[] =
    categorized.dataResults.filter(isFireResult)
  const shipResults: ShipResultType[] =
    categorized.dataResults.filter(isShipResult)

  const [activeTab, setActiveTab] = React.useState('images')

  const [isFilterOpen, { toggle }] = useDisclosure(false)

  const showTotalProcessedResults = useFeature('SHOW_TOTAL_PROCESSED_RESULTS')

  const categories = Object.entries(categorized).reduce(
    (final, [category, items]) => {
      return {
        ...final,
        [category]: orderBy(items, sortPreference || undefined, 'desc'),
      }
    },
    {} as GroupedResults,
  )

  const hasResults = ids.length > 0

  React.useEffect(() => {
    // Reset the UI states when a new query is started
    setActiveTab('images')
  }, [currentQueryId])

  React.useEffect(() => {
    if (hasResults) {
      if (categorized.imageResults.length > 0) {
        setActiveTab('images')
      } else if (categorized.publicationResults.length > 0) {
        setActiveTab('news')
      } else if (categorized.socialMediaResults.length > 0) {
        setActiveTab('social')
      } else if (fireResults.length > 0) {
        setActiveTab('fire')
      } else if (shipResults.length > 0) {
        setActiveTab('ship')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResults])

  return (
    <>
      {categories.insightResults.map((result, index) => (
        <InsightsPanel key={index} result={result} isEmbedded={true} />
      ))}
      <Tabs
        value={activeTab}
        onChange={(value) =>
          value ? setActiveTab(value) : setActiveTab('images')
        }
        mt={isEmbedded ? 'xs' : 'sm'}
        keepMounted={false}
      >
        <Tabs.List>
          <Tab
            value="images"
            total={
              onlyOrtho
                ? orthoLayerGroups.length
                : categories.imageResults.length
            }
            isEmbedded={isEmbedded}
          />
          <Tab
            value="news"
            total={categories.publicationResults.length}
            isEmbedded={isEmbedded}
          />
          <Tab
            value="social"
            total={categories.socialMediaResults.length}
            isEmbedded={isEmbedded}
          />
          <Tab
            value="fire"
            total={fireResults.length}
            isEmbedded={isEmbedded}
          />
          <Tab
            value="ship"
            total={shipResults.length}
            isEmbedded={isEmbedded}
          />
          <Group ml="auto" gap="xs">
            {ids.length > 0 &&
              showTotalProcessedResults &&
              ids.length <
                totalProcessedResults - categories.insightResults.length && (
                <Text size="xs" c="gray">
                  Showing the top {ids.length} results out of{' '}
                  {totalProcessedResults - categories.insightResults.length}
                </Text>
              )}
            <Select
              size="xs"
              value={sortPreference}
              onChange={(value) =>
                value
                  ? setSortPreference(value as 'authoredOn' | 'score')
                  : setSortPreference('score')
              }
              data={[
                { label: 'Date', value: 'authoredOn' },
                { label: 'Relevance', value: 'score' },
              ]}
              maw={100}
              style={{
                marginBottom: isEmbedded ? 8 : 0,
              }}
              data-cy="sort-results"
            />
            {!isEmbedded && (
              <FilterToggleButton
                isFilterOpen={isFilterOpen}
                onClick={toggle}
              />
            )}
          </Group>
        </Tabs.List>
        {!isEmbedded && <FilterPanel isOpen={isFilterOpen} />}
        <ResultsTimelineContainer isSmall={isEmbedded} />
        <TabPanel value="images">
          {onlyOrtho ? (
            <ImagesPanel
              imageResults={orthoLayerGroups}
              isEmbedded={isEmbedded}
              makeThumbnail={(item) => (
                <OrthoThumbnail item={item as OrthoImageryLayerGroup} />
              )}
            />
          ) : (
            <ImagesPanel
              imageResults={categories.imageResults}
              isEmbedded={isEmbedded}
              makeThumbnail={(item) => (
                <Thumbnail item={item as ImageryResult} />
              )}
            />
          )}
        </TabPanel>
        <TabPanel value="news">
          <SimpleGrid cols={{ base: 2, xl: 3 }}>
            {categories.publicationResults.map((result) => (
              <NewsResult key={result.documentId} result={result} />
            ))}
          </SimpleGrid>
        </TabPanel>
        <TabPanel value="social">
          <SimpleGrid cols={{ base: 2, xl: 3 }}>
            {categories.socialMediaResults.map((result) => (
              <SocialResult
                key={result.documentId}
                result={result}
                hideActions={['viewOnMap']}
              />
            ))}
          </SimpleGrid>
        </TabPanel>
        <TabPanel value="fire">
          <SimpleGrid cols={{ base: 2, xl: 3 }}>
            {fireResults.map((result) => (
              <FireResult key={result.documentId} result={result} />
            ))}
          </SimpleGrid>
        </TabPanel>
        <TabPanel value="ship">
          <SimpleGrid cols={{ base: 2, xl: 3 }}>
            {shipResults.map((result) => (
              <ShipResult key={result.documentId} result={result} />
            ))}
          </SimpleGrid>
        </TabPanel>
      </Tabs>
    </>
  )
}
