import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHotkeys } from '@mantine/hooks'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { graphWs } from '@/utils/constants.ts'
import {
  type ChatMessage,
  isChatResponse,
  isResultMessage,
  isStatusMessage,
  type SocketMessage,
  type StatusMessage,
} from '@/utils/types/message-types.ts'
import type { RawSearchResult } from '@/utils/types/result-types.ts'
import { singletonHook } from 'react-singleton-hook'

const useWebsocketImpl = () => {
  const { user } = useDantiAuth()

  const socket = React.useRef<WebSocket>()

  const [lastResults, setLastResults] = useState<RawSearchResult[]>([])
  const [lastStatusMessages, setLastStatusMessages] = useState<StatusMessage[]>(
    [],
  )
  const [lastChatMessages, setLastChatMessages] = useState<ChatMessage[]>([])

  const currentQueryId = resultsStore((s) => s.currentQueryId)
  const queryIdRef = useRef<string | null>(currentQueryId)

  const sendMessage = useCallback(
    async (action: string, message: Record<string, any>) => {
      if (socket.current === undefined) {
        console.log('socket is undefined')
      } else {
        const messageAction = {
          ...message,
          action: action,
        }
        console.debug('Sending message over WebSocket', messageAction)
        if (socket.current?.readyState === WebSocket.CONNECTING) {
          await new Promise<void>((accept, reject) => {
            if (socket.current) {
              socket.current?.addEventListener('open', () => accept())
              socket.current?.addEventListener('error', () => reject())
            }
          })
          socket.current.send(JSON.stringify(messageAction))
        } else if (socket.current?.readyState === socket.current?.OPEN) {
          socket.current.send(JSON.stringify(messageAction))
        }
      }
    },
    [],
  )

  const createWebsocket = useCallback(() => {
    if (!user?.access_token) {
      return
    }

    const url = `${graphWs}?token=${user.access_token}`
    socket.current = new WebSocket(url)

    socket.current?.addEventListener('open', () => {
      console.time('socket open time')
      console.log('websocket opened')
      void sendMessage('query', {
        queryId: currentQueryId,
      })
    })

    socket.current?.addEventListener('error', (event) => {
      console.log('websocket error', event)
      console.timeEnd('socket open time')
    })

    socket.current?.addEventListener('close', () => {
      console.log('websocket closed')
      console.timeEnd('socket open time')
    })

    socket.current?.addEventListener('message', (event: { data: string }) => {
      const data = JSON.parse(event.data) as SocketMessage
      if (data.queryId && data.queryId !== queryIdRef.current) {
        console.log('Ignoring message for old query')
        return
      }

      if (isResultMessage(data)) {
        setLastResults((state) => [...data.results, ...state])
      } else if (isStatusMessage(data)) {
        setLastStatusMessages((state) => [data, ...state])
      } else if (isChatResponse(data)) {
        setLastChatMessages((state) => [data, ...state])
      }
    })
  }, [currentQueryId, sendMessage, user?.access_token])

  const closeSocket = useCallback(() => {
    if (socket.current) {
      socket.current.close()
    }
  }, [])

  // Emergency shutoff for when the websocket just won't stop believin'
  useHotkeys([['mod+shift+U', closeSocket]])

  useEffect(() => {
    if (currentQueryId) {
      queryIdRef.current = currentQueryId
      void sendMessage('query', {
        queryId: currentQueryId,
      })
    }
  }, [currentQueryId, sendMessage])

  useEffect(() => {
    // don't reconnect the socket on token change if we already have an open connection
    if (
      socket.current &&
      (socket.current.readyState === WebSocket.CONNECTING ||
        socket.current.readyState === WebSocket.OPEN)
    ) {
      return
    }

    createWebsocket()
  }, [createWebsocket])

  return {
    sendMessage,
    lastResults,
    flushLastResults: () => setLastResults([]),
    lastStatusMessages,
    flushLastStatusMessages: () => setLastStatusMessages([]),
    lastChatMessages,
    flushLastChatMessages: () => setLastChatMessages([]),
    closeSocket,
  }
}

export const useWebsocket = singletonHook<{
  sendMessage: (action: string, message: Record<string, any>) => void
  lastResults: RawSearchResult[]
  flushLastResults: () => void
  lastStatusMessages: StatusMessage[]
  flushLastStatusMessages: () => void
  lastChatMessages: ChatMessage[]
  flushLastChatMessages: () => void
  closeSocket: () => void
}>(
  () => ({
    sendMessage: () => {},
    lastResults: [],
    flushLastResults: () => {},
    lastStatusMessages: [],
    flushLastStatusMessages: () => {},
    lastChatMessages: [],
    flushLastChatMessages: () => {},
    closeSocket: () => {},
  }),
  useWebsocketImpl,
)
