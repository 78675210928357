import { Table } from '@mantine/core'
import { ResultImage } from '@/components/lib/ResultImage.tsx'
import type {
  Collection,
  CollectionItem,
} from '@/utils/types/collection-types.ts'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import type React from 'react'

import styles from './collection-item-list.module.css'

dayjs.extend(relativeTime)

export function CollectionItemList({
  collection,
  menu: Menu,
  onClick,
}: {
  collection: Collection & { items: CollectionItem[] }
  menu: React.ComponentType<{ collectionId: string; item: CollectionItem }>
  onClick: (id: string) => void
}) {
  return (
    <Table
      className={styles['collectionItemList']}
      highlightOnHover
      w="100%"
      ta="left"
      mt="lg"
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th style={{ width: '2.5rem' }}>Select</Table.Th>
          <Table.Th style={{ width: '3.25rem' }}></Table.Th>
          <Table.Th style={{ width: '100%' }}>Name</Table.Th>
          <Table.Th style={{ width: '3rem' }}>Type</Table.Th>
          <Table.Th style={{ width: '6rem' }}>Source</Table.Th>
          <Table.Th style={{ width: '7rem' }}>Date Added</Table.Th>
          <Table.Th style={{ width: '2.5rem' }}></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {collection.items.map((item) => (
          <Table.Tr key={item.id}>
            <Table.Td style={{ textAlign: 'center' }}>
              <input type="checkbox" />
            </Table.Td>
            <Table.Td onClick={() => onClick(item.id)}>
              <ResultImage item={item.doc} />
            </Table.Td>
            <Table.Td onClick={() => onClick(item.id)}>
              {item.doc.title}
            </Table.Td>
            <Table.Td onClick={() => onClick(item.id)}>
              {item.doc.filetype}
            </Table.Td>
            <Table.Td onClick={() => onClick(item.id)}>
              {item.doc.source}
            </Table.Td>
            <Table.Td onClick={() => onClick(item.id)}>
              {dayjs(item.doc.authoredOn).fromNow()}
            </Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>
              <Menu collectionId={collection.id} item={item} />
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
