import { type MutableRefObject, useEffect } from 'react'
import { usePrevious } from '@mantine/hooks'
import { ZOOM_BUFFER_FACTOR } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useOrthoImagerySources } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import { mapStore } from '@/stores/map-store.ts'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'

export const useZoomToNewOrtholayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const [activeOrthoLayerGroupIds] = mapStore((s) => [
    s.activeOrthoLayerGroupIds,
  ])
  const previousActiveOrthoLayerGroupIds = usePrevious(activeOrthoLayerGroupIds)
  const { orthoLayerGroups } = useOrthoImagerySources()

  useEffect(() => {
    const previousIdSet = new Set(previousActiveOrthoLayerGroupIds)
    const newLayerIds = activeOrthoLayerGroupIds.filter(
      (id) => !previousIdSet.has(id),
    )
    if (newLayerIds.length === 1) {
      const olg = orthoLayerGroups.find((olg) => olg.id === newLayerIds[0])
      if (olg) {
        const bbox = turf.bbox(
          turf.transformScale(olg.geometry, ZOOM_BUFFER_FACTOR, {
            origin: 'centroid',
          }),
        ) as mapboxgl.LngLatBoundsLike
        map.current?.fitBounds(bbox)
      }
    }
  }, [
    activeOrthoLayerGroupIds,
    map,
    orthoLayerGroups,
    previousActiveOrthoLayerGroupIds,
  ])
}
