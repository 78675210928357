import { Stack, Spoiler, Text, List } from '@mantine/core'

export interface ExploreSummaryProps {
  showContentOne: boolean
}

export function ExploreSummary({ showContentOne }: ExploreSummaryProps) {
  const title = showContentOne
    ? 'Russian air bases near Ukraine'
    : 'Russian strikes on hospitals during the Russian invasion of Ukraine'
  const description = showContentOne ? (
    <Stack>
      <Text>
        The Russian air bases near Ukraine that are being used to launch attacks
        against Ukraine include:
      </Text>
      <List listStyleType="disc">
        <List.Item>
          Millerovo air base in Rostov Oblast, Russia, which was attacked by
          Ukrainian forces on February 25, 2022, destroying at least one Russian
          Su-30SM fighter jet
        </List.Item>
        <List.Item>
          Airfields in Crimea, such as the Baherove air base, which Russia has
          been actively using to launch bombing raids against Ukraine using
          Tupolev bombers armed with Kh-101 cruise missiles
        </List.Item>
        <List.Item>
          Up to 9 air bases in Belarus that Russia may be using to launch
          attacks, with Ukrainian officials reporting bombing raids by Sukhoi
          fighter jets taking off from Belarus
        </List.Item>
      </List>
    </Stack>
  ) : (
    <Stack>
      <Text>
        Series of deliberate military attacks on Ukrainian health care
        facilities. Russian Military targeted Ukrainian medical facilities,
        including hospitals, clinics, ambulances, and health workers.
        Prioritized attacks on medical facilities using Iranian-made drones
        like Shahed 131 and Shahed 136. As of December 21, 2023, WHO reported
        1,422 attacks on health care; verified 1,574 attacks and 118 health-care
        worker deaths by February 22, 2024
      </Text>
      <List listStyleType="disc">
        <List.Item>
          Over 1,000 attacks on healthcare facilities, workers, and
          infrastructure in Ukraine have been documented since the invasion...
        </List.Item>
        <List.Item>
          Airfields in Crimea, such as the Baherove air base, which Russia has
          been actively using to launch bombing raids against Ukraine using
          Tupolev bombers armed with Kh-101 cruise missiles
        </List.Item>
        <List.Item>
          Up to 9 air bases in Belarus that Russia may be using to launch
          attacks, with Ukrainian officials reporting bombing raids by Sukhoi
          fighter jets taking off from Belarus
        </List.Item>
      </List>
    </Stack>
  )

  return (
    <Stack gap={16} style={{ marginBottom: -24 }}>
      <Text
        style={{ fontSize: '42px', fontWeight: 'lighter', lineHeight: '48px' }}
      >
        {title}
      </Text>
      <Spoiler maxHeight={110} showLabel="more ⏷" hideLabel="Hide">
        <Text style={{ fontSize: '18px' }}>{description}</Text>
      </Spoiler>
    </Stack>
  )
}
