import { type FC, useCallback } from 'react'
import { Box, Image } from '@mantine/core'
import aiStarsIcon from '@/assets/ai-stars-icon.png'
import { useChat } from '@/hooks/use-chat.ts'

type AutoSearchWidgetProps = {
  prompt: string
}

export const AutoAskWidget: FC<AutoSearchWidgetProps> = ({ prompt }) => {
  const { sendPrompt } = useChat()

  const sendAutoSearch = useCallback(() => {
    sendPrompt(prompt)
  }, [prompt, sendPrompt])

  return (
    <Box onClick={sendAutoSearch} style={{ cursor: 'help' }}>
      <Image src={aiStarsIcon} />
    </Box>
  )
}
