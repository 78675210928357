import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Grid, List, ListItem, Stack, Text } from '@mantine/core'
import { SortSelect } from '@/components/input'
import { QueryHeader } from '@/components/layout/QueryHeader.tsx'
import { EmbeddedMap } from '@/components/lib/map-search/EmbeddedMap.tsx'
import { InsightsPanel } from '@/components/lib/results/InsightsPanel.tsx'
import {
  FirePanel,
  ImagePanel,
  NewsPanel,
  ShipPanel,
  SocialPanel,
} from '@/components/lib/results/Panel'
import { Skeleton } from '@/components/lib/Skeleton.tsx'
import { usePageTitle } from '@/hooks/use-page-title.ts'
import { useSearch } from '@/hooks/use-search.ts'
import { SearchStatuses, useSearchStatus } from '@/hooks/use-search-status.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { wordBreak } from '@/utils/ui-helpers.tsx'

const MAP_HEIGHT = 580

interface StatusAwareSkeletonProps {
  height?: string
  fallback?: React.ReactNode
  condition?: boolean
  children?: React.ReactNode
}

/**
 * Reads search status to correctly display a skeleton while searching.
 * Once the search is completed, uses the `condition` to determine whether to
 * display the children or null, or the fallback if the search returned no results.
 * @example
 * <StatusAwareSkeleton condition={hasResults} fallback={<NoResults />}>
 *   <ResultDisplay />
 * </StatusAwareSkeleton>
 */
function StatusAwareSkeleton({
  height = '100%',
  children = null,
  condition = true,
  fallback = null,
}: StatusAwareSkeletonProps) {
  const { status } = useSearchStatus()

  const isLoading = ![
    SearchStatuses.NOT_STARTED,
    SearchStatuses.READY,
    SearchStatuses.NO_RESULTS,
  ].includes(status)

  if (isLoading) {
    return (
      <div style={{ height }}>
        <Skeleton />
      </div>
    )
  } else {
    if (condition) {
      return children
    } else if (status === SearchStatuses.NO_RESULTS) {
      return fallback
    } else {
      return null
    }
  }
}

export default function ResultsOverview() {
  const [imageResults, newsResults, insightResults, dataResults] = resultsStore(
    (s) => [
      s.categorized.imageResults,
      s.categorized.publicationResults,
      s.categorized.insightResults,
      s.categorized.dataResults,
    ],
  )

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const search = params.get('search')

  const [currentQuery] = queryStore((state) => [state.currentQuery])
  const { doTextSearch } = useSearch()

  usePageTitle(`${currentQuery} - Danti Search`)

  React.useEffect(() => {
    if (search && search !== currentQuery) {
      void doTextSearch(search)
    }
    // This `useLocation` stateful hook is updated anytime the URL changes.
    // We use location.search as a dependency here because we want to update the
    // query when the user navigates to the same page with a new query string.
    // This ensures expected behavior for forward/back history navigation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentQuery])

  const hasImages = imageResults.length > 0
  const hasNews = newsResults.length > 0
  const hasInsights = insightResults.length > 0
  const hasData = dataResults.length > 0
  const hasMapResults = hasImages || hasNews || hasData

  const panelAssignments = {
    left: hasNews && hasImages ? <NewsPanel position="left" /> : null,
    center: hasImages ? (
      <ImagePanel position="center" />
    ) : hasNews ? (
      <NewsPanel position="center" />
    ) : null,
  }

  return (
    <>
      <QueryHeader />
      <Container size="100%">
        <Grid columns={12} gutter="xl" mt="xl" mb="sm">
          <Grid.Col offset={2} span={7} mih={48} py={0} mt="md">
            <StatusAwareSkeleton
              condition={hasInsights}
              fallback={
                <>
                  <Text fz={48} fw={700}>
                    {`There's no clear answer`}
                  </Text>
                  <Text>
                    {`Your search didn't return a clear result. Refine your question and try again.`}
                  </Text>
                </>
              }
            >
              <Text
                fz={48}
                fw={700}
                lh={1.1}
                lineClamp={3}
                title={insightResults[0]?.title}
              >
                {hasInsights && wordBreak(insightResults[0].title)}
              </Text>
            </StatusAwareSkeleton>
          </Grid.Col>
        </Grid>
        <Grid columns={12} gutter="xl">
          <Grid.Col span={2}>
            {panelAssignments['left'] ?? <StatusAwareSkeleton />}
          </Grid.Col>
          <Grid.Col span={7}>
            <Stack mih={MAP_HEIGHT} gap="xl">
              <StatusAwareSkeleton
                height={`calc(${MAP_HEIGHT}px * 0.25)`}
                condition={hasInsights}
                fallback={
                  <div>
                    <Text fz={20} fw={700}>
                      Some suggestions to get a better answer
                    </Text>
                    <List mt="sm">
                      <ListItem>
                        Be specific. For example, if you are trying to find out
                        about battle damage in a city, ask about specific
                        structures
                      </ListItem>
                      <ListItem>
                        Reference a location like a city, state, country or zip
                        code
                      </ListItem>
                      <ListItem>
                        Phrase your question naturally, as if you were talking
                        to a person
                      </ListItem>
                      <ListItem>Avoid using jargon, slang or acronyms</ListItem>
                      <ListItem>
                        Use latitude, longitude ordering for decimal degree
                        inputs
                      </ListItem>
                      <ListItem>
                        Explore the <Link to="/map">map</Link>
                      </ListItem>
                    </List>
                  </div>
                }
              >
                {insightResults.map((result) => (
                  <InsightsPanel key={result.id} result={result} />
                ))}
              </StatusAwareSkeleton>

              {panelAssignments['center'] ?? (
                <StatusAwareSkeleton
                  height={`calc(((${MAP_HEIGHT}px * 0.75) - var(--mantine-spacing-xl))`}
                />
              )}
              <SocialPanel />
              <FirePanel />
              <ShipPanel />
            </Stack>
          </Grid.Col>
          <Grid.Col span={3}>
            <Stack h={MAP_HEIGHT} gap={12}>
              {hasMapResults ? (
                <>
                  <SortSelect style={{ alignSelf: 'end' }} />
                  <EmbeddedMap />
                </>
              ) : (
                <StatusAwareSkeleton />
              )}
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  )
}
