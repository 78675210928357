import type GeoJSON from 'geojson'
import type mapboxgl from 'mapbox-gl'
import type { MutableRefObject } from 'react'

export const RETRY_DELAY_MS = 500
export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
export const safeSetLayoutProperty = async (
  map: MutableRefObject<mapboxgl.Map | null>,
  layer: string,
  name: string,
  value: any,
) => {
  try {
    while (map.current?.getLayer(layer) === undefined) {
      // eslint-disable-next-line no-await-in-loop
      await delay(RETRY_DELAY_MS)
    }
  } catch {
    /* empty */
  }

  map.current?.setLayoutProperty(layer, name, value)
}
export const safeSetMapSourceData = async (
  map: MutableRefObject<mapboxgl.Map | null>,
  sourceName: string,
  data: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
) => {
  try {
    while (map.current?.getSource(sourceName) === undefined) {
      // eslint-disable-next-line no-await-in-loop
      await delay(RETRY_DELAY_MS)
    }
  } catch {
    /* empty */
  }
  const source = map.current?.getSource(sourceName) as mapboxgl.GeoJSONSource
  source.setData(data)
}
