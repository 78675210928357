import { type CardProps, Group, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface NewsResultProps extends CardProps {
  result: ResultWithId
  isFullscreen?: boolean
}

export function NewsResult({
  result,
  isFullscreen,
  ...props
}: NewsResultProps) {
  return (
    <ResultBase
      result={result}
      onClick="visitArticle"
      hideActions={['downloadImage', 'viewOnMap']}
      className={styles['separated']}
      data-cy="newsResult"
      {...props}
    >
      <Stack gap="xs" mb="md" title={result.title}>
        <Text
          className={styles['link-ish']}
          fz={isFullscreen ? 'sm' : 'xs'}
          lineClamp={isFullscreen ? 3 : 3}
          fw="bold"
        >
          {result.title}
        </Text>
        <Group gap="xs" wrap="nowrap">
          <Icon name="calendar_today" size="sm" />
          <Text fz={isFullscreen ? 'sm' : 'xs'} truncate="end">
            {dayjs(result.properties.datetime).utc().format('MMMM DD, YYYY')}
          </Text>
        </Group>
        {isFullscreen && <Text fw="bold">{result.source}</Text>}
      </Stack>
    </ResultBase>
  )
}
