import type { CategorizedResults } from '@/stores/results-store.ts'
import {
  apiUrl,
  oidcAuthority,
  oidcClientId,
  serviceApiUrl,
} from '@/utils/constants.ts'
import {
  type DataResult,
  type ImageryResult,
  type InsightResult,
  isDataResult,
  isImageryResult,
  isInsightResult,
  isPublicationResult,
  isRelatedImageryResult,
  isSocialMediaResult,
  type PublicationResult,
  type RawSearchResult,
  type RelatedImageryResult,
  type ResultWithId,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'
import { captureException } from '@sentry/react'
import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import { uniqBy } from 'lodash'
import { User } from 'oidc-client-ts'

const MAX_IMAGE_RESULTS = 800
const MAX_PUBLICATION_RESULTS = 300

// TODO: Remove this entirely and use `result.documentId` as canonical ID source everywhere
export function addIdToResult(result: RawSearchResult): ResultWithId {
  return { ...result, id: result.documentId }
}

export interface GroupedResults {
  ids: string[]
  imageResults: (ImageryResult | RelatedImageryResult)[]
  dataResults: DataResult[]
  publicationResults: PublicationResult[]
  socialMediaResults: SocialMediaResult[]
  insightResults: InsightResult[]
}

export function groupResultsByType(results: ResultWithId[]) {
  const grouped = results.reduce(
    (categorized, result) => {
      categorized.ids.push(result.id)
      if (isImageryResult(result) || isRelatedImageryResult(result)) {
        // TODO: Hack to limit results before we have a paginated API
        if (categorized.imageResults.length < MAX_IMAGE_RESULTS) {
          categorized.imageResults.push(result)
        }
      } else if (isDataResult(result)) {
        categorized.dataResults.push(result)
      } else if (isPublicationResult(result)) {
        // TODO: Hack to limit results before we have a paginated API
        if (categorized.publicationResults.length < MAX_PUBLICATION_RESULTS) {
          categorized.publicationResults.push(result)
        }
      } else if (isSocialMediaResult(result)) {
        categorized.socialMediaResults.push(result)
      } else if (isInsightResult(result)) {
        categorized.insightResults.push(result)
      }

      return categorized
    },
    <GroupedResults>{
      ids: [],
      imageResults: [],
      dataResults: [],
      publicationResults: [],
      socialMediaResults: [],
      insightResults: [],
    },
  )

  return grouped
}

export function filterAndGroupResults(
  results: RawSearchResult[],
  categorized: CategorizedResults,
) {
  const existing = Object.values(categorized).flat() as ResultWithId[]
  const resultsWithIds = results.map(addIdToResult)
  const sortedResults = [...resultsWithIds, ...existing].sort(
    (a, b) => b.score - a.score,
  )
  const uniqueResults = uniqBy(sortedResults, (result) => result.id)

  return groupResultsByType(uniqueResults)
}

export const axiosClient = axios.create({
  baseURL: apiUrl,
})

axiosClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    captureException(error)
    return Promise.reject(error)
  },
)

export function getUserAuthToken() {
  const oidcUser = window.localStorage.getItem(
    `oidc.user:${oidcAuthority}:${oidcClientId}`,
  )
  if (!oidcUser) {
    return
  }

  return User.fromStorageString(oidcUser).access_token
}

export function setUserAuthForFetch(
  token: string | undefined = getUserAuthToken(),
) {
  if (!token) {
    return
  }

  return axiosClient.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}

export const axiosAuthInterceptor = setUserAuthForFetch()

export const axiosClientNoAuth = axios.create({
  baseURL: apiUrl,
})

axiosClientNoAuth.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    captureException(error)
    return Promise.reject(error)
  },
)

export function getRequest<ResponseType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.get(url, config)
}

export function getRequestNoAuth<ResponseType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClientNoAuth.get(url, config)
}

export function postServiceRequest<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.post(url, payload, { ...config, baseURL: serviceApiUrl })
}

export function postRequest<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.post(url, payload, config)
}

export function deleteRequest<ResponseType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.delete(url, config)
}
