import { Group, Text, em } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import type { SymbolName } from '@/utils/types/material-icons'
import { useMediaQuery } from '@mantine/hooks'

export interface FooterItem {
  title: string
  icon: SymbolName
}

export interface ExploreFooterProps {
  items: FooterItem[]
}

export function ExploreFooter({ items }: ExploreFooterProps) {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)

  return (
    <Group gap="16px" style={{ marginTop: 20 }}>
      {items.map((x) => (
        <Group
          gap={10}
          key={x.title}
          w={{ base: 'calc(50% - 10px)', md: 'auto' }}
          style={{
            background: '#EDEADE',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'row',
            height: 60,
            paddingLeft: 11,
            paddingRight: 11,
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <Icon
            name={x.icon}
            style={{
              padding: '5px',
              background: '#FFF',
              borderRadius: 20,
              display: 'inline-block',
              width: 30,
            }}
            color="#6B6B6B"
          />
          <Text
            maw={{ base: '100%', md: 80 }}
            style={{
              fontSize: '14px',
              whiteSpace: isMobile ? 'pre-wrap' : undefined,
            }}
          >
            {x.title}
          </Text>
        </Group>
      ))}
    </Group>
  )
}
