import { useMemo } from 'react'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { hazardPropertySample7434 } from '@/utils/types/single-address/__tests__/sample-data/hazard/property-sample-7434.ts'
import { hazardRiskSample7434 } from '@/utils/types/single-address/__tests__/sample-data/hazard/risk-sample-7434.ts'
import {
  isHazardPropertyResult,
  parseHazardPropertyAddress,
} from '@/utils/types/single-address/hazard-property-types.ts'
import type {
  LabeledScoredRisk,
  ScoredRiskKey,
} from '@/utils/types/single-address/hazard-risk-types.ts'
import {
  isHazardRiskResult,
  parseHazardRiskAddress,
  ScoredRiskTypes,
} from '@/utils/types/single-address/hazard-risk-types.ts'
import { matchesRawAddress } from '@/utils/us-address-utils.ts'

export const useHazard = (isMock: boolean) => {
  const dataResults = resultsStore((s) => s.categorized.dataResults)
  const currentQuery = queryStore((s) => s.currentQuery)

  const hazardPropertyRecord = useMemo(
    () =>
      isMock
        ? hazardPropertySample7434.properties
        : dataResults
            .filter(isHazardPropertyResult)
            .find((r) =>
              matchesRawAddress(
                currentQuery,
                parseHazardPropertyAddress(r.properties),
              ),
            )?.properties,
    [currentQuery, dataResults, isMock],
  )
  const hazardRiskRecord = useMemo(
    () =>
      isMock
        ? hazardRiskSample7434.properties
        : dataResults
            .filter(isHazardRiskResult)
            .find((r) =>
              matchesRawAddress(
                currentQuery,
                parseHazardRiskAddress(r.properties),
              ),
            )?.properties,
    [currentQuery, dataResults, isMock],
  )

  const riskRecords = useMemo(() => {
    return Object.keys(ScoredRiskTypes)
      .map((k) => ({
        record: hazardRiskRecord?.[k as ScoredRiskKey],
        type: k,
        label: ScoredRiskTypes[k as ScoredRiskKey],
      }))
      .filter((r): r is LabeledScoredRisk => !!r.record)
  }, [hazardRiskRecord])

  const risksScoredF = useMemo(
    () => riskRecords.filter((r) => r.record.score === 'F'),
    [riskRecords],
  )

  const risksScoredD = useMemo(
    () => riskRecords.filter((r) => r.record.score === 'D'),
    [riskRecords],
  )

  const risksScoredC = useMemo(
    () => riskRecords.filter((r) => r.record.score === 'C'),
    [riskRecords],
  )

  const mlsImages = useMemo(
    () => hazardPropertyRecord?.images,
    [hazardPropertyRecord?.images],
  )

  return {
    hazardPropertyRecord,
    hazardRiskRecord,
    riskRecords,
    risksScoredF,
    risksScoredD,
    risksScoredC,
    mlsImages,
  }
}
