import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { CollectionItemGrid } from '@/components/lib/CollectionItemGrid.tsx'
import { CollectionItemList } from '@/components/lib/CollectionItemList.tsx'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  //openAnalyzeCollectionModal,
  openAnalyzeItemModal,
  openDeleteCollectionModal,
  openDownloadCollectionModal,
  openDownloadItemModal,
  openRemoveFromCollectionModal,
} from '@/components/modals/openers.tsx'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { userStore } from '@/stores/user-store.ts'
import { useCollections } from '@/utils/data-fetching/collections.ts'
import type { CollectionItem } from '@/utils/types/collection-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import pluralize from 'pluralize'

function CollectionItemMenu({
  collectionId,
  item,
}: {
  collectionId: string
  item: CollectionItem
}) {
  const { data } = useIsDownloadable(item.doc, true)
  const isCheckingDownloadability = data === undefined
  const isDownloadable = data ?? false

  return (
    <Menu shadow="md" withinPortal>
      <Menu.Target>
        <ActionIcon variant="subtle" data-cy="collection-item-menu">
          <Icon name="more_horiz" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {isDownloadable && (
          <Menu.Item
            leftSection={
              <Icon
                name={
                  isCheckingDownloadability ? 'clock_loader_10' : 'download'
                }
              />
            }
            onClick={() => openDownloadItemModal(item.doc as ImageryResult)}
            disabled={!isDownloadable}
            data-cy="collection-item-menu-download"
          >
            Download
          </Menu.Item>
        )}
        <Menu.Item
          leftSection={
            <Icon
              name={isCheckingDownloadability ? 'clock_loader_10' : 'analytics'}
            />
          }
          onClick={() => openAnalyzeItemModal(item.doc as ImageryResult)}
          disabled={!isDownloadable}
          data-cy="collection-item-menu-analyze"
        >
          Analyze
        </Menu.Item>
        <Menu.Item
          leftSection={<Icon name="delete" />}
          color="red"
          onClick={() => openRemoveFromCollectionModal(collectionId, item.id)}
          data-cy="collection-item-menu-remove"
        >
          Remove
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default function CollectionDetail() {
  const { collectionId } = useParams()
  const navigate = useNavigate()
  const { data } = useCollections()
  const avatarBackgroundColor = userStore(
    (state) => state.avatarBackgroundColor,
  )
  const userInitials = userStore(
    (state) => state.firstName.charAt(0) + state.lastName.charAt(0),
  )

  const [isListView, setIsListView] = React.useState(false)

  const collection = data.find(({ id }) => id === collectionId)

  if (!collection) {
    return null
  }

  // Defined as const to avoid hoisting, so we know `collection` is defined
  const handleClick = (id: string) => {
    navigate(`/collections/${collection.id}/item/${id}`)
  }

  return (
    <Flex h="calc(100% + 2rem)" mt="-1.875rem" mx="-xl">
      <Box
        pos="fixed"
        style={{ borderRight: '1px solid #ddd' }}
        w="250px"
        h="100%"
        pt="xl"
        px="md"
      >
        <Group justify="space-between" mt="lg">
          <Text c="blue" size="sm" tt="uppercase">
            Collection
          </Text>
          <Menu shadow="md" withinPortal>
            <Menu.Target>
              <ActionIcon data-cy="collection-menu">
                <Icon name="more_horiz" />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              {/* TODO update again when collection wide analysis is online
              <Menu.Item
                leftSection={<Icon name="analytics" />}
                onClick={() => openAnalyzeCollectionModal(collection.id)}
                data-cy="collection-menu-analyze"
              >
                Image Detections
              </Menu.Item> */}
              <Menu.Item
                leftSection={<Icon name="send" />}
                onClick={() => openDownloadCollectionModal(collection)}
                data-cy="collection-menu-download"
              >
                Send to Vendor
              </Menu.Item>
              <Menu.Item
                leftSection={<Icon name="delete" />}
                color="red"
                onClick={() => openDeleteCollectionModal(collection.id)}
                data-cy="collection-menu-delete"
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        <Stack gap="xs" my="xs">
          <Title order={2} c="#424242" data-cy="collection-name">
            {collection.name}
          </Title>
          <Text size="xs" c="dimmed">
            {collection.modifiedOn
              ? `Updated ${dayjs(collection.modifiedOn).fromNow()}`
              : `Created ${dayjs(collection.createdOn).fromNow()}`}
          </Text>
          <Text size="sm" c="dimmed" mt="xs" data-cy="collection-description">
            {collection.desc}
          </Text>
        </Stack>
        <Group justify="space-between" mt="lg" mb="xs">
          <Text size="sm" fw="bold" c="dimmed">
            Tags
          </Text>
          <Button
            size="xs"
            variant="default"
            disabled
            data-cy="collection-add-tag"
          >
            Add
          </Button>
        </Group>
        <Chip.Group data-cy="collection-tags">
          {collection.tags?.map((tag) => (
            <Chip key={tag} size="xs">
              {tag}
            </Chip>
          ))}
        </Chip.Group>
        <Divider my="md" />
        <Group justify="space-between">
          <Avatar.Group>
            <Avatar variant="filled" radius="50%" color={avatarBackgroundColor}>
              {userInitials}
            </Avatar>
          </Avatar.Group>
        </Group>
      </Box>

      <Stack gap={0} w="100%" ml={249} py="2rem" px="3rem">
        <Group justify="space-between">
          <Text c="gray" size="md" tt="uppercase">
            {collection.items.length}{' '}
            {pluralize('Item', collection.items.length)}
          </Text>
          <Group gap="sm">
            <Button.Group>
              <ActionIcon
                onClick={() => setIsListView(false)}
                variant={isListView ? 'subtle' : 'filled'}
                data-cy="collection-detail-grid-view"
              >
                <Icon name="grid_view" />
              </ActionIcon>
              <ActionIcon
                onClick={() => setIsListView(true)}
                variant={isListView ? 'filled' : 'subtle'}
                data-cy="collection-detail-list-view"
              >
                <Icon name="reorder" />
              </ActionIcon>
            </Button.Group>
          </Group>
        </Group>

        {isListView ? (
          <CollectionItemList
            collection={collection}
            menu={CollectionItemMenu}
            onClick={handleClick}
          />
        ) : (
          <CollectionItemGrid
            collection={collection}
            menu={CollectionItemMenu}
            onClick={handleClick}
          />
        )}
      </Stack>
    </Flex>
  )
}
