import {
  extractAssetName,
  extractCompanyName,
  extractSensorName,
} from '@/hooks/results/result-filter-utilities.ts'
import {
  type PostFilterType,
  usePostfilterImageResults,
} from '@/hooks/results/use-postfilter-image-results.ts'
import { resultsStore } from '@/stores/results-store.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Dictionary } from 'lodash'
import { groupBy } from 'lodash'

export const useImageResultsPropertyHistogram = () => {
  const allImageResults = resultsStore((s) => s.categorized.imageResults)
  const prefilteredResults: Partial<{
    [key in PostFilterType]: ImageryResult[]
  }> = {
    source: usePostfilterImageResults({ excludeType: 'source' }),
    repo: usePostfilterImageResults({ excludeType: 'repo' }),
    asset: usePostfilterImageResults({ excludeType: 'asset' }),
    sensor: usePostfilterImageResults({ excludeType: 'sensor' }),
  }

  const makeGroupHistogram = (
    results: ImageryResult[],
    filterType: PostFilterType,
  ): Dictionary<ImageryResult[]> => {
    switch (filterType) {
      case 'source':
        return groupBy(results, (ir) => extractCompanyName(ir))
      case 'repo':
        return groupBy(results, (ir) => ir.source)
      case 'asset':
        return groupBy(results, (ir) => extractAssetName(ir))
      case 'sensor':
        return groupBy(results, (ir) => extractSensorName(ir))
    }
    return {}
  }

  const makeHistogramWithZeroElements = (
    filterType: PostFilterType,
  ): Dictionary<ImageryResult[]> => {
    const fullHistogram = makeGroupHistogram(allImageResults, filterType)
    const prefilteredResultsForType = prefilteredResults[filterType]
    if (!prefilteredResultsForType) {
      return fullHistogram || {}
    }
    const filteredHistogram = makeGroupHistogram(
      prefilteredResultsForType,
      filterType,
    )
    const zeroEntries = Object.fromEntries(
      Object.keys(fullHistogram)
        .filter((k) => !filteredHistogram[k])
        .map((k) => [k, []]),
    )

    return {
      ...filteredHistogram,
      ...zeroEntries,
    }
  }

  return {
    source: makeHistogramWithZeroElements('source'),
    repo: makeHistogramWithZeroElements('repo'),
    asset: makeHistogramWithZeroElements('asset'),
    sensor: makeHistogramWithZeroElements('sensor'),
  }
}
