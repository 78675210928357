import { createStore } from '@/utils/create-store.ts'
import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'
import {
  QuerySourceKeys,
  QuerySourceState,
  type QueryStatus,
} from '@/utils/types/message-types.ts'

export type SortPreferenceOption = 'score' | 'authoredOn'
export interface QueriesState {
  currentQuery: string
  currentQueryFilters: SearchApiFilterValue[]
  currentQueryStart: Date | null
  recentQueries: string[]
  setQuery: (query: string) => void
  setQueryFilters: (apiFilters: SearchApiFilterValue[]) => void
  queryStatus: QueryStatus
  clear: () => void
  setStatus: (key: QuerySourceKeys, state: QuerySourceState) => void
  setQueryStarted: () => void
  totalProcessedResults: number
  setTotalProcessedResults: (count: number) => void
  sortPreference: SortPreferenceOption
  setSortPreference: (sortPreference: SortPreferenceOption) => void
}

const initialState: Partial<QueriesState> = {
  currentQuery: '',
  currentQueryFilters: [],
  currentQueryStart: null,
  recentQueries: [],
  queryStatus: Object.fromEntries(
    Object.values(QuerySourceKeys).map((k) => [
      k,
      QuerySourceState.NOT_STARTED,
    ]),
  ) as QueryStatus,
  totalProcessedResults: 0,
  sortPreference: 'score',
}

export const queryStore = createStore<QueriesState>({
  storageKey: 'QueryStore',
  version: 0.03,
  initialState: initialState,
  actions: (set, get): Partial<QueriesState> => ({
    setQuery: (query: string) => {
      const recentQueries = get()?.recentQueries ?? []
      return set({
        currentQuery: query,
        queryStatus: initialState.queryStatus,
        recentQueries: ['', 'polygon'].includes(query)
          ? recentQueries
          : [...new Set([query, ...recentQueries])],
      })
    },
    clear: () =>
      set({
        currentQuery: '',
        currentQueryStart: null,
        queryStatus: initialState.queryStatus,
        totalProcessedResults: 0,
      }),
    setStatus: (key: QuerySourceKeys, state: QuerySourceState) => {
      const currentStatus = get().queryStatus
      if (
        currentStatus &&
        [QuerySourceState.COMPLETED, QuerySourceState.ERROR].includes(
          currentStatus[key],
        )
      ) {
        return
      }
      set({
        queryStatus: { ...(get().queryStatus as QueryStatus), [key]: state },
      })
    },
    setQueryStarted: () =>
      set({ currentQueryStart: new Date(), totalProcessedResults: 0 }),
    setTotalProcessedResults: (count: number) =>
      set({ totalProcessedResults: count }),
    setQueryFilters: (apiFilters: SearchApiFilterValue[]) =>
      set({ currentQueryFilters: apiFilters }),
    setSortPreference: (sortPreference) => set({ sortPreference }),
  }),
})
