import type BaseBrush from '@visx/brush/lib/BaseBrush'
import { Group } from '@visx/group'
import { Circle, Line } from '@visx/shape'
import type React from 'react'

export const makeBrushXButton = (
  brushRef: React.MutableRefObject<BaseBrush | null>,
  clearSelection: () => void,
) => {
  const diameter = 10
  const radius = diameter / 2

  const topRightCornerX = brushRef.current?.corners().topRight?.x ?? 0

  return (
    <>
      {brushRef.current && brushRef.current.getBrushWidth() > 0 && (
        <Group
          id="clearTimelineSelection"
          onClick={() => clearSelection()}
          style={{ cursor: 'pointer' }}
        >
          <Circle
            cx={topRightCornerX - radius}
            cy={0}
            r={diameter}
            fill="white"
            stroke="#7777"
          />
          <Line
            from={{
              x: topRightCornerX - diameter,
              y: -radius,
            }}
            to={{
              x: topRightCornerX,
              y: radius,
            }}
            stroke="#7777"
          />
          <Line
            from={{
              x: topRightCornerX - diameter,
              y: radius,
            }}
            to={{
              x: topRightCornerX,
              y: -radius,
            }}
            stroke="#7777"
          />
        </Group>
      )}
    </>
  )
}
