import { Box } from '@mantine/core'
import { BAR_FILL_COLOR } from '@/components/lib/results/results-timeline/bar-fill-color.tsx'
import { defaultStyles } from '@visx/tooltip'
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip'
import type React from 'react'
import type { TooltipData } from './results-tooltip-data.ts'

const TOOLTIP_COLOR_SWATCH_SIZE = 8

type ResultsTimelineTooltipProps = {
  timeBins: Date[]
  useTooltipParams: UseTooltipParams<TooltipData>
  dateFormatter: (d: Date) => string
}

export const ResultsTimelineTooltip: React.FC<ResultsTimelineTooltipProps> = ({
  timeBins,
  useTooltipParams,
  dateFormatter,
}) => {
  if (!(useTooltipParams.tooltipOpen && useTooltipParams.tooltipData)) {
    return <></>
  }

  return (
    <Box
      bottom={useTooltipParams.tooltipTop! + 45}
      left={useTooltipParams.tooltipLeft! - 40}
      style={{
        ...defaultStyles,
        minWidth: 60,
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        fontSize: 9,
        zIndex: 1000,
      }}
    >
      <div style={{ marginBottom: 16, marginTop: 12 }}>
        {dateFormatter(
          timeBins[useTooltipParams.tooltipData.index - 1] || new Date(),
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 8,
        }}
      >
        <span
          style={{
            display: 'block',
            width: TOOLTIP_COLOR_SWATCH_SIZE,
            height: TOOLTIP_COLOR_SWATCH_SIZE,
            marginRight: 4,
            backgroundColor: BAR_FILL_COLOR['IMAGE'].normal,
          }}
        ></span>
        <span>Images: {useTooltipParams.tooltipData.bar.data.IMAGE}</span>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 8,
        }}
      >
        <span
          style={{
            display: 'block',
            width: TOOLTIP_COLOR_SWATCH_SIZE,
            height: TOOLTIP_COLOR_SWATCH_SIZE,
            marginRight: 4,
            backgroundColor: BAR_FILL_COLOR['PUBLICATION'].normal,
          }}
        ></span>
        <span>News: {useTooltipParams.tooltipData.bar.data.PUBLICATION}</span>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 12,
        }}
      >
        <span
          style={{
            display: 'block',
            width: TOOLTIP_COLOR_SWATCH_SIZE,
            height: TOOLTIP_COLOR_SWATCH_SIZE,
            marginRight: 4,
            backgroundColor: BAR_FILL_COLOR['DATA'].normal,
          }}
        ></span>
        <span>Data: {useTooltipParams.tooltipData.bar.data.DATA}</span>
      </div>
    </Box>
  )
}
