export default function NotFound() {
  return (
    <div>
      <h1>404</h1>
      <h3>Not found</h3>
      <span role="img" aria-label="Sad">
        Sorry, we couldn't find what you're looking for &#x1F643;
      </span>
    </div>
  )
}
