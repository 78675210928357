export const attomPermitSample7434 = {
  title: 'Resource from Attom Data',
  subtitle: '',
  geometry: {
    coordinates: ['-122.726387', '45.471485'],
    type: 'Point',
  },
  documentId: 'unknown',
  score: 0.5,
  description: 'Resource from Attom Data',
  thumbnail: '',
  filetype: '',
  category: 'DATA',
  authoredOn: '2022-11-03T00:00:00',
  source: 'ATTOM',
  tags: null,
  license: 'unknown',
  asset: '',
  properties: {
    address: {
      country: 'US',
      countrySubd: 'OR',
      line1: '7434 SW 49TH CT',
      line2: 'PORTLAND, OR 97219',
      locality: 'PORTLAND',
      matchCode: 'ExaStr',
      oneLine: '7434 SW 49TH CT, PORTLAND, OR 97219',
      postal1: '97219',
      postal2: '1492',
      postal3: 'C023',
    },
    building: {
      rooms: {
        bathsTotal: 3,
        beds: 4,
      },
      size: {
        universalSize: 2981,
      },
    },
    buildingPermits: [
      {
        businessName: 'Nadeans Custom Plumbing LLC',
        description:
          '*single pdf* partially converting existing basement into a livable space addition staircase to main floor addition bedroom with closet addition full bathroom addition sliding door leading to deck. Mt et and pt included',
        effectiveDate: '2022-07-29',
        homeOwnerName: 'Isaacson,andrew & Isaacson,amy',
        permitNumber: '2022-167807-000-00-RS',
        status: 'issued',
        subType: 'Interior alteration only',
        type: 'Building permit',
      },
      {
        businessName: 'J Dodge Construction LLC',
        description:
          'Install a 3 sewer lateral on property for a bathroom addition and connecting to the main lateral for the house',
        effectiveDate: '2022-09-13',
        homeOwnerName: 'Isaacson,andrew & Isaacson,amy',
        permitNumber: '2022-180074-000-00-PT',
        status: 'final',
        subType: 'Addition/alteration/replacement',
        type: 'Building permit',
      },
      {
        businessName: 'AIR Maxx Heating & Cooling LLC',
        description: 'Relocate gas lines and air supplies',
        effectiveDate: '2022-10-01',
        homeOwnerName: 'Isaacson,andrew & Isaacson,amy',
        permitNumber: '2022-188931-000-00-MT',
        status: 'complete',
        subType: 'Addition/alteration/replace (mt)',
        type: 'Building permit',
      },
    ],
    identifier: {
      Id: 147938444,
      apn: '1S1E19AD 02301',
      attomId: 147938444,
      fips: '41051',
    },
    location: {
      accuracy: 'Rooftop',
      distance: 0,
      geoId:
        'CO41051, CS4192520, DB4110040, ND0000628798, ND0004662665, PL4159000, RS0000144504, SB0000112186, SB0000112214, SB0000112215, ZI97219',
      geoIdV4: {
        CO: 'fe537ed5e15f2aae50bce41e9a234641',
        CS: '2e2f48c3aecc24a1cb5bd66b8d11a672',
        DB: '46cab17588eaf850ebeaa8bbe6dc21f2',
        N1: '5ac03b3e09d2dff25587946f220107de',
        N2: 'cd369f72f1f3b7d9feb3963d76656819',
        N4: '5c217fd256c64354d9956aded587fbcb',
        PL: 'f2bdbbce9644a35b0b754a7f218c4065',
        SB: '9bf4770f309d2b3430e755137115ad19,f5c5bbeee4128bd5eeda1493fdfa0817,04fa410b69802df8e6d758d86bc7692e',
        ZI: '2289c92219b5c62430add5bda43e912e',
      },
      latitude: '45.471485',
      longitude: '-122.726387',
    },
    lot: {
      lotSize1: 0.3018136,
    },
    summary: {
      propClass: 'Single Family Residence / Townhouse',
      propIndicator: 10,
      propLandUse: 'SFR',
      propSubType: 'Residential',
      propType: 'SFR',
      propertyType: 'SINGLE FAMILY RESIDENCE',
      yearBuilt: 1999,
    },
    vintage: {
      lastModified: '2022-11-03',
      pubDate: '2023-06-17',
    },
  },
}
