import React from 'react'
import { Button, Group, Stack, Textarea, TextInput } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useCollections } from '@/utils/data-fetching/collections.ts'

export function CreateCollectionModal({ context, id }: ContextModalProps) {
  const { createCollection } = useCollections()

  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')

  async function handleCreate() {
    await createCollection({ name, desc: description })
    context.closeModal(id)
  }

  return (
    <>
      <Stack gap="sm">
        <TextInput
          label="Name"
          size="xs"
          required
          withAsterisk
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
          data-cy="create-collection-name"
        />
        <Textarea
          label="Description"
          size="xs"
          required
          withAsterisk
          value={description}
          onChange={(event) => setDescription(event.currentTarget.value)}
          data-cy="create-collection-description"
        />
      </Stack>

      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button
          disabled={!name || !description}
          onClick={() => void handleCreate()}
        >
          Create
        </Button>
      </Group>
    </>
  )
}
