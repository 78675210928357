import { isGov } from '@/utils/constants.ts'
import { QuerySourceKeys } from '@/utils/types/message-types.ts'

export const relevantQueryStatuses: QuerySourceKeys[] | undefined = isGov
  ? [
      QuerySourceKeys.PLANET,
      QuerySourceKeys.GEGD,
      QuerySourceKeys.DANTI_IMAGE,
      QuerySourceKeys.DANTI_WEB,
      QuerySourceKeys.DANTI_DATA,
    ]
  : undefined
