import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Stack, Tooltip } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { useEmbeddedInitialBehavior } from '@/components/lib/map-search/hooks/use-embedded-initial-behavior.ts'
import { useEmbeddedStyleLoadedCallback } from '@/components/lib/map-search/hooks/use-embedded-style-loaded-callback.ts'
import { useMapboxMap } from '@/components/lib/map-search/hooks/use-mapbox-map.ts'
import { useOrthoLayers } from '@/components/lib/map-search/hooks/use-ortho-layers.ts'
import type mapboxgl from 'mapbox-gl'

export const EmbeddedMap = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const nav = useNavigate()
  useMapboxMap(map, mapContainer)
  useEmbeddedInitialBehavior(map)
  useEmbeddedStyleLoadedCallback({ map })
  useOrthoLayers(map)

  return (
    <Stack h="100%" pos="relative">
      <Tooltip
        label="Expand Map"
        position="right"
        bg="rgba(97, 97, 97, 1)"
        withArrow
        arrowSize={7}
      >
        <ActionIcon
          onClick={() => {
            nav(`/map`)
          }}
          pos="absolute"
          radius="xs"
          top={10}
          right=".5rem"
          p="0"
          bg="#eee"
          style={{
            zIndex: 1,
            transform: `translateX(-100%), rotate(0deg)`,
            color: '#666',
            transition: 'right 0.25s',
            boxShadow:
              '0px 0px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.22) inset',
          }}
        >
          <Icon name="fullscreen" />
        </ActionIcon>
      </Tooltip>
      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: '100%' }}
      />
    </Stack>
  )
}
