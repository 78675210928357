import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Group, Stack, Text, Title } from '@mantine/core'
import { DataDisplay } from '@/components/lib/DataDisplay.tsx'
import { Icon } from '@/components/lib/Icon.tsx'
import { SingleImageMap } from '@/components/lib/map-search/SingleImageMap.tsx'
import {
  openAddToCollectionModal,
  openAnalyzeItemModal,
  openDownloadItemModal,
} from '@/components/modals/openers.tsx'
import { useAllResults } from '@/hooks/results/use-all-results.ts'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { useCollections } from '@/utils/data-fetching/collections'
import { addIdToResult, getRequest } from '@/utils/data-fetching/config.ts'
import { createStaticMapUrl } from '@/utils/static-map.ts'
import {
  type ImageryResult,
  isImageryResult,
  type RawSearchResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { wordBreak } from '@/utils/ui-helpers.tsx'
import dayjs from 'dayjs'

interface ItemDetailProps {
  onGoBack?: () => void
}

function ItemDetailComponent({
  item,
  onGoBack,
}: ItemDetailProps & { item: ImageryResult }) {
  const { data: isDownloadable } = useIsDownloadable(item, true)

  return (
    <>
      <Stack>
        <Title size="h1" fw="bold" c="#3e3f42" mb="sm">
          {wordBreak(item.title)}
        </Title>
        {item.subtitle && (
          <Text size="xl" c="#6b6c6f" mb="md">
            {item.subtitle}
          </Text>
        )}
        <Text size="md" fw="bold" c="#3e3f42" mb="sm">
          {item.source}
          {' - '}
          {dayjs(item.authoredOn).format('MM/DD/YYYY [at] h:mmA [GMT]Z')}
        </Text>
      </Stack>

      <Group
        justify="space-between"
        pb="md"
        style={{ borderBottom: '1px solid #acb4c1' }}
      >
        <Button
          variant="outlined"
          leftSection={<Icon name="arrow_left_alt" />}
          onClick={onGoBack}
        >
          Back
        </Button>
        <Group gap="sm">
          <Button
            variant="outlined"
            onClick={() => openAddToCollectionModal(item)}
          >
            Add to Collection
          </Button>
          <Button
            variant="primary"
            onClick={() => openDownloadItemModal(item)}
            disabled={!isDownloadable}
          >
            {isDownloadable === undefined
              ? 'Checking Ability to Download'
              : isDownloadable
                ? 'Download'
                : 'Download Unavailable'}
          </Button>
          <Button 
            variant="primary"
            onClick={() => openAnalyzeItemModal(item)}
            disabled={!isDownloadable}
          >
            {isDownloadable === undefined
              ? 'Checking Ability to Analyze'
              : isDownloadable
                ? 'Analyze'
                : 'Analysis Unavailable'}
          </Button>
        </Group>
      </Group>

      <Stack mt="lg">
        <SingleImageMap isFullScreen={false} item={item} />
        <Flex>
          <div style={{ flex: 1 }}>
            <DataDisplay data={item.properties} />
          </div>
          <div
            style={{
              width: '33%',
              marginLeft: '32px',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#6b6c6f',
                textTransform: 'uppercase',
              }}
            >
              Map Reference
            </Text>
            <img
              src={createStaticMapUrl({ geometry: item.geometry })}
              alt="Map"
              style={{ width: '100%', marginTop: '8px' }}
            />
          </div>
        </Flex>
      </Stack>
    </>
  )
}

export default function ItemDetail({ onGoBack }: ItemDetailProps) {
  const navigate = useNavigate()
  const { collectionId, itemId, terrapinItemId } = useParams()
  const { data: collections } = useCollections()
  const results = useAllResults()
  const [item, setItem] = React.useState<ResultWithId>()
  const addResults = resultsStore((s) => s.addResults)

  async function fetchItem(id: string) {
    const { data } = await getRequest<RawSearchResult>(`/results/${id}`)
    const result = addIdToResult(data)
    addResults([result])
    setItem(result)
  }

  React.useEffect(() => {
    let item: ResultWithId | undefined

    if (collectionId) {
      const collection = collections.find(({ id }) => id === collectionId)
      if (collection) {
        const collectionItem = collection.items.find(({ id }) => id === itemId)
        if (collectionItem) {
          item = collectionItem.doc
        }
      }
    } else if (itemId) {
      const result = results.find(({ id }) => id === itemId)
      if (result) {
        item = result
      }
    } else if (terrapinItemId) {
      void fetchItem(terrapinItemId)
    }

    if (item) {
      setItem(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, itemId])

  return item && isImageryResult(item) ? (
    <ItemDetailComponent
      item={item}
      onGoBack={onGoBack ?? (() => navigate(-1))}
    />
  ) : (
    <Text>Loading...</Text>
  )
}
