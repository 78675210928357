import type {
  AnalyticProperty,
  ImageryResult,
  ResultWithId,
} from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'

export const ZOOM_BUFFER_FACTOR = 1.25

type PinProperties = {
  resultId: string
  category: string
}

export const makeImageryResultPolygons = (
  results: ImageryResult[],
): turf.FeatureCollection<turf.Polygon | turf.Point, turf.Properties> => {
  return turf.featureCollection(
    results
      .filter((r: ImageryResult) => r.geometry.type === 'Polygon')
      .map((r: ImageryResult) =>
        turf.feature(r.geometry, { documentId: r.id }),
      ),
  )
}

export const makeAnalyticResultPolygons = (
  results: AnalyticProperty[],
): turf.FeatureCollection<turf.Polygon, turf.Properties> => {
  return turf.featureCollection(
    results
      .filter((r) => r.geometry.type === 'Polygon')
      .map((r) => turf.feature(r.geometry)),
  )
}

export const makeResultPinPoints = (results: ResultWithId[]) => {
  const features = results
    .filter((r) => r.geometry.type === 'Point')
    .map((result) =>
      turf.feature<turf.Point, PinProperties>(result.geometry as turf.Point, {
        resultId: result.id,
        category: result.category,
      }),
    )
  return turf.featureCollection(features)
}

export const makeImageryResultsBoundingBox = (
  imageResults: ImageryResult[],
) => {
  return turf.bbox(
    turf.transformScale(
      makeImageryResultPolygons(imageResults),
      ZOOM_BUFFER_FACTOR,
      { origin: 'centroid' },
    ),
  )
}

export const makeImageryResultsCentroid = (imageResults: ImageryResult[]) => {
  const polygons = makeImageryResultPolygons(imageResults)
  try {
    return turf.centroid(polygons).geometry
  } catch {
    console.error(`Error creating centroid`, { imageResults })
  }
}
