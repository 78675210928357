import { type FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { SingleAddressPanel } from '@/components/lib/single-address/SingleAddressPanel.tsx'

export const SingleAddress: FC = () => {
  const { mock } = useParams()
  return (
    <Fragment>
      <SingleAddressPanel mock={mock === '7434'} />
    </Fragment>
  )
}
