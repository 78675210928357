import { flattenCategorizedResults } from '@/hooks/results/use-all-results.ts'
import type { CategorizedResults, Results } from '@/stores/results-store.ts'
import type { ImageryResult, ResultWithId } from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'

export type ResultPredicate = (r: ResultWithId) => boolean
export const filterCategorizedResults = (
  cr: CategorizedResults,
  predicate: ResultPredicate,
): CategorizedResults => {
  return {
    imageResults: cr.imageResults.filter((r) => predicate(r)),
    dataResults: cr.dataResults.filter((r) => predicate(r)),
    publicationResults: cr.publicationResults.filter((r) => predicate(r)),
    socialMediaResults: cr.socialMediaResults.filter((r) => predicate(r)),
    insightResults: cr.insightResults,
  }
}
export const useIdFilterResults = (
  predicate: ResultPredicate,
  unfilteredResults: Results,
): Results => {
  const results = flattenCategorizedResults(
    unfilteredResults.categorized,
  ).filter((r) => predicate(r))
  const categorized = filterCategorizedResults(
    unfilteredResults.categorized,
    predicate,
  )
  const ids = results.map((r) => r.id)

  return {
    currentQueryId: unfilteredResults.currentQueryId,
    categorized,
    ids,
  }
}

export const extractCompanyName = (ir: ImageryResult) => {
  switch (ir.source) {
    case 'Planet Labs':
      return 'Planet Labs'
    case 'GEGD':
      return (ir.properties['companyName'] || '').replace(
        'DigitalGlobe',
        'MAXAR',
      )
  }
}

export const extractAssetName = (ir: ImageryResult) => {
  const source = ir.source
  if (!source) {
    return 'Unknown'
  }
  switch (source) {
    case 'Planet Labs':
      return 'Natural Color'
    case 'GEGD':
      return 'Panchromatic'
    default:
      console.warn(`Encountered unknown asset property for: ${source}`)
      Sentry.captureMessage(
        `Encountered unknown asset property for: ${source}`,
        { level: 'warning', tags: { resultId: ir.id } },
      )
      return 'Unknown'
  }
}

export const extractSensorName = (ir: ImageryResult) => {
  const sensorProperty = ir.properties['_dSensor']
  if (!sensorProperty) {
    return 'Unknown'
  }
  return sensorProperty
}
