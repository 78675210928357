import { Image } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { DantiNoResultMark } from '@/components/lib/DantiNoResultMark.tsx'
import { useThumbnailProxy } from '@/utils/data-fetching/thumbnail.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import type React from 'react'

interface ResultImageProps {
  item: ResultWithId
  style?: React.CSSProperties
  onClick?: () => void
  className?: string
}

export function ResultImage({ item, style, ...props }: ResultImageProps) {
  const { ref, entry } = useIntersection({
    rootMargin: '0px',
    threshold: 0,
  })
  const { title, thumbnail, source } = item

  const { data } = useThumbnailProxy(
    { thumbnail: thumbnail ?? '', source },
    Boolean(thumbnail) && entry?.isIntersecting,
  )

  return (
    <div
      ref={ref}
      style={{
        height: '100%',
        backgroundColor: 'var(--mantine-color-gray-2)',
      }}
    >
      {data ? (
        <Image
          alt={title}
          src={data}
          fit="cover"
          width="100%"
          height="100%"
          radius="sm"
          {...props}
          data-cy="result-image-thumbnail"
        />
      ) : (
        <DantiNoResultMark />
      )}
    </div>
  )
}
