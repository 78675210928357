import { type MutableRefObject, useEffect, useRef } from 'react'
import { makeImageryResultPolygons } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { mapStore } from '@/stores/map-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { apiUrl } from '@/utils/constants.ts'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { formatMapboxStyle } from './use-base-layer-selection.ts'

export const useMapboxMap = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapContainer: MutableRefObject<HTMLDivElement | null>,
  showMapControls?: true | undefined,
) => {
  const [baseLayer, selectedHexGridId, setHoveredHexGridId] = mapStore((s) => [
    s.baseLayer,
    s.selectedHexGridId,
    s.setHoveredHexGridId,
  ])
  const [categorized] = resultsStore((s) => [s.categorized])

  const { user } = useDantiAuth()

  const userRef = useRef(user)


  useEffect(() => {
    userRef.current = user
  }, [user])

  useEffect(() => {
    if (map.current) {
      // initialize map only once
      return
    }
    if (!mapContainer.current) {
      return
    }
    const imageResultsCenter = (
      categorized.imageResults.length > 0
        ? turf.centroid(makeImageryResultPolygons(categorized.imageResults))
            .geometry.coordinates
        : [-95.712, 37]
    ) as [number, number]

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: formatMapboxStyle(baseLayer),
      center: imageResultsCenter,
      zoom: 5,
      pitchWithRotate: false,
      touchPitch: false,
      dragRotate: false,
      touchZoomRotate: false,
      transformRequest: (urlString, resourceType) => {
        const url = new URL(urlString)
        const apiUrlUrl = new URL(apiUrl)
        if (resourceType === 'Tile' && url.origin === apiUrlUrl.origin) {
          return {
            url: urlString,
            headers: {
              Authorization: `Bearer ${userRef.current?.access_token}`,
              Accept: 'image/png',
            },
          }
        }
        return { url: urlString }
      },
    })

    {
      showMapControls &&
        map.current.addControl(
          new mapboxgl.NavigationControl({
            visualizePitch: false,
            showCompass: false,
          }),
          'bottom-left',
        )
    }

    map.current.doubleClickZoom.disable()

    map.current.loadImage(
      'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
      (error, result) => {
        if (result) {
          map.current?.addImage('custom-marker', result)
        }
        if (error) {
          throw error
        }
      },
    )

  }, [
    baseLayer,
    map,
    mapContainer,
    selectedHexGridId,
    setHoveredHexGridId,
    categorized.imageResults,
  ])
}
