import { Box, Text } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'

export interface ExploreSuggestionRowProps {
  suggestions: string[]
  onSelect: (suggestiion: string) => void
}

export function ExploreSuggestionRow({
  suggestions,
  onSelect,
}: ExploreSuggestionRowProps) {
  const { width } = useViewportSize()

  return (
    <Box w={width - 50} style={{ maxWidth: '932px' }}>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflowX: 'scroll',
          display: 'flex',
          gap: '10px',
        }}
      >
        {suggestions.map((x) => (
          <Text
            key={x}
            style={{
              color: '#50555C',
              fontSize: '10px',
              background: '#F5F5F5',
              padding: 8,
              paddingLeft: 12,
              paddingRight: 12,
              borderRadius: '12px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => onSelect(x)}
          >
            {x}
          </Text>
        ))}
      </div>
    </Box>
  )
}
