import React, { type PropsWithChildren } from 'react'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { userStore } from '@/stores/user-store.ts'
import { oidcAuthority, oidcClientId, oidcRedirectUri } from '@/utils/constants'
import {
  axiosAuthInterceptor,
  axiosClient,
  setUserAuthForFetch,
} from '@/utils/data-fetching/config.ts'
import { WebStorageStateStore } from 'oidc-client-ts'
import { AuthProvider, type AuthProviderProps } from 'react-oidc-context'

const oidcConfig: AuthProviderProps =
  oidcAuthority && oidcClientId && oidcRedirectUri
    ? {
        authority: oidcAuthority,
        // eslint-disable-next-line camelcase
        client_id: oidcClientId,
        // eslint-disable-next-line camelcase
        redirect_uri: oidcRedirectUri,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        onSigninCallback: (user) => {
          const setProfile = userStore((state) => state.setProfile)
          if (user) {
            setProfile(user)
          }
        },
        onRemoveUser: () => {
          window.location.pathname = '/log-in'
        },
      }
    : {}

function AuthListener({ children }: PropsWithChildren) {
  const { isLoading, isAuthenticated, user } = useDantiAuth()
  const setProfile = userStore((state) => state.setProfile)
  const [authInterceptor, setAuthInterceptor] = React.useState<
    number | undefined
  >(axiosAuthInterceptor)

  React.useEffect(() => {
    if (!isLoading) {
      if (authInterceptor !== undefined) {
        axiosClient.interceptors.request.eject(authInterceptor)
      }
      if (user) {
        console.groupCollapsed('Setting auth user:')
        console.dir(user)
        console.groupEnd()
        setProfile(user)

        setAuthInterceptor(setUserAuthForFetch(user.access_token))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, user])

  return isLoading ? <span>Loading...</span> : children
}

export const DantiAuthProvider = ({ children }: PropsWithChildren) => (
  <AuthProvider {...oidcConfig}>
    <AuthListener>{children}</AuthListener>
  </AuthProvider>
)
