import React from 'react'
import {
  type LinkProps,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Avatar,
  Badge,
  Box,
  Button,
  em,
  Group,
  Menu,
  Paper,
  Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { DantiWordMark } from '@/components/lib/DantiWordMark.tsx'
import { Icon } from '@/components/lib/Icon.tsx'
import { useClearAll } from '@/hooks/use-clear-all.ts'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { userStore } from '@/stores/user-store.ts'
import type { SymbolName } from '@/utils/types/material-icons'
import { classes } from '@/utils/ui-helpers.tsx'

import styles from './header.module.css'

interface NavItem {
  icon: SymbolName
  label: string
}

const rightNavItems: NavItem[] = [
  {
    icon: 'person',
    label: 'My Account',
  },
  {
    icon: 'question_mark',
    label: 'Help Center',
  },
  {
    icon: 'admin_panel_settings',
    label: 'Admin',
  },
  {
    icon: 'logout',
    label: 'Sign Out',
  },
]

function HeaderLink({ to, ...props }: LinkProps) {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
  if (isMobile) {
    return <></>
  }

  return (
    <>
      <NavLink
        to={to}
        title={props.children?.toString()}
        {...props}
        className={({ isActive }) =>
          classes(styles['link'], isActive && styles['active'])
        }
      />
    </>
  )
}

export function Header() {
  const navigate = useNavigate()
  const { logIn } = useDantiAuth()
  const { pathname } = useLocation()

  const [avatarBackgroundColor, userInitials] = userStore((state) => [
    state.avatarBackgroundColor,
    state.firstName.charAt(0) + state.lastName.charAt(0),
  ])
  const [currentQuery, currentQueryStart] = queryStore((state) => [
    state.currentQuery,
    state.currentQueryStart,
    state.recentQueries,
  ])
  const currentQueryId = resultsStore((state) => state.currentQueryId)
  const clearAll = useClearAll()

  const [overviewUrl, setOverviewUrl] = React.useState('/')

  const isLogIn = pathname === '/log-in'

  React.useEffect(() => {
    if (currentQuery || currentQueryStart || currentQueryId) {
      setOverviewUrl(
        `/overview?${new URLSearchParams({
          search: currentQuery,
        }).toString()}`,
      )
    } else {
      setOverviewUrl('/')
    }
  }, [currentQuery, currentQueryStart, currentQueryId])

  return (
    <Paper
      pos="fixed"
      bg="#000b"
      fz={14}
      top={0}
      left={0}
      right={0}
      shadow="xs"
      radius={0}
      style={{ zIndex: 3 }}
    >
      <header className={styles['header']}>
        <Group justify="space-between" py="0.25rem" w="100%">
          <Group h="100%" gap="sm">
            <DantiWordMark
              height={29}
              style={{
                marginRight: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                clearAll()
                navigate('/')
              }}
              color="white"
            />
            {!isLogIn && (
              <Group gap={5}>
                <HeaderLink to={overviewUrl}>
                  <Group gap={5}>
                    <Icon name="search" style={{ color: 'white' }} />
                    <Text>Explore</Text>
                  </Group>
                </HeaderLink>
                <HeaderLink to="/map">
                  <Group gap={5}>
                    <Icon name="map" style={{ color: 'white' }} />
                    <Text>Map</Text>
                  </Group>
                </HeaderLink>
                <HeaderLink to="/collections">
                  <Group gap={5}>
                    <Icon
                      name="collections_bookmark"
                      style={{ color: 'white' }}
                    />
                    <Text>Collections</Text>
                  </Group>
                </HeaderLink>
                <HeaderLink to="/history">
                  <Group gap={5}>
                    <Icon name="history" style={{ color: 'white' }} />
                    <Text>History</Text>
                  </Group>
                </HeaderLink>
              </Group>
            )}
          </Group>
          {isLogIn ? (
            <Button variant="primary" onClick={logIn}>
              Log In
            </Button>
          ) : (
            <>
              <Group justify="center" gap={30}>
                <Box style={{ position: 'relative', top: 4 }}>
                  <Icon
                    name="notifications"
                    style={{ color: 'white' }}
                    size={25}
                  />
                  <Badge
                    size="xs"
                    circle
                    color="red"
                    style={{ position: 'absolute', top: -4, right: -4 }}
                  >
                    1
                  </Badge>
                </Box>

                <Menu
                  trigger="click"
                  transitionProps={{ duration: 100 }}
                  position="bottom-end"
                >
                  <Menu.Target>
                    <Avatar
                      size="30px"
                      variant="filled"
                      color={avatarBackgroundColor}
                      radius="50%"
                    >
                      {userInitials}
                    </Avatar>
                  </Menu.Target>
                  <Menu.Dropdown
                    bg="transparent"
                    styles={{
                      dropdown: {
                        border: 'none',
                        backdropFilter: 'blur(1.5px)',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                      },
                    }}
                  >
                    <Paper
                      bg="rgba(0, 0, 0, 0.733)"
                      p="5px"
                      style={{ borderRadius: '10px' }}
                    >
                      {rightNavItems.map(({ label, icon }) => {
                        return (
                          <Menu.Item
                            key={label}
                            // onClick={() => void handleSetQuery(query)}
                            c="white"
                            classNames={{ item: styles['menuItem'] }}
                          >
                            <Group gap={6}>
                              <Icon name={icon} style={{ color: 'white' }} />
                              <Text fs="18px">{label}</Text>
                            </Group>
                          </Menu.Item>
                        )
                      })}
                    </Paper>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </>
          )}
        </Group>
      </header>
    </Paper>
  )
}
