import { type MutableRefObject, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { MapPointInfoTooltip } from '@/components/lib/map-search/MapPointInfoTooltip.tsx'
import { useAllResults } from '@/hooks/results/use-all-results.ts'
import { mapStore } from '@/stores/map-store.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import mapboxgl from 'mapbox-gl'

export const usePointData = (map: MutableRefObject<mapboxgl.Map | null>) => {
  const [selectedPointId] = mapStore((s) => [s.selectedPointId])
  const results = useAllResults()

  useEffect(() => {
    if (!selectedPointId) {
      return
    }
    const result = results.find(
      (result: ResultWithId) => result.id === selectedPointId,
    )
    if (!result || !selectedPointId) {
      return
    }
    const coords = result?.geometry?.coordinates as [number, number]
    const makeTooltip = () => {
      const div = document.createElement('div')
      const root = createRoot(div)
      root.render(MapPointInfoTooltip({ result }))
      return div
    }

    if (map.current) {
      new mapboxgl.Popup({ closeButton: false })
        .setLngLat(coords)
        .setDOMContent(makeTooltip())
        .addTo(map.current)
    }
  }, [map, results, selectedPointId])
}
