import { Fragment } from 'react'
import MapSearchPanel from '@/components/lib/map-search/MapSearchPanel.tsx'

export default function MapSearch() {
  return (
    <Fragment>
      <MapSearchPanel />
    </Fragment>
  )
}
