import {
  Button,
  px,
  Spoiler,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon.tsx'
import type { InsightResult } from '@/utils/types/result-types.ts'
import { wordBreak } from '@/utils/ui-helpers.tsx'
import ReactMarkdown from 'react-markdown'

interface InsightsPanelProps {
  result: InsightResult
  isEmbedded?: boolean
}

export function InsightsPanel({ result, isEmbedded }: InsightsPanelProps) {
  const [isOpen, { toggle }] = useDisclosure()
  const theme = useMantineTheme()
  const spoilerHeight =
    Number(theme.lineHeights.md) * (theme.scale * Number(px('1rem'))) * 3 - 1

  return (
    <Stack
      gap="sm"
      p={isEmbedded ? 'md' : 0}
      style={
        isEmbedded
          ? { border: '1px solid var(--mantine-color-gray-2)', borderRadius: 4 }
          : {}
      }
    >
      {isEmbedded && (
        <>
          <Text fz="xs" c="gray.7" fw={700} lh={1} mt={0} tt="uppercase">
            Answer
          </Text>
          <Text fz={48} fw={700} lh={1} lineClamp={3}>
            {wordBreak(result.title)}
          </Text>
        </>
      )}
      <Spoiler
        fz={14}
        m={0}
        mb="xl"
        data-cy="insights-panel"
        showLabel={
          <Button
            component="a"
            variant="transparent"
            c="gray.7"
            size="sm"
            onClick={toggle}
            p={0}
            mt="xs"
          >
            Read More
            <Icon name="arrow_drop_down" />
          </Button>
        }
        hideLabel={
          <Button
            component="a"
            variant="transparent"
            c="gray.7"
            size="sm"
            onClick={() => setTimeout(toggle, 200)}
            p={0}
            mt="xs"
          >
            Hide
            <Icon name="arrow_drop_up" />
          </Button>
        }
        transitionDuration={200}
        maxHeight={spoilerHeight}
      >
        <Text component="div" span fz={16} lineClamp={isOpen ? 0 : 3}>
          <ReactMarkdown>{result.description}</ReactMarkdown>
        </Text>
      </Spoiler>
    </Stack>
  )
}
