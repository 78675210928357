import {
  extractAssetName,
  extractCompanyName,
  extractSensorName,
} from '@/hooks/results/result-filter-utilities.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { resultsFilterStore } from '@/stores/results-filter-store.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'

export const allPostFilterTypes = [
  'source',
  'repo',
  'asset',
  'sensor',
  'quality',
  'cloud',
] as const
export type PostFilterType = (typeof allPostFilterTypes)[number]

type UsePostfilterImageResultsProps = {
  excludeType?: PostFilterType
}
export const usePostfilterImageResults = ({
  excludeType,
}: UsePostfilterImageResultsProps = {}) => {
  const {
    categorized: { imageResults },
  } = useTimelineFilteredResults()
  const [
    omittedSources,
    omittedRepos,
    omittedAssets,
    omittedSensors,
    qualityCeil,
    cloudCeil,
  ] = resultsFilterStore((s) => [
    s.omittedSources,
    s.omittedRepos,
    s.omittedAssets,
    s.omittedSensors,
    s.qualityCeil,
    s.cloudCeil,
  ])

  const predicateByType: {
    [key in PostFilterType]: (ir: ImageryResult) => boolean
  } = {
    source: (ir) => !omittedSources.includes(extractCompanyName(ir) || ''),
    repo: (ir) => !omittedRepos.includes(ir.source),
    asset: (ir) => !omittedAssets.includes(extractAssetName(ir) || ''),
    sensor: (ir) => !omittedSensors.includes(extractSensorName(ir)),
    quality: (ir) => {
      const q = ir.properties._dQuality
      if (!q) {
        return true //Don't filter out images that aren't right
      }

      switch (qualityCeil) {
        case 1:
          return q <= 0.5
        case 2:
          return q <= 1
        case 3:
          return q <= 3
      }
      return true
    },
    cloud: (ir) => {
      const c = ir.properties._dCloudCover
      if (!c) {
        return true
      }

      switch (cloudCeil) {
        case 1:
          return c <= 20
        case 2:
          return c <= 40
        case 3:
          return c <= 60
        case 4:
          return c <= 80
      }
      return true
    },
  }

  const filterTypes = allPostFilterTypes.filter((ft) => ft !== excludeType)

  return filterTypes.reduce((filteredResults, currentFilterType) => {
    return filteredResults.filter(predicateByType[currentFilterType])
  }, imageResults)
}
