import { useMemo } from 'react'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  lat2tile,
  lon2tile,
  tile2lat,
  tile2long,
} from '@/utils/coordinate-conversion.ts'
import { regridSample7434 } from '@/utils/types/single-address/__tests__/sample-data/regrid-sample-7434.ts'
import {
  isRegridResult,
  parseRegridAddress,
} from '@/utils/types/single-address/regrid-types.ts'
import { matchesRawAddress } from '@/utils/us-address-utils.ts'
import * as turf from '@turf/turf'
import type { Polygon } from 'geojson'

export interface ImageTileLayerConfig {
  tileX: number
  tileY: number
  coordinates: number[][]
}
export const useRegrid = (isMock: boolean) => {
  const dataResults = resultsStore((s) => s.categorized.dataResults)
  const currentQuery = queryStore((s) => s.currentQuery)
  const regridResult = useMemo(
    () =>
      isMock
        ? regridSample7434
        : dataResults
            .filter(isRegridResult)
            .find((r) =>
              matchesRawAddress(currentQuery, parseRegridAddress(r.properties)),
            ),
    [currentQuery, isMock, dataResults],
  )

  const regridRecord = useMemo(() => regridResult?.properties, [regridResult])

  const parcelPolygon = useMemo<Polygon | undefined>(
    () => regridResult?.geometry as Polygon,
    [regridResult],
  )

  const makeTileLayerOptions = (z: number) => {
    if (!parcelPolygon) {
      return []
    }
    const [minX, minY, maxX, maxY] = turf.bbox(parcelPolygon)
    const xRange = [lon2tile(minX, z), lon2tile(maxX, z)]
    const yRange = [lat2tile(minY, z), lat2tile(maxY, z)]

    const imageConfigs: ImageTileLayerConfig[] = []
    for (let x = xRange[0]; x <= xRange[1]; x++) {
      for (let y = yRange[0]; y >= yRange[1]; y--) {
        imageConfigs.push({
          tileX: x,
          tileY: y,
          coordinates: [
            [tile2long(x, z), tile2lat(y, z)],
            [tile2long(x + 1, z), tile2lat(y, z)],
            [tile2long(x + 1, z), tile2lat(y + 1, z)],
            [tile2long(x, z), tile2lat(y + 1, z)],
          ],
        })
      }
    }

    return imageConfigs
  }

  return { regridResult, regridRecord, parcelPolygon, makeTileLayerOptions }
}
