import { Group } from '@mantine/core'
import { StatusBall } from '@/components/lib/StatusBall.tsx'
import { useAllResults } from '@/hooks/results/use-all-results.ts'
import { queryStore } from '@/stores/queries-store.ts'
import {
  DantiImageSourceKeys,
  QuerySourceKeys,
} from '@/utils/types/message-types.ts'

const makeNiceSourceName = (source: QuerySourceKeys): string => {
  switch (source) {
    case QuerySourceKeys.ATTOM:
      return 'Attom'
    case QuerySourceKeys.PLANET:
      return 'Planet'
    case QuerySourceKeys.HAZARD:
      return 'Hazard'
    case QuerySourceKeys.VEXCEL:
      return 'Vexcel'
    case QuerySourceKeys.MAXAR:
      return 'Maxar'
    case QuerySourceKeys.NEARSPACE:
      return 'NearSpace'
    case QuerySourceKeys.NUDL:
      return 'NUDL'
    case QuerySourceKeys.GEGD:
      return 'GEGD'
    case QuerySourceKeys.DANTI_IMAGE:
      return 'Danti Image Search'
    case QuerySourceKeys.DANTI_WEB:
      return 'Danti Web Search'
    case QuerySourceKeys.DANTI_DATA:
      return 'Danti Data Search'
    case QuerySourceKeys.DANTI_ID_SEARCH:
      return 'Danti ID Search'
    case QuerySourceKeys.THOTH:
      return 'Danti AI Search'
  }

  return source
}

const dataSourceToStatusSource = (dataSource: string): string => {
  // Most data sources match the QuerySourceKeys already
  const exceptionMap: { [key: string]: QuerySourceKeys } = {
    'Planet Labs': QuerySourceKeys.PLANET,
    RSS: QuerySourceKeys.DANTI_WEB,
  }
  return exceptionMap[dataSource] || dataSource
}

type QueryStatusDisplayProps = {
  onlySources?: QuerySourceKeys[]
}
export function QueryStatusDisplay(props: QueryStatusDisplayProps) {
  const queryStatus = queryStore((s) => s.queryStatus)
  const results = useAllResults()

  const sourceKeys = props.onlySources
    ? Object.entries(queryStatus).filter(
        ([key]) => props.onlySources && props.onlySources.includes(key),
      )
    : Object.entries(queryStatus)

  return (
    <Group gap={8}>
      {sourceKeys
        .sort(([aKey], [bKey]) =>
          makeNiceSourceName(aKey as QuerySourceKeys).localeCompare(
            makeNiceSourceName(bKey as QuerySourceKeys),
          ),
        )
        .map(([key, state]) => {
          const sourceCount = results.filter(
            (r) => dataSourceToStatusSource(r.source) === key,
          ).length

          const actualState = Object.values(DantiImageSourceKeys).includes(key)
            ? queryStatus['DANTI_IMAGE']
            : state

          return {
            label:
              `${makeNiceSourceName(key as QuerySourceKeys)}` +
              (sourceCount > 0 ? ` (${sourceCount})` : ''),
            actualState,
          }
        })
        .map(({ label, actualState }, index) => {
          return (
            <StatusBall
              key={`box_${index}`}
              label={label}
              state={actualState}
            />
          )
        })}
    </Group>
  )
}
