import { getRequest, getRequestNoAuth } from '@/utils/data-fetching/config.ts'
import { captureException } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import type { AxiosRequestConfig } from 'axios'
import { apiUrl } from '../constants'

interface ImageryResultPart {
  thumbnail: string | URL
  source: string
}

/**
 * Set of hostnames that require authentication using the provider proxy.
 */
const PROXY_REQUIRED = new Set<string>([
  'tiles.planet.com',
  'api.gic.org',
  'eapi.maxar.com',
  'e1so.eapi.maxar.com',
  'api.content.maxar.com',
  'nudl-tst.xc.nga.mil',
  'api.nearspacelabs.net',
])

/**
 * Lookup map from result provider names to provider proxy path prefixes.
 */
const SOURCE_MAP = new Map<string, string>([
  ['Planet Labs', 'planet'],
  ['VEXCEL', 'vexcel'],
  ['MAXAR', 'maxar'],
  ['NUDL', 'nudl'],
  ['Danti Analytic', 'analytic'],
  ['NEARSPACE', 'nearspace'],
  ['G-EGD', 'gegd'],
])

/**
 * Lookup map from hostname to provider proxy path prefix based on Maxar API.
 */
const MAXAR_API_SOURCE_MAP = new Map<string, string>([
  ['eapi.maxar.com', 'e1so'],
  ['e1so.eapi.maxar.com', 'e1so'],
  ['api.content.maxar.com', 'api'],
])

/**
 * Regular expression to extract the item ID from the Maxar Geospatial Platform
 * catalog api. The hostname for this API is `api.content.maxar.com`.
 */
const MAXAR_MGP_PATH = new RegExp(
  /\/catalog\/collections\/.+\/items\/([\da-z-]+)\//,
  'i',
)

/**
 * Regular expression to extract the item ID from the Nearspace provider api.
 * The hostname for this API is `api.nearspacelabs.net`
 */
const NEARSPACE_CATALOG_ID_MATCH = new RegExp(
  /\/tile\/basemap\/([\dT_/]+)/,
  'i',
)

/**
 * Regular expression to extract the mime-type from a Content-Type header.
 */
const MIME = new RegExp(/^\s*([^\s;]*)/)

interface AnalyticResponse {
  url: string
}

async function getProxiedUrl(params: {
  thumbnail: string | URL
  source: string
}): Promise<string | undefined> {
  // check if this item should be proxied
  const url = new URL(params.thumbnail)
  if (!PROXY_REQUIRED.has(url.hostname) && params.source != 'Danti Analytic') {
    return url.toString()
  }

  const source = params.source.startsWith('NUDL ') ? 'NUDL' : params.source
  // pull the correct proxy path based on the provider
  let provider = SOURCE_MAP.get(source)
  if (!provider) {
    console.error(`couldn't find provider for ${source}`)
    return undefined
  }

  if (provider == 'maxar') {
    const apiVersion = MAXAR_API_SOURCE_MAP.get(url.hostname)
    if (!apiVersion) {
      return undefined
    }

    // FIXME: for now attempt translate the Maxar MGP API URL to a eAPI URL using the item ID
    //  the provier proxy does not currently support api.content.maxar.com
    provider += '/e1so' // FIXME: replace with: provider += `/${apiVersion}`
    if (apiVersion === 'api') {
      const results = MAXAR_MGP_PATH.exec(url.pathname)
      if (results && results[1]) {
        url.pathname = `/e1so/collections/wv04/items/${results[1]}/assets/browse.tif`
      }
    }
  } else if (provider == 'analytic') {
    const signingUrl = new URL(apiUrl)
    signingUrl.pathname = `/provider/${provider}${url.pathname}`
    signingUrl.search = url.search

    const response = await getRequest<AnalyticResponse>(signingUrl.toString())

    return response.data.url
  } else if (provider == 'vexcel') {
    const thumbnailUrl = new URL(apiUrl)

    // The ortho proxy works slightly differently than the others, since the args are mostly in the path
    // rather than the search string
    thumbnailUrl.pathname = `/provider/vexcel${url.pathname}`
    thumbnailUrl.search = url.search
    return thumbnailUrl.toString()
  } else if (provider == 'nearspace') {
    const results = NEARSPACE_CATALOG_ID_MATCH.exec(url.pathname)
    if (results && results[1]) {
      url.pathname = `${results[1]}`
    }
  }

  // rewrite the thumbnail url to the proxy provider hostname and path prefix
  const thumbnailUrl = new URL(apiUrl)
  thumbnailUrl.pathname = `/provider/${provider}${url.pathname}`
  thumbnailUrl.search = url.search

  return thumbnailUrl.toString()
}

async function getDataUrlFromProxy(thumbnailUrl: string) {
  const get = thumbnailUrl.includes('amazonaws.com')
    ? getRequestNoAuth
    : getRequest
  const config: AxiosRequestConfig = {
    responseType: 'arraybuffer',
  }

  if (
    thumbnailUrl.includes('provider/nudl') ||
    thumbnailUrl.includes('provider/maxar') ||
    thumbnailUrl.includes('provider/gegd')
  ) {
    config.headers = {
      Accept: 'image/png',
    }
  }
  const response = await get<ArrayBufferLike>(thumbnailUrl, config)
  // grab the mime-type so that we know how to handle the data url
  const contentType = response.headers['content-type'] as string
  const mimeType = MIME.exec(contentType)
  if (!mimeType) {
    return
  }
  let base64
  switch (mimeType[1]) {
    case 'image/tiff':
      console.log("sending a tiff to the browser, this probably won't work")
      return
    case 'image/png':
    case 'image/jpeg':
      try {
        const dataArray = new Uint8Array(response.data as ArrayBuffer)
        const dataString = dataArray.reduce(
          (data, byte) => data + String.fromCodePoint(byte),
          '',
        )
        base64 = window.btoa(dataString)
      } catch (error) {
        console.error('Failed to parse image data', { e: error })
        captureException(error)
      }
      return new URL(`data:${mimeType[1]};base64,${base64}`).toString()
  }
}

async function getThumbnailDataUrl(item: ImageryResultPart) {
  if (!item || !item.thumbnail) {
    return
  }

  const thumbnailUrl = (await getProxiedUrl(item)) ?? ''
  if (!thumbnailUrl) {
    return
  }

  return getDataUrlFromProxy(thumbnailUrl)
}

export const getProxiedDataUrl = async (params: {
  thumbnail: string | URL
  source: string
}) => {
  const proxiedUrl = await getProxiedUrl(params)
  if (!proxiedUrl) {
    return
  }
  return getDataUrlFromProxy(proxiedUrl)
}

export function useThumbnailProxy(
  item: ImageryResultPart,
  fetchCondition = false,
) {
  return useQuery({
    queryKey: ['thumbnails', item.thumbnail],
    queryFn: async () => {
      return getThumbnailDataUrl(item)
    },
    retry: false,
    enabled: fetchCondition,
  })
}
