import React, { type ReactElement } from 'react'

/**
 * Breaks a snake-case string into words and inserts <wbr> tags between them
 * Useful for long snake-case titles that would otherwise overflow their container
 * @param title
 * @example wordBreak('this_is_a_really_long_snake_case_title')
 */
export function wordBreak(title: string): ReactElement[] | string {
  const titleWords = title.split('_')
  return (
    titleWords?.reduce((elements: ReactElement[], word, index) => {
      elements.push(
        <React.Fragment key={word + index}>
          {word}
          {index < titleWords.length - 1 ? '_' : ''}
        </React.Fragment>,
      )
      if (index < titleWords.length - 1) {
        elements.push(<wbr key={word + index + 'break'} />)
      }

      return elements
    }, []) ?? title
  )
}

// Concat a list of conditional classNames into a single string
export function classes(
  ...args: Array<string | boolean | null | undefined>
): string {
  return args.filter(Boolean).join(' ')
}

interface Unit {
  value: number
  unit: string
}

export function parseUnit(numberish: string | number): Unit {
  let value = numberish as number
  let unit = 'px'
  if (typeof numberish === 'string') {
    unit = numberish.match(/(px|em|rem|pt|%)$/)?.[0] ?? 'px'
    value = Number(numberish.replace(unit, ''))
  }
  return { value, unit }
}

interface Ratio {
  width: string | number
  height: string | number
}

export function maintainRatio(
  { width, height }: Partial<Ratio>,
  x: number,
  y: number,
): Ratio {
  const xRatio = x / y
  const yRatio = y / x
  const finalRatio = { width: width ?? 0, height: width ?? 0 }

  const { value, unit } = parseUnit(width ?? height ?? 0)

  let normalizedValue = value

  switch (unit) {
    case '%':
      finalRatio.width = width ?? `${value}%`
      finalRatio.height = height ?? `${value}%`
      break
    case 'em':
    case 'rem':
      normalizedValue = value * 16
      break
    default:
      break
  }

  if (unit !== '%') {
    if (width && !height) {
      finalRatio.width = normalizedValue
      finalRatio.height = normalizedValue * yRatio
    } else if (!width && height) {
      finalRatio.width = normalizedValue * xRatio
      finalRatio.height = normalizedValue
    }
  }

  return finalRatio
}
