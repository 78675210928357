import { useState } from 'react'
import { Avatar, Center, Group, Paper, Text } from '@mantine/core'
import { FilterToggleButton } from '@/components/filters/FilterToggleButton.tsx'
import { MemoFilterPanel } from '@/components/lib/FilterPanel.tsx'
import { queryStore } from '@/stores/queries-store.ts'
import { userStore } from '@/stores/user-store.ts'

import styles from '@/components/layout/queryHeader.module.css'

export function QueryHeader(props: { fixed?: boolean }) {
  const [avatarBackgroundColor, userInitials] = userStore((state) => [
    state.avatarBackgroundColor,
    state.firstName.charAt(0) + state.lastName.charAt(0),
    state.email,
  ])
  const currentQuery = queryStore((state) => state.currentQuery)
  const [isFilterExpanded, setIsFilterExpanded] = useState(false)

  return (
    <Paper
      pos={props.fixed ? 'fixed' : 'relative'}
      w={props.fixed ? '100%' : 'calc(100% + (2 * var(--mantine-spacing-xl)))'}
      bg="var(--inherit-white-main, #FFF)"
      bottom="1px solid var(--divider, rgba(0, 0, 0, 0.12))"
      fz={14}
      shadow="xs"
      radius={0}
      mx="-xl"
      mt="-lg"
      style={{ zIndex: props.fixed ? 1 : 0 }}
      data-cy="query-header"
    >
      <header className={styles['queryHeader']}>
        <Group justify="space-between" py="0.25rem" w="100%">
          <Group h="100%" gap="5">
            <Center style={{ cursor: 'pointer' }} inline>
              <Avatar
                variant="filled"
                color={avatarBackgroundColor}
                radius="50%"
                size={25}
              >
                {userInitials}
              </Avatar>
            </Center>
            <Center pt={18} pb={18}>
              <Text
                fz={20}
                fw={400}
                c="var(--text-primary, rgba(0, 0, 0, 0.87))"
                data-cy="query-header-query"
              >
                {currentQuery.length > 83
                  ? currentQuery.slice(0, 80) + '...'
                  : currentQuery}
              </Text>
            </Center>
          </Group>
          <Group>
            <FilterToggleButton
              isFilterOpen={isFilterExpanded}
              onClick={() => setIsFilterExpanded((state) => !state)}
              data-cy="query-header-filter-toggle"
            />
          </Group>
        </Group>
      </header>
      <MemoFilterPanel
        isOpen={isFilterExpanded}
        data-cy="query-header-filter-panel"
      />
    </Paper>
  )
}
