import { Group } from '@mantine/core'
import { StatusBall } from '@/components/lib/StatusBall.tsx'
import type { PropertyData } from '@/hooks/use-single-address-components.ts'
import { queryStore } from '@/stores/queries-store.ts'
import type { VexcelOrthoCoverageAoi } from '@/utils/data-fetching/vexcel-ortho-coverage.ts'
import { QuerySourceState } from '@/utils/types/message-types.ts'
import type { Polygon } from 'geojson'
import type { FC } from 'react'

type StatusDisplayProps = {
  propertyData: PropertyData
  parcelPolygon: Polygon | undefined
  orthoCoverage: VexcelOrthoCoverageAoi[]
}

const makeStateForReport = (
  sourceStatus: QuerySourceState,
  record: any | undefined,
) => {
  return [QuerySourceState.NOT_STARTED, QuerySourceState.ERROR].includes(
    sourceStatus,
  )
    ? sourceStatus
    : record
    ? QuerySourceState.COMPLETED
    : QuerySourceState.IN_PROGRESS
}
export const StatusDisplay: FC<StatusDisplayProps> = ({
  propertyData,
  orthoCoverage,
}) => {
  const queryStatus = queryStore((s) => s.queryStatus)

  const attomPropertyState = makeStateForReport(
    queryStatus['ATTOM'],
    propertyData.attomProperty,
  )
  const attomPermitState = makeStateForReport(
    queryStatus['ATTOM'],
    propertyData.attomPermit,
  )

  const hazardRiskState = makeStateForReport(
    queryStatus['HAZARD'],
    propertyData.hazardRisk,
  )
  const hazardPropertyState = makeStateForReport(
    queryStatus['HAZARD'],
    propertyData.hazardProperty,
  )
  const regridState = makeStateForReport(
    queryStatus['REGRID'],
    propertyData.regrid,
  )
  const vexcelState = makeStateForReport(queryStatus['VEXCEL'], orthoCoverage)

  return (
    <Group gap={8}>
      <StatusBall label="Attom Permit" state={attomPermitState} />
      <StatusBall label="Attom Property" state={attomPropertyState} />
      <StatusBall label="Hazard Property" state={hazardPropertyState} />
      <StatusBall label="Hazard Risk" state={hazardRiskState} />
      <StatusBall label="Regrid" state={regridState} />
      <StatusBall label="Vexcel" state={vexcelState} />
    </Group>
  )
}
