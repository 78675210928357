import React from 'react'
import { Flex, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { Slider } from '@/components/lib/Slider.tsx'
import { filtersStore, setFilters } from '@/stores/filters-store.ts'

const cloudCoverSteps = Array.from({ length: 11 }).map((_, index) => ({
  label: `${index * 10}%`,
  value: index * 10,
}))

export function CloudCover() {
  const cloudCover = filtersStore((state) => state.cloudCover)
  const [cloudCoverValue, setCloudCoverValue] = React.useState(cloudCover)

  React.useEffect(() => {
    setCloudCoverValue(cloudCover)
  }, [cloudCover])

  return (
    <Stack gap="xs" mt="-1.625rem">
      <Text ml="auto" size="xs" c="#3BADED">
        {`${cloudCoverValue}% or Less`}
      </Text>
      <Flex justify="space-between">
        <Icon name="wb_sunny" />
        <Icon name="wb_cloudy" />
      </Flex>
      <Slider
        min={0}
        max={100}
        step={10}
        marks={cloudCoverSteps}
        value={cloudCoverValue}
        label={(clouds) => `${clouds}%`}
        aria-label="Cloud Cover"
        onChange={(value) => setCloudCoverValue(value)}
        onChangeEnd={(value) => setFilters({ cloudCover: value })}
      />
    </Stack>
  )
}
