import { Box, Button } from '@mantine/core'
import type { FC, MouseEventHandler } from 'react'

type MapSwitchToListButtonProps = {
  onClick: MouseEventHandler
}

export const MapSwitchToListButton: FC<MapSwitchToListButtonProps> = ({
  onClick,
}) => {
  return (
    <Box style={{ position: 'absolute', bottom: 25, left: '50%' }}>
      <Button
        variant="contained"
        onClick={onClick}
        size="large"
        //endIcon={<List />}
      >
        Show List
      </Button>
    </Box>
  )
}
