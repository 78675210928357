import type { RawSearchResult } from '@/utils/types/result-types.ts'

// Pre-ingested data sources
export enum DantiQuerySourceKeys {
  DANTI_DATA = 'DANTI_DATA',
  DANTI_ID_SEARCH = 'DANTI_ID-SEARCH',
  DANTI_IMAGE = 'DANTI_IMAGE',
  DANTI_WEB = 'DANTI_WEB',
}

export enum DantiImageSourceKeys {
  GEGD = 'GEGD',
}

export enum EventDrivenQuerySourceKeys {
  NUDL = 'NUDL',
  PLANET = 'PLANET',
  MAXAR = 'MAXAR',
  VEXCEL = 'VEXCEL',
  ATTOM = 'ATTOM',
  HAZARD = 'HAZARD',
  NEARSPACE = 'NEARSPACE',
  REGRID = 'REGRID',
}

// Just Thoth for now
export enum InsightQuerySourceKeys {
  THOTH = 'THOTH',
}

export const QuerySourceKeys = {
  ...DantiQuerySourceKeys,
  ...DantiImageSourceKeys,
  ...EventDrivenQuerySourceKeys,
  ...InsightQuerySourceKeys,
} as const

export type QuerySourceKeys =
  (typeof QuerySourceKeys)[keyof typeof QuerySourceKeys]

export enum QuerySourceState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export type QueryStatus = { [key in QuerySourceKeys]: QuerySourceState }

interface ResultMessage {
  event: 'result'
  queryId: string
  results: RawSearchResult[]
}

export interface StatusMessage {
  event: 'status'
  queryId: string
  source: QuerySourceKeys
  state: QuerySourceState
  progress: { search: number }
}

interface ChatResponse {
  event: 'chatResponse'
  queryId: string
  messageId: string
  message: string
}

interface ChatRequest {
  action: 'chatRequest'
  event: 'chatRequest'
  queryId: string
  prompt: string
}

export type ChatMessage = ChatResponse | ChatRequest

export type SocketMessage = ResultMessage | StatusMessage | ChatMessage

export function isResultMessage(
  message: SocketMessage,
): message is ResultMessage {
  return message && 'event' in message && message.event === 'result'
}

export function isStatusMessage(
  message: SocketMessage,
): message is StatusMessage {
  return message && 'event' in message && message.event === 'status'
}

export function isChatResponse(
  message: SocketMessage,
): message is ChatResponse {
  return message && 'event' in message && message.event === 'chatResponse'
}

export function isChatRequest(message: SocketMessage): message is ChatRequest {
  return message && 'action' in message && message.action === 'chatRequest'
}

export function isChatMessage(message: SocketMessage): message is ChatMessage {
  return isChatRequest(message) || isChatResponse(message)
}
