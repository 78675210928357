import { getRequest } from '@/utils/data-fetching/config.ts'
import * as turf from '@turf/turf'
import type { FeatureCollection, Polygon } from 'geojson'
import { apiUrl } from '../constants'

export interface OrthoResponseFeature {
  properties: {
    AOI: string
    layer: string
  }
  'Child AOI': {
    Name: string
    'Max Zoom': number
    'Min Date': string
  }[]
}
export interface VexcelOrthoCoverageAoi {
  aoi: string
  childAoi: string
  maxZoom: number
  minDate: string
  layerName: string
}
export const getVexcelOrthoCoverage = async (polygon: Polygon) => {
  const center = turf.center(polygon).geometry.coordinates
  const response = await getRequest<FeatureCollection>(
    `${apiUrl}/provider/vexcel/metadata/OrthoCoverage?format=wkt&wkt=POINT(${center[0]} ${center[1]})`,
  )

  const features = response.data.features as unknown as OrthoResponseFeature[]
  const aois: VexcelOrthoCoverageAoi[] = []

  features.forEach((f) => {
    f['Child AOI'].forEach((c) => {
      aois.push({
        aoi: f.properties['AOI'],
        childAoi: c['Name'],
        maxZoom: c['Max Zoom'],
        minDate: c['Min Date'],
        layerName: f.properties['layer'],
      })
    })
  })
  return aois
}
