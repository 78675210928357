import { useNavigate } from 'react-router-dom'
import { Group, Title } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { ImageResultPostfilterPopup } from '@/components/lib/results/results-postfilter/ImageResultPostfilterPopup.tsx'
import type React from 'react'

interface CompactPanelHeaderProps {
  titleShort: string
  link: string
  menu?: React.ReactNode
  position?: 'left' | 'center' | 'right'
}

export function CompactPanelHeader({
  titleShort,
  link,
  position,
}: CompactPanelHeaderProps) {
  const navigate = useNavigate()

  return (
    <div>
      <Group mb="sm">
        <Title
          size={position === 'center' ? 24 : 20}
          onClick={() => navigate(link)}
          style={{ cursor: 'pointer' }}
        >
          <Group gap={0}>
            {titleShort}
            <Icon
              name="chevron_right"
              size={position === 'center' ? 24 : 20}
              weight={700}
            />
          </Group>
        </Title>
        <div style={{ flexGrow: 1 }} />
        {link === '/images' && <ImageResultPostfilterPopup />}
      </Group>
    </div>
  )
}
