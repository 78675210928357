export const attomPropertySample7434 = {
  title: 'Resource from Attom Data',
  subtitle: '',
  geometry: {
    coordinates: ['-122.726387', '45.471485'],
    type: 'Point',
  },
  documentId: 'unknown',
  score: 0.5,
  description: 'Resource from Attom Data',
  category: 'DATA',
  thumbnail: '',
  filetype: '',
  authoredOn: '2023-06-17T00:00:00',
  source: 'ATTOM',
  tags: null,
  license: 'unknown',
  properties: {
    address: {
      country: 'US',
      countrySubd: 'OR',
      line1: '7434 SW 49TH CT',
      line2: 'PORTLAND, OR 97219',
      locality: 'PORTLAND',
      matchCode: 'ExaStr',
      oneLine: '7434 SW 49TH CT, PORTLAND, OR 97219',
      postal1: '97219',
      postal2: '1492',
      postal3: 'C023',
      situsAddressSuffix: 'CT',
      situsDirection: 'SW',
      situsHouseNumber: '7434',
      situsStreetName: '49TH',
      stateFips: '41',
    },
    area: {
      censusBlockGroup: '1',
      censusTractIdent: '6601',
      countrySecSubd: 'Multnomah',
      countyUse1: 'SFR  ',
      locType: 'VIEW - NONE',
      munCode: 'MU',
      munName: 'MULTNOMAH',
      srvyRange: '01E',
      srvySection: '19',
      srvyTownship: '01S',
      subdName: 'AZALEA PARK',
      taxCodeArea: '1',
    },
    assessment: {
      appraised: {},
      assessed: {
        assdTtlValue: 453850,
      },
      market: {
        mktImprValue: 525430,
        mktLandValue: 321000,
        mktTtlValue: 846430,
      },
      mortgage: {
        FirstConcurrent: {
          amount: 648000,
          companyCode: '65867',
          date: '2020-08-04',
          deedType: 'WD',
          ident: '97222',
          lenderLastName: 'PRIME LNDG',
          term: '360',
          trustDeedDocumentNumber: '97222',
        },
        SecondConcurrent: {},
        title: {
          companyCode: '1643',
          companyName: 'FIRST AMERICAN TITLE',
        },
      },
      owner: {
        absenteeOwnerStatus: 'O',
        corporateIndicator: 'N',
        description: 'INDIVIDUAL',
        mailingAddressOneLine: '7434 SW 49TH CT, PORTLAND, OR 97219-1492',
        owner1: {
          firstNameAndMi: 'ANDREW ',
          lastName: 'ISAACSON ',
        },
        owner2: {
          firstNameAndMi: 'AMY ',
          lastName: 'ISAACSON ',
        },
        owner3: {},
        owner4: {},
        type: 'INDIVIDUAL',
      },
      tax: {
        exemption: {},
        exemptiontype: {},
        taxAmt: 12107.47,
        taxPerSizeUnit: 4.06,
        taxYear: 2022,
      },
    },
    building: {
      construction: {
        foundationType: 'CONCRETE',
        roofCover: 'COMPOSITION SHINGLE',
        wallType: 'WOOD',
      },
      interior: {
        fplcCount: 1,
        fplcInd: 'Y',
        fplcType: 'YES',
      },
      parking: {
        garageType: 'Garage, Attached',
        prkgSize: 688,
        prkgSpaces: '2',
        prkgType: 'Garage, Attached',
      },
      rooms: {
        bathsFull: 2,
        bathsPartial: 1,
        bathsTotal: 3,
        beds: 4,
      },
      size: {
        bldgSize: 2981,
        grossSize: 3453,
        grossSizeAdjusted: 2981,
        groundFloorSize: 1476,
        livingSize: 2981,
        sizeInd: 'LIVING SQFT',
        universalSize: 2981,
      },
      summary: {
        levels: 2,
        storyDesc: 'CONVENTIONAL HOUSE',
        view: 'VIEW - NONE',
        viewCode: '000',
      },
    },
    identifier: {
      Id: 147938444,
      apn: '1S1E19AD 02301',
      attomId: 147938444,
      fips: '41051',
    },
    location: {
      accuracy: 'Rooftop',
      distance: 0,
      geoIdV4: {
        CO: 'fe537ed5e15f2aae50bce41e9a234641',
        CS: '2e2f48c3aecc24a1cb5bd66b8d11a672',
        DB: '46cab17588eaf850ebeaa8bbe6dc21f2',
        N1: '5ac03b3e09d2dff25587946f220107de',
        N2: 'cd369f72f1f3b7d9feb3963d76656819',
        N4: '5c217fd256c64354d9956aded587fbcb',
        PL: 'f2bdbbce9644a35b0b754a7f218c4065',
        SB: '9bf4770f309d2b3430e755137115ad19,f5c5bbeee4128bd5eeda1493fdfa0817,04fa410b69802df8e6d758d86bc7692e',
        ZI: '2289c92219b5c62430add5bda43e912e',
      },
      geoid:
        'CO41051, CS4192520, DB4110040, ND0000628798, ND0004662665, PL4159000, RS0000144504, SB0000112186, SB0000112214, SB0000112215, ZI97219',
      latitude: '45.471485',
      longitude: '-122.726387',
    },
    lot: {
      lotNum: '8',
      lotSize1: 0.3018136,
      lotSize2: 13147,
      poolType: 'NO POOL',
      siteZoningIdent: 'R7',
      zoningType: 'Residential',
    },
    sale: {
      amount: {
        saleAmt: 810000,
        saleCode:
          'ESTIMATED Sale price is not available due to non-disclosure counties',
        saleDisclosureType: 0,
        saleDocNum: '0000097221',
        saleDocType: 'DEED',
        saleRecDate: '2020-08-04',
        saleTransType: 'Resale',
      },
      calculation: {
        pricePerBed: 202500,
        pricePerSizeUnit: 271.72,
      },
      saleSearchDate: '2020-08-04',
      saleTransDate: '2020-07-31',
      sellerName: 'AKOS KONDOR',
      sequenceSaleHistory: 1,
      transactionIdent: '891297638',
    },
    summary: {
      REOflag: 'False',
      absenteeInd: 'OWNER OCCUPIED',
      legal1: 'AZALEA PARK, LOT 8',
      propClass: 'Single Family Residence / Townhouse',
      propIndicator: 10,
      propLandUse: 'SFR',
      propSubType: 'Residential',
      propType: 'SFR',
      propertyType: 'SINGLE FAMILY RESIDENCE',
      quitClaimFlag: 'False',
      yearBuilt: 1999,
    },
    utilities: {
      heatingType: 'FORCED AIR',
    },
    vintage: {
      lastModified: '2023-06-17',
      pubDate: '2023-06-17',
    },
  },
  asset: '',
}
