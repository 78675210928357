import { extractCompanyName } from '@/hooks/results/result-filter-utilities.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { apiUrl } from '@/utils/constants.ts'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Polygon } from 'geojson'

export const makeGegdOrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  return {
    source: 'GEGD' as const,
    id: r.properties['featureId'] || r.id,
    tileUrl:
      `${apiUrl}/provider/gegd/wms?SERVICE=WMS&REQUEST=GetMap&VERSION=1.1.1&SRS=EPSG:3857` +
      `&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256&LAYERS=DigitalGlobe:Imagery` +
      `&FORMAT=image/png&transparent=true&FEATURECOLLECTION=${r.properties['featureId']}`,
    authoredOn: r.authoredOn,
    geometry: r.geometry as Polygon,
    imageResult: r,
    groupKey: r.properties.featureId || r.id,
    score: r.score,
    formattedSource: `${extractCompanyName(r)} (G-EGD)`,
  }
}

export const isGegd = (ir: ImageryResult) => ir.source.toLowerCase() === 'gegd'
export const useGegd = () => {
  const imageResults = usePostfilterImageResults()

  const gegdOrthoLayers = imageResults.filter(isGegd).map(makeGegdOrthoLayer)

  return { gegdOrthoLayers }
}
