import {
  openAddToCollectionModal,
  openAnalyzeCollectionModal,
  openDownloadItemModal,
  openResultDetailsModal,
  openShareCollectionModal,
} from '@/components/modals/openers.tsx'
import type { SymbolName } from '@/utils/types/material-icons.ts'
import {
  isImageryResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'

export interface ActionMenuType {
  label: string
  icon: SymbolName
  onClick: (result: ResultWithId) => void
  title?: string
  disabled?: boolean
}

export const actionMenuOptions: Record<string, ActionMenuType> = {
  addToCollection: {
    label: 'Add to Collection',
    icon: 'add',
    onClick: (result: ResultWithId) => {
      openAddToCollectionModal(result)
    },
  },
  viewDetails: {
    label: 'View Details',
    icon: 'info',
    onClick: (result: ResultWithId) => {
      openResultDetailsModal(result)
    },
  },
  visitArticle: {
    label: 'Visit Article',
    icon: 'open_in_new',
    onClick: (result: ResultWithId) => {
      window.open(result.thumbnail!, '_blank')
    },
  },
  downloadImage: {
    label: 'Download',
    icon: 'download',
    onClick: (result: ResultWithId) => {
      if (isImageryResult(result)) {
        openDownloadItemModal(result)
      }
    },
    disabled: true,
  },
  viewOnMap: {
    label: 'View on Map',
    icon: 'location_pin',
    onClick: (result: ResultWithId) => {
      window.location.pathname = `/map/${result.id}`
    },
  },
  findRelatedImages: {
    label: 'Find Related Images',
    icon: 'image_search',
    onClick: (result: ResultWithId) => {
      void result
    },
  },
  sendToAnalytics: {
    label: 'Send to Analytics',
    icon: 'chart_data',
    onClick: (result: ResultWithId) => {
      openAnalyzeCollectionModal(result.id)
    },
    title: 'Coming Soon',
    disabled: true,
  },
  share: {
    label: 'Share',
    icon: 'ios_share',
    onClick: (result: ResultWithId) => {
      openShareCollectionModal(result.id)
    },
    title: 'Coming Soon',
    disabled: true,
  },
} as const
export type ActionMenuOption = keyof typeof actionMenuOptions

export function isActionMenuOption(
  value?: string | (() => void),
): value is ActionMenuOption {
  return (value &&
    typeof value !== 'function' &&
    Object.keys(actionMenuOptions).includes(value)) as boolean
}
