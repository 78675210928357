import { createStore } from '@/utils/create-store'
import {
  addIdToResult,
  filterAndGroupResults,
} from '@/utils/data-fetching/config.ts'
import {
  type DataResult,
  type ImageryResult,
  type InsightResult,
  isRelatedImageryResult,
  type PublicationResult,
  type RawSearchResult,
  type RelatedImageryResult,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'

export interface CategorizedResults {
  imageResults: (ImageryResult | RelatedImageryResult)[]
  dataResults: DataResult[]
  publicationResults: PublicationResult[]
  socialMediaResults: SocialMediaResult[]
  insightResults: InsightResult[]
}

export interface Results {
  currentQueryId: string | null
  categorized: CategorizedResults
  ids: string[]
}

interface ResultsActions {
  setCurrentQueryId: (queryId: string | null) => void
  clearResults: () => void
  addResults: (results: RawSearchResult[]) => void
  addRelatedImageryResults: (results: RawSearchResult[]) => void
  clearImages: () => void
}

const initialCategories = {
  imageResults: [],
  dataResults: [],
  publicationResults: [],
  socialMediaResults: [],
  insightResults: [],
}

export const initialResultsState: Results = {
  currentQueryId: null,
  categorized: initialCategories,
  ids: [],
}

export const resultsStore = createStore<Results & ResultsActions>({
  storageKey: 'Results',
  version: 0.06,
  initialState: initialResultsState,
  actions: (set, get) => ({
    setCurrentQueryId: (queryId: string | null) => {
      console.log(`Got queryId ${queryId}`)
      set({ currentQueryId: queryId })
    },
    clearResults: () => {
      set({
        currentQueryId: null,
        categorized: {
          imageResults: [],
          dataResults: [],
          publicationResults: [],
          socialMediaResults: [],
          insightResults: [],
        },
        ids: [],
      })
    },
    addResults: (results: RawSearchResult[]) => {
      const { categorized } = get()
      if (categorized) {
        const {
          ids: newIds,
          // Separate Insights to exclude them from total count
          insightResults,
          ...categories
        } = filterAndGroupResults(results, categorized)
        set({
          categorized: { ...categories, insightResults },
          ids: newIds,
        })
      }
    },
    addRelatedImageryResults: (results: RawSearchResult[]) => {
      const { categorized } = get()
      const resultsWithIds = results
        .map(addIdToResult)
        .filter(isRelatedImageryResult)

      if (categorized) {
        set({
          categorized: {
            ...categorized,
            imageResults: orderBy(
              [...resultsWithIds, ...categorized.imageResults],
              ['category', 'score'],
              ['desc', 'desc'],
            ),
          },
        })
      }
    },
    clearImages: () => {
      const { categorized } = get()
      if (categorized) {
        set({
          categorized: {
            ...categorized,
            imageResults: [],
          },
        })
      }
    },
  }),
})
