import { Outlet } from 'react-router-dom'
import { Container, em } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { Header } from '@/components/layout/Header.tsx'
import { AddToCollectionModal } from '@/components/modals/AddToCollectionModal.tsx'
import { AnalyzeCollectionModal } from '@/components/modals/AnalyzeCollectionModal.tsx'
import { AnalyzeItemModal } from '@/components/modals/AnalyzeItemModal.tsx'
import { CreateCollectionModal } from '@/components/modals/CreateCollectionModal.tsx'
import { DeleteCollectionModal } from '@/components/modals/DeleteCollectionModal.tsx'
import { DownloadCollectionModal } from '@/components/modals/DownloadCollectionModal.tsx'
import { DownloadItemModal } from '@/components/modals/DownloadItemModal.tsx'
import { RemoveFromCollectionModal } from '@/components/modals/RemoveFromCollectionModal.tsx'
import { ResultDetailsModal } from '@/components/modals/ResultDetailsModal.tsx'
import { SessionConfirmationModal } from '@/components/modals/SessionConfirmationModal.tsx'
import { ShareCollectionModal } from '@/components/modals/ShareCollectionModal.tsx'
import { UpdateCollectionModal } from '@/components/modals/UpdateCollectionModal.tsx'
import { useHeapAnalytics } from '@/hooks/use-heap-analytics.ts'
import { useSentryMetadataSyncing } from '@/hooks/use-sentry-metadata-syncing.ts'
import { useCollections } from '@/utils/data-fetching/collections.ts'
import { HeaderMobile } from './HeaderMobile'

import '@mantine/notifications/styles.css'

export default function MainLayout() {
  useCollections()
  useHeapAnalytics()
  useSentryMetadataSyncing()
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)

  return (
    <ModalsProvider
      modals={{
        addToCollection: AddToCollectionModal,
        removeFromCollection: RemoveFromCollectionModal,
        createCollection: CreateCollectionModal,
        updateCollection: UpdateCollectionModal,
        shareCollection: ShareCollectionModal,
        deleteCollection: DeleteCollectionModal,
        analyzeCollection: AnalyzeCollectionModal,
        analyzeItem: AnalyzeItemModal,
        downloadItem: DownloadItemModal,
        downloadCollection: DownloadCollectionModal,
        resultDetails: ResultDetailsModal,
        sessionConfirmation: SessionConfirmationModal,
      }}
    >
      {isMobile ? <HeaderMobile /> : <Header />}
      <Container size="lg" pt={100} pb={50} maw="100%" h="calc(100% - 70px)">
        <Notifications />
        <Outlet />
      </Container>
    </ModalsProvider>
  )
}
