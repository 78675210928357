import { serviceApiUrl } from '@/utils/constants.ts'
import { getRequest } from '@/utils/data-fetching/config.ts'
import {
  isImageryResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { useQuery } from '@tanstack/react-query'

async function checkDownloadAvailability(item: ResultWithId) {
  if (!isImageryResult(item)) {
    return false
  }

  const { _dProvider, featureId, companyName } = item.properties

  if (companyName !== 'DigitalGlobe') {
    return false
  }

  try {
    await getRequest(`/download/check/${_dProvider}/${featureId}`, {
      baseURL: serviceApiUrl,
    })
    return true
  } catch {
    return false
  }
}

export function useIsDownloadable(item: ResultWithId, fetchCondition = false) {
  return useQuery({
    queryKey: ['isDownloadable', item.documentId],
    queryFn: async () => checkDownloadAvailability(item),
    retry: false,
    enabled: fetchCondition,
  })
}
