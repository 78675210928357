import { postRequest } from '@/utils/data-fetching/config.ts'

export interface GoogleMapsResult {
  address_components: {
    long_name: string
    short_name: string
    types: string[]
  }[]
  formatted_address: string
  geometry: {
    viewport: {
      northeast: {
        lat: number
        lng: number
      }
      southwest: {
        lat: number
        lng: number
      }
    }
    location: {
      lat: number
      lng: number
    }
  }
  place_id: string
  types: string[]
}

export interface GoogleMapsResponse {
  results: GoogleMapsResult[]
  status: string
}

export interface AutocompleteOption {
  label: string
  value: string
  isUsAddress?: boolean
}

type Bounds = {
  northeast: { lat: number; lng: number }
  southwest: { lat: number; lng: number }
}

function createBoundingBoxFromCorners({ northeast, southwest }: Bounds) {
  const offset = 0.085
  const topRight = [northeast.lng + offset, northeast.lat + offset]
  const topLeft = [southwest.lng + offset, northeast.lat - offset]
  const bottomLeft = [southwest.lng - offset, southwest.lat - offset]
  const bottomRight = [northeast.lng - offset, southwest.lat + offset]
  const polygon = [topLeft, topRight, bottomRight, bottomLeft, topLeft]

  return JSON.stringify(polygon)
}

export const separatorString = ' $#$#$# '

export async function resolveLocation(
  value: string,
  callback: (options: AutocompleteOption) => void,
) {
  if (!value) {
    return
  }
  const { data } = await postRequest<string>('/provider/googlemaps', {
    address: value,
    api_request_type: 'geocode',
  })
  const { results } = JSON.parse(atob(data)) as GoogleMapsResponse
  if (results.length === 0) {
    return
  }
  const result = results[0]
  const isUsAddress = result.address_components.some(
    // eslint-disable-next-line camelcase
    ({ types, short_name }) => types.includes('country') && short_name === 'US',
  )
  const bbox = createBoundingBoxFromCorners(result.geometry.viewport)
  const formattedOption = {
    isUsAddress,
    label: result.formatted_address,
    value: `${bbox}${separatorString}${result.formatted_address}`,
  }

  callback(formattedOption)
}
