import { createStore } from '@/utils/create-store.ts'

export interface Config {
  showScores: boolean
}

export const initialConfigState: Config = {
  showScores: false,
}

export const configStore = createStore<Config>({
  initialState: initialConfigState,
  storageKey: 'ConfigStore',
  version: 0.03,
})

export function getShowScores() {
  return configStore.getState().showScores
}

export function toggleShowScores() {
  const currentShowScores = getShowScores()
  configStore.setState({ showScores: !currentShowScores })
}
