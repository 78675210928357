import {
  type FC,
  type MouseEventHandler,
  type MutableRefObject,
  useEffect,
  useState,
} from 'react'
import { Button, Group, Text } from '@mantine/core'
import { FilterToggleButton } from '@/components/filters/FilterToggleButton.tsx'
import { MemoFilterPanel } from '@/components/lib/FilterPanel.tsx'
import { Icon } from '@/components/lib/Icon.tsx'
import { useClearAll } from '@/hooks/use-clear-all.ts'
import { useSearch } from '@/hooks/use-search.ts'
import { mapDrawStore } from '@/stores/map-draw-store.ts'
import { mapStore } from '@/stores/map-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'

import styles from './map-control-panel.module.css'

const METERS_PER_MILE = 1609.34
const SEARCH_IN_WINDOW_MILE_THRESHOLD = 1000

type MapControlPanelProps = {
  openFileDialog: MouseEventHandler<HTMLElement>
  recomputeHexGrid: (results: ResultWithId[]) => void
  map: MutableRefObject<mapboxgl.Map | null>
}

export const MapControlPanel: FC<MapControlPanelProps> = ({
  map,
  openFileDialog,
  recomputeHexGrid,
}) => {
  const [baseLayer, setBaseLayer, visualizationMode, setVisualizationMode] =
    mapStore((s) => [
      s.baseLayer,
      s.setBaseLayer,
      s.visualizationMode,
      s.setVisualizationMode,
    ])
  const [startDrawingPolygon, isDrawing] = mapDrawStore((s) => [
    s.startDrawingPolygon,
    s.isDrawing,
  ])
  const imageResults = resultsStore((s) => s.categorized.imageResults)
  const { doLocationSearch } = useSearch()
  const clearAll = useClearAll()

  const [isFilterExpanded, setIsFilterExpanded] = useState(false)
  const [isSearchInWindowEnabled, setSearchInWindowEnabled] = useState(false)

  const handleClearClick = () => {
    clearAll()
    recomputeHexGrid([])
  }

  const doSearchInWindow = async () => {
    const b = map.current?.getBounds()
    if (b) {
      const poly = [
        [b.getNorthWest().lng, b.getNorthWest().lat],
        [b.getNorthEast().lng, b.getNorthEast().lat],
        [b.getSouthEast().lng, b.getSouthEast().lat],
        [b.getSouthWest().lng, b.getSouthWest().lat],
        [b.getNorthWest().lng, b.getNorthWest().lat],
      ]
      await doLocationSearch(JSON.stringify(poly), 'polygon')
      recomputeHexGrid(imageResults)
    }
  }

  useEffect(() => {
    map.current?.on('moveend', () => {
      if (map && map.current && map.current?.getBounds())
        setSearchInWindowEnabled(
          map?.current
            ?.getBounds()
            .getNorthWest()
            .distanceTo(map.current?.getBounds().getSouthEast()) <
            SEARCH_IN_WINDOW_MILE_THRESHOLD * METERS_PER_MILE,
        )
    })
  }, [map])

  return (
    <Group
      className={styles['mapControls']}
      justify="space-between"
      bg="white"
      px="sm"
      py="xs"
      pos="fixed"
      top="4.25rem"
      w="100%"
      mx="-xl"
      data-cy="map-controls"
    >
      <Group gap="xs">
        <Text fz="xs">Search By:</Text>
        <Button.Group>
          <Button
            size="xs"
            leftSection={<Icon name="upload" />}
            onClick={openFileDialog}
            data-cy="map-controls-file-upload"
          >
            File
          </Button>
          <Button
            size="xs"
            leftSection={<Icon name="polyline" />}
            onClick={() => {
              if (isDrawing) {
                handleClearClick()
              } else {
                startDrawingPolygon()
              }
            }}
            data-cy="map-controls-polygon-draw"
            {...(isDrawing ? { 'data-active': true } : {})}
          >
            Polygon
          </Button>
          <Button
            size="xs"
            leftSection={<Icon name="pageview" />}
            onClick={() => void doSearchInWindow()}
            {...(isSearchInWindowEnabled ? {} : { 'data-disabled': false })}
            data-cy="map-controls-search-in-window"
          >
            View
          </Button>
          <Button
            size="xs"
            leftSection={<Icon name="delete" />}
            onClick={handleClearClick}
            data-cy="map-controls-clear"
          >
            Clear
          </Button>
        </Button.Group>
      </Group>

      <Group gap="xs">
        <Text fz="xs">Show:</Text>
        <Button.Group>
          <Button
            size="xs"
            data-active={visualizationMode === 'extents'}
            onClick={() => setVisualizationMode('extents')}
            leftSection={<Icon name="rectangle" />}
            data-cy="map-controls-layer-extents-toggle"
          >
            EXTENTS
          </Button>
          <Button
            size="xs"
            data-active={visualizationMode === 'grid'}
            onClick={() => {
              recomputeHexGrid(imageResults)
              setVisualizationMode('grid')
            }}
            leftSection={<Icon name="hexagon" />}
            data-cy="map-controls-layer-grid-toggle"
          >
            GRID
          </Button>
          {/*<Button
              size="xs"
              data-active={visualizationMode === 'point'}
              onClick={() => setVisualizationMode('point')}
              leftSection={<Icon name="rectangle" />}
              data-cy="map-controls-layer-point-toggle"
            >
              POINT
            </Button>*/}
          <Button
            size="xs"
            data-active={visualizationMode === 'none'}
            onClick={() => {
              setVisualizationMode('none')
            }}
            leftSection={<Icon name="hide_source" />}
            data-cy="map-controls-layer-none"
          >
            NONE
          </Button>
        </Button.Group>
      </Group>

      <Group gap="xs">
        <Text fz="xs">Map Mode:</Text>
        <Button.Group>
          <Button
            size="xs"
            data-active={baseLayer === 'light-v11'}
            onClick={() => setBaseLayer('light-v11')}
            data-cy="map-controls-mode-light"
          >
            <Icon name="wb_sunny" />
          </Button>
          <Button
            size="xs"
            data-active={baseLayer === 'dark-v11'}
            onClick={() => setBaseLayer('dark-v11')}
            data-cy="map-controls-mode-dark"
          >
            <Icon name="mode_night" />
          </Button>
          <Button
            size="xs"
            data-active={baseLayer === 'satellite-streets-v9'}
            onClick={() => setBaseLayer('satellite-streets-v9')}
            data-cy="map-controls-mode-satellite"
          >
            <Icon name="public" />
          </Button>
        </Button.Group>
      </Group>
      <FilterToggleButton
        isFilterOpen={isFilterExpanded}
        onClick={() => setIsFilterExpanded((state) => !state)}
        data-cy="map-controls-filter-toggle"
      />
      <MemoFilterPanel
        isOpen={isFilterExpanded}
        data-cy="map-controls-filter-panel"
      />
    </Group>
  )
}
