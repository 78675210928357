import { useCallback, useEffect, useState } from 'react'
import { useInterval } from '@mantine/hooks'
import {
  CONTEXT_HIST_HEIGHT,
  FOCUS_HIST_HEIGHT,
  INNER_MARGIN,
  SVG_TOP_PADDING,
} from '@/components/lib/results/results-timeline/ResultsTimeline.tsx'
import { AxisBottom } from '@visx/axis'
import { Group } from '@visx/group'
import { scaleLinear } from '@visx/scale'
import { Bar } from '@visx/shape'
import { animated, useSpring } from 'react-spring'

export const ANIM_INTERVAL_MS = 300
export const NUM_WAVES = 2
export const BAR_SPACING = 8
export const MIN_HEIGHT = 5
const AnimatedHistogramBar = (props: {
  isHigh: boolean
  index: number
  x: number
  barWidth: number
  yMax: number
}) => {
  const { scale } = useSpring({
    from: { scale: 0 },
    to: { scale: props.isHigh ? 1 : 0 },
    config: {
      duration: 1000,
      velocity: props.isHigh ? 50000 : 100,
    },
  })

  const AnimatedBar = animated(Bar)
  return (
    <AnimatedBar
      key={`bar-${props.index}`}
      x={props.x}
      y={scale.to((s) => props.yMax - MIN_HEIGHT - s * props.yMax)}
      width={props.barWidth}
      height={scale.to((s) => MIN_HEIGHT + s * props.yMax)}
      fill="#BDBDBD"
    />
  )
}
export const ResultsTimelineLoading = (props: {
  width: number
  small: boolean
  numBars: number
}) => {
  const [currentHighIndex, setCurrentHighIndex] = useState(0)
  const maxAnimationIndex = props.numBars / NUM_WAVES
  const barWidth = props.width / props.numBars

  const advanceBar = useCallback(() => {
    setCurrentHighIndex((currentHighIndex + 1) % maxAnimationIndex)
  }, [currentHighIndex, maxAnimationIndex])

  const animationInterval = useInterval(advanceBar, ANIM_INTERVAL_MS)
  useEffect(() => {
    animationInterval.start()
    return () => animationInterval.stop()
  }, [animationInterval])

  return (
    <svg
      width={props.width}
      height={
        FOCUS_HIST_HEIGHT + CONTEXT_HIST_HEIGHT + INNER_MARGIN + SVG_TOP_PADDING
      }
    >
      <Group>
        <Group top={SVG_TOP_PADDING}>
          <rect
            style={{ cursor: 'crosshair' }}
            x={0}
            width={props.width}
            height={FOCUS_HIST_HEIGHT}
            fillOpacity={0}
          />
          <Group>
            {[...Array.from({ length: props.numBars }).keys()].map((index) => {
              return (
                <AnimatedHistogramBar
                  key={`bar_${index}`}
                  isHigh={
                    index % Math.floor(props.numBars / NUM_WAVES) ===
                    currentHighIndex
                  }
                  index={index}
                  x={(barWidth + BAR_SPACING) * index}
                  yMax={FOCUS_HIST_HEIGHT}
                  barWidth={barWidth}
                />
              )
            })}
          </Group>
          <AxisBottom
            scale={scaleLinear({ domain: [0, 1], range: [0, props.width] })}
            top={FOCUS_HIST_HEIGHT}
            numTicks={0}
          />
        </Group>
      </Group>
    </svg>
  )
}
