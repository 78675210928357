import { MultiSelect } from '@mantine/core'
import { filtersStore, setProviders } from '@/stores/filters-store.ts'
import { Provider } from '@/utils/types/filter-types.ts'

export function Providers() {
  const providers = filtersStore((state) => state.providers)

  const updateProviders = (selectedProviders: string[]) => {
    const providersArray: string[] = selectedProviders.map((provider) => {
      const providerKey = Object.keys(Provider).find((key) => key === provider)
      return providerKey as keyof typeof Provider
    })
    setProviders(providersArray)
  }

  const providerLabels = Object.entries(Provider)
    .filter(([value]) => ['PLANET', 'GEGD'].includes(value))
    .map(([value, label]) => ({ value, label }))

  return (
    <MultiSelect
      mt="sm"
      placeholder={providers.length === 0 ? 'Any' : ''}
      value={providers}
      onChange={updateProviders}
      data={providerLabels}
      searchable
      multiple
    />
  )
}
