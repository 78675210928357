import { useNavigate } from 'react-router-dom'
import { Button, Group, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import {
  type DeleteCollectionVariables,
  useCollections,
} from '@/utils/data-fetching/collections.ts'

export function DeleteCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId },
}: ContextModalProps<DeleteCollectionVariables>) {
  const navigate = useNavigate()
  const { deleteCollection } = useCollections()

  async function handleDelete() {
    await deleteCollection({ collectionId })
    context.closeModal(modalId)
    navigate('/collections')
  }

  return (
    <>
      <Text>Are you sure you want to delete this collection?</Text>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button color="red" onClick={() => void handleDelete()}>
          Delete
        </Button>
      </Group>
    </>
  )
}
