import type {
  CloudCoverApiFilterValue,
  DateRangeApiFilterValue,
  KeywordApiFilterValue,
  NadirApiFilterValue,
  OrientationApiFilterValue,
  ProviderApiFilterValue,
  QualityApiFilterValue,
  SensorApiFilterValue,
} from '@/utils/types/filter-types.ts'

export interface ExtractionResult {
  dateRange?: DateRangeApiFilterValue
  location?: string
  locationType?: string
  sensors?: SensorApiFilterValue
  providers?: ProviderApiFilterValue
  quality?: QualityApiFilterValue
  cloudCover?: CloudCoverApiFilterValue
  angle?: NadirApiFilterValue
  orientations?: OrientationApiFilterValue
  keywords?: KeywordApiFilterValue
  error?: string
}

export function isoToCustomTime(date: Date) {
  return date.toISOString().replace(/\.\d+Z$/, 'Z')
}

/**
 * Extract date information from a query string.
 * @param {string } query - The user's query.
 * @returns {[DateRangeApiFilterValue, string]} Returns formatted date object.
 */
export function parseDateRange(
  query: string,
): [DateRangeApiFilterValue | undefined, string] {
  // Initialize default date range to past 6 months
  const currentDate = new Date()
  const sixMonthsAgo = new Date(currentDate)
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6)

  const dateRange: DateRangeApiFilterValue = {
    type: 'TIMEFRAME',
    start: isoToCustomTime(sixMonthsAgo),
    end: isoToCustomTime(currentDate),
  }

  let currentQuery = query

  // TODO: Re-enable this when we want to support these keywords
  // const dayTimeRegex = /\bday(?:\s*time)?\b/i
  // const dayTimeMatch = query.match(dayTimeRegex)
  //
  // const nightTimeRegex = /\bnight(?:\s*time)?\b/i
  // const nightTimeMatch = query.match(nightTimeRegex)

  // Date Range Regex
  const dateRangeRegex =
    /((\d{1,2})\/(\d{1,2})\/(\d{4})) to ((\d{1,2})\/(\d{1,2})\/(\d{4}))/
  const dateRangeMatch = query.match(dateRangeRegex)
  if (dateRangeMatch) {
    currentQuery = currentQuery.replace(dateRangeRegex, '')
    const start = new Date(dateRangeMatch[1]!)
    const end = new Date(dateRangeMatch[5]!)
    end.setHours(23, 59, 59)

    dateRange.start = isoToCustomTime(start)
    dateRange.end = isoToCustomTime(end)
    return [dateRange, currentQuery]
  }

  // Date Regex
  const dateRegex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/
  const dateMatch = query.match(dateRegex)
  if (dateMatch) {
    currentQuery = currentQuery.replace(dateRegex, '')
    const start = new Date(dateMatch[0])
    const end = new Date(start)
    end.setHours(23, 59, 59)
    dateRange.start = isoToCustomTime(start)
    dateRange.end = isoToCustomTime(end)
    return [dateRange, currentQuery]
  }

  // Relative Date Regex
  const relativeDateRegex =
    /(past\s)?(\d{1,2})\s*(hour|day|week|month|year)s?\s*/
  const relativeDateMatch = query.match(relativeDateRegex)
  if (relativeDateMatch) {
    currentQuery = currentQuery.replace(relativeDateRegex, '')
    const number = Number.parseInt(relativeDateMatch[2]!)
    const unit = relativeDateMatch[3]
    const start = currentDate
    const end = new Date()

    switch (unit) {
      case 'hour':
        start.setHours(currentDate.getHours() - number)
        break
      case 'day':
        start.setDate(currentDate.getDate() - number)
        break
      case 'week':
        start.setDate(currentDate.getDate() - number * 7)
        break
      case 'month':
        start.setMonth(currentDate.getMonth() - number)
        break
      case 'year':
        start.setFullYear(currentDate.getFullYear() - number)
        break
    }

    dateRange.start = isoToCustomTime(start)
    dateRange.end = isoToCustomTime(end)
    return [dateRange, currentQuery]
  }

  return [undefined, currentQuery]
}

/**
 * Extract location information from a query string.
 * @param {string } query - The user's query.
 * @returns {{location: string, locationType: string, currentLocationQuery: string}} Returns formatted date object.
 */
export function parseLocation(query: string): {
  location?: string
  locationType?: string
  currentLocationQuery: string
} {
  let location: string | undefined
  let locationType: string | undefined
  let currentLocationQuery: string

  const stateList = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ]

  const bboxRegex =
    /\[\[(-?\d+(\.\d+)?),\s+(-?\d+(\.\d+)?)](?:,\s?\[(-?\d+(\.\d+)?),\s+(-?\d+(\.\d+)?)])*]$/
  const bboxMatch = query.match(bboxRegex)
  if (bboxMatch) {
    location = bboxMatch[0]
    locationType = 'bbox'
    currentLocationQuery = query.replace(bboxRegex, '')
    return {
      location,
      locationType,
      // Some bbox/polygon queries don't have their brackets and commas stripped properly
      currentLocationQuery: currentLocationQuery.replace(/\[,*?]/, ''),
    }
  }
  currentLocationQuery = query

  const polygonRegex =
    /(?:\[|\()?\s*(-?\d+\.\d+)\s*,\s*(-?\d+\.\d+)\s*(?:\]|\))?\s*/g
  const polygonMatch = [...query.matchAll(polygonRegex)]
  if (polygonMatch.length > 0) {
    let location = ''
    let locationType = 'polygon'
    let firstLat,
      firstLon = ''
    let currentLat,
      currentLon = ''

    // Building the location in the format [[lat, lon], [lat, lon], ...]
    let index = 0
    for (const match of polygonMatch) {
      if (index == 0) {
        firstLat = match[1]
        firstLon = match[2]!
      }
      currentLat = match[1]
      currentLon = match[2]!
      location += `[${currentLat}, ${currentLon}], `
      index++
    }
    if (firstLat != currentLat || firstLon != currentLon) {
      location += `[${firstLat}, ${firstLon}]`
    }

    while (location.at(-1) != ']') {
      location = `${location.slice(0, -1)}`
    }
    location = '[' + location + ']'

    if (location.split('], [').length < 2) {
      locationType = 'latLong'
    }
    currentLocationQuery = query.replaceAll(polygonRegex, '')
    return {
      location,
      locationType,
      // Some bbox/polygon queries don't have their brackets and commas stripped properly
      currentLocationQuery: currentLocationQuery.replace(/\[,*?]/, ''),
    }
  }

  // Latitude and Longitude Regex
  const latLongRegex = /(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)/
  const latLongMatch = query.match(latLongRegex)
  if (latLongMatch) {
    location = latLongMatch[0]
    locationType = 'latLong'
    currentLocationQuery = query.replace(latLongRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // MGRS Regex
  const mgrsRegex = /\d{1,2}[^ABIOYZabioyz][A-Za-z]{2}(\d\d)+/
  const mgrsMatch = query.match(mgrsRegex)
  if (mgrsMatch) {
    location = mgrsMatch[0]
    locationType = 'mgrs'
    currentLocationQuery = query.replace(mgrsRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // Street, City, State, Zip (EX: 1234 Main St, City, State, Zip)
  // Using this list for reference: https://fsawebenroll.ed.gov/RoboHelp/Address.htm#:~:text=Enter%20your%20complete%20mailing%20address,%2C%20slash%20(%2F)%2C%20and%20spaces.
  const streetCityStateZipRegex =
    /(\d+[\s\w#-./%']+),\s*([\s\w.]+),\s*([A-Za-z]{2})\.?,?\s*(\d{5}(-\d{4})?)(?:,\sUSA)?/
  const streetCityStateZipMatch = query.match(streetCityStateZipRegex)
  if (streetCityStateZipMatch) {
    location =
      streetCityStateZipMatch[1] +
      ',' +
      streetCityStateZipMatch[2] +
      ',' +
      streetCityStateZipMatch[3] +
      ',' +
      streetCityStateZipMatch[4]
    locationType = 'streetCityStateZip'
    currentLocationQuery = query.replace(streetCityStateZipRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // Street, City, State (EX: 1234 Main St, City, State)
  const streetCityStateRegex =
    /(\d+[\s\w#-./%']+),\s*([\s\w.]+),\s*([A-Za-z]{2})\.?,?\s*/
  const streetCityStateMatch = query.match(streetCityStateRegex)
  if (streetCityStateMatch) {
    location =
      streetCityStateMatch[1] +
      ',' +
      streetCityStateMatch[2] +
      ',' +
      streetCityStateMatch[3]
    locationType = 'streetCityState'
    currentLocationQuery = query.replace(streetCityStateRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // City, State (EX: Miami, FL)
  const cityStateRegex = new RegExp(
    `\\b([\\s.A-Za-z]+)\\s*,\\s*((${stateList.join('|')})|([A-Za-z]{2}))\\b`,
    'i',
  )
  const cityStateMatch = query.match(cityStateRegex)
  if (cityStateMatch) {
    location = cityStateMatch[1] + ',' + cityStateMatch[2]
    locationType = 'cityState'
    currentLocationQuery = query.replace(cityStateRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // City, Country (EX: Kiev, Ukraine)
  const cityCountryRegex = /\b([A-Za-z]+)\s*,\s*([A-Za-z]+)\b/
  const cityCountryMatch = query.match(cityCountryRegex)
  if (cityCountryMatch) {
    location = cityCountryMatch[1] + ',' + cityCountryMatch[2]
    locationType = 'cityCountry'
    currentLocationQuery = query.replace(cityCountryRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // Zip Code, State (EX: 22102, OK) where the comma is optional
  const zipCodeStateRegex = /^\d{5}(?:,\s*[A-Za-z]+)/
  const zipCodeStateMatch = query.match(zipCodeStateRegex)
  if (zipCodeStateMatch) {
    location = zipCodeStateMatch[0]
    locationType = 'zipCodeState'
    currentLocationQuery = query.replace(zipCodeStateRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  // Zip Code (EX: 22102 or 22102-1234)
  const zipCodeRegex = /\d{5}(?:-\d{4})?/
  const zipCodeMatch = query.match(zipCodeRegex)
  if (zipCodeMatch) {
    location = zipCodeMatch[0]
    locationType = 'zipCode'
    currentLocationQuery = query.replace(zipCodeRegex, '')
    return { location, locationType, currentLocationQuery }
  }

  return { location, locationType, currentLocationQuery }
}

/**
 * Extract sensor information from a query string.
 * @param {string } query - The user's query.
 * @returns {[SensorApiFilterValue, string]} Returns collection of sensors.
 */
export function parseSensors(
  query: string,
): [SensorApiFilterValue | undefined, string] {
  const sensors: string[] = []
  let currentQuery = query
  const EOSensorRegex = /\b(?:e\s*o|electro\s*optical)\b/i
  const EOSensorRegexMatch = query.match(EOSensorRegex)
  if (EOSensorRegexMatch) {
    sensors.push('EO')
    currentQuery = currentQuery.replace(EOSensorRegex, '')
  }

  const SARSensorRegex = /\b(?:s\s*a\s*r|synthetic\s*aperture\s*radar)\b/i
  const SARSensorRegexMatch = query.match(SARSensorRegex)
  if (SARSensorRegexMatch) {
    sensors.push('SAR')
    currentQuery = currentQuery.replace(SARSensorRegex, '')
  }

  const RadarRegex = /\bradar\b/i
  const RadarRegexMatch = query.match(RadarRegex)
  if (RadarRegexMatch) {
    sensors.push('RADAR')
    currentQuery = currentQuery.replace(RadarRegex, '')
  }

  const InfraredThermalRegex = /\b(?:infrared\s*thermal|thermal)\b/i
  const InfraredThermalRegexMatch = query.match(InfraredThermalRegex)
  if (InfraredThermalRegexMatch) {
    sensors.push('INFRAREDTHERMAL')
    currentQuery = currentQuery.replace(InfraredThermalRegex, '')
  }

  const InfraredRegex = /\binfrared\b/i
  const InfraredRegexMatch = query.match(InfraredRegex)
  if (InfraredRegexMatch) {
    sensors.push('INFRARED')
    currentQuery = currentQuery.replace(InfraredRegex, '')
  }

  const LidarRegex = /\blidar\b/i
  const LidarRegexMatch = query.match(LidarRegex)
  if (LidarRegexMatch) {
    sensors.push('LIDAR')
    currentQuery = currentQuery.replace(LidarRegex, '')
  }

  const HyperspectralRegex = /\bhyperspectral\b/i
  const HyperspectralRegexMatch = query.match(HyperspectralRegex)
  if (HyperspectralRegexMatch) {
    sensors.push('HYPERSPECTRAL')
    currentQuery = currentQuery.replace(HyperspectralRegex, '')
  }

  const MultispectralRegex = /\bmultispectral\b/i
  const MultispectralRegexMatch = query.match(MultispectralRegex)
  if (MultispectralRegexMatch) {
    sensors.push('MULTISPECTRAL')
    currentQuery = currentQuery.replace(MultispectralRegex, '')
  }

  const MicrowaveRegex = /\bmicrowave\b/i
  const MicrowaveRegexMatch = query.match(MicrowaveRegex)
  if (MicrowaveRegexMatch) {
    sensors.push('MICROWAVE')
    currentQuery = currentQuery.replace(MicrowaveRegex, '')
  }

  const AcousticRegex = /\bacoustic\b/i
  const AcousticRegexMatch = query.match(AcousticRegex)
  if (AcousticRegexMatch) {
    sensors.push('ACOUSTIC')
    currentQuery = currentQuery.replace(AcousticRegex, '')
  }

  const sensorFilter: SensorApiFilterValue = {
    type: 'SENSOR',
    sensors: sensors,
  }

  if (sensors.length > 0) {
    return [sensorFilter, currentQuery]
  }

  return [undefined, currentQuery]
}

/**
 * Extract provider information from a query string.
 * @param {string } query - The user's query.
 * @returns {[ProviderApiFilterValue, string]} Returns collection of providers.
 */
export function parseProviders(
  query: string,
): [ProviderApiFilterValue | undefined, string] {
  const providers = []
  let currentQuery = query

  const MaxarRegex = /\bmaxar\b/i
  const MaxarMatch = query.match(MaxarRegex)
  if (MaxarMatch) {
    providers.push('MAXAR')
    currentQuery = currentQuery.replace(MaxarRegex, '')
  }

  const PlanetRegex = /\b(?:planet|planet\s*labs)\b/i
  const PlanetMatch = query.match(PlanetRegex)
  if (PlanetMatch) {
    providers.push('PLANET')
    currentQuery = currentQuery.replace(PlanetRegex, '')
  }

  const AttomRegex = /\battom\b/i
  const AttomMatch = query.match(AttomRegex)
  if (AttomMatch) {
    providers.push('ATTOM')
    currentQuery = currentQuery.replace(AttomRegex, '')
  }

  const HazardHubRegex = /\b(?:hazard\s*hub|hazard)\b/i
  const HazardHubMatch = query.match(HazardHubRegex)
  if (HazardHubMatch) {
    providers.push('HAZARD')
    currentQuery = currentQuery.replace(HazardHubRegex, '')
  }

  const NearSpaceRegex = /\b(?:near\s*space|nearspace)\b/i
  const NearSpaceMatch = query.match(NearSpaceRegex)
  if (NearSpaceMatch) {
    providers.push('NEARSPACE')
    currentQuery = currentQuery.replace(NearSpaceRegex, '')
  }

  const CapellaRegex = /\bcapella\b/i
  const CapellaMatch = query.match(CapellaRegex)
  if (CapellaMatch) {
    providers.push('CAPELLA')
    currentQuery = currentQuery.replace(CapellaRegex, '')
  }

  const UmbraRegex = /\bumbra\b/i
  const UmbraMatch = query.match(UmbraRegex)
  if (UmbraMatch) {
    providers.push('UMBRA')
    currentQuery = currentQuery.replace(UmbraRegex, '')
  }

  const VexcelRegex = /\bvexcel\b/i
  const VexcelMatch = query.match(VexcelRegex)
  if (VexcelMatch) {
    providers.push('VEXCEL')
    currentQuery = currentQuery.replace(VexcelRegex, '')
  }

  const NudlRegex = /\bnudl\b/i
  const NudlMatch = query.match(NudlRegex)
  if (NudlMatch) {
    providers.push('NUDL')
    currentQuery = currentQuery.replace(NudlRegex, '')
  }

  const GegdRegex = /\bg-?egd\b/i
  const GegdMatch = query.match(GegdRegex)
  if (GegdMatch) {
    providers.push('GEGD')
    currentQuery = currentQuery.replace(GegdRegex, '')
  }

  const providersFilter: ProviderApiFilterValue = {
    type: 'PROVIDER',
    ids: providers,
  }

  if (providers.length > 0) {
    return [providersFilter, currentQuery]
  }

  return [undefined, currentQuery]
}

/**
 * Extract quality information from a query string.
 * @param {string } query - The user's query.
 * @returns {[QualityApiFilterValue, string]} Returns formatted quality string.
 */
export function parseQuality(
  query: string,
): [QualityApiFilterValue | undefined, string] {
  const qualityFilter: QualityApiFilterValue = {
    type: 'QUALITY',
    gsd: '5000',
  }
  let currentQuery = query

  const qualityRegex =
    /(<|>\s*)?(\d{1,2})(cm|m|centimeter|centimeters|meter|meters)\b/i
  const qualityRegexMatch = query.match(qualityRegex)
  if (qualityRegexMatch) {
    // TODO: Re-enable this if we want to support > and < going forward
    // const operator = qualityRegexMatch[1]!
    let digits = Number.parseInt(qualityRegexMatch[2])
    const units = qualityRegexMatch[3]
    currentQuery = currentQuery.replace(qualityRegex, '')

    if (units == 'cm') {
      digits = digits / 100
      qualityFilter.gsd = digits.toString()
      return [qualityFilter, currentQuery]
    } else {
      qualityFilter.gsd = digits.toString()
      return [qualityFilter, currentQuery]
    }
  }
  return [undefined, currentQuery]
}

/**
 * Extract cloud cover information from a query string.
 * @param {string } query - The user's query.
 * @returns {[CloudCoverApiFilterValue, string]} Returns formatted quality string.
 */
export function parseCloudCover(
  query: string,
): [CloudCoverApiFilterValue | undefined, string] {
  const cloudCoverFilter: CloudCoverApiFilterValue = {
    type: 'CLOUDCOVER',
    coverage: 100,
  }
  let currentQuery = query

  const cloudCoverRegex = /(\d{1,2})\s*%\s*(cloud cover)?/i
  const cloudCoverRegexMatch = query.match(cloudCoverRegex)
  if (cloudCoverRegexMatch) {
    const coverage = Number.parseInt(cloudCoverRegexMatch[1]!)
    cloudCoverFilter.coverage = coverage
    currentQuery = currentQuery.replace(cloudCoverRegex, '')
    return [cloudCoverFilter, currentQuery]
  }
  return [undefined, currentQuery]
}

/**
 * Extract nadir angle information from a query string.
 * @param {string } query - The user's query.
 * @returns {[NadirApiFilter, string]} Returns formatted quality string.
 */
export function parseNadir(
  query: string,
): [NadirApiFilterValue | undefined, string] {
  let currentQuery = query
  const nadirRegex = /(\d{1,2})\s*(?:degrees?|°)\s*(?:(?:off|from)? nadir)?/i
  const nadirRegexMatch = query.match(nadirRegex)
  if (nadirRegexMatch?.[1]) {
    const nadirFilter: NadirApiFilterValue = {
      type: 'NADIR',
      angle: nadirRegexMatch[1],
    }
    currentQuery = currentQuery.replace(nadirRegex, '')
    return [nadirFilter, currentQuery]
  }
  return [undefined, currentQuery]
}

/**
 * Extract orientation information from a query string.
 * @param {string } query - The user's query.
 * @returns {[OrientationApiFilterValue, string]} Returns formatted quality string.
 */
export function parseOrientation(
  query: string,
): [OrientationApiFilterValue | undefined, string] {
  const orientationFilter: OrientationApiFilterValue = {
    type: 'ORIENTATION',
    args: [],
  }
  let currentQuery = query

  const orientations: { start: number; end: number }[] = []
  const orientationRegex =
    /((north(\s*|-)east|north(\s*|-)west|south(\s*|-)east|south(\s*|-)west|north|south|east|west)|(\s+(NE|NW|SE|SW|N|S|E|W)(?!\w)))(\s|-)facing/i
  const orientationRegexMatch = query.match(orientationRegex)
  if (orientationRegexMatch) {
    currentQuery = currentQuery.replace(orientationRegex, '')
    const formattedMatch = (orientationRegexMatch as string[])[1]!
      .toLowerCase()
      .replace(/\W|((\s|-)facing)/, '')
    switch (formattedMatch) {
      case 'north':
      case 'n':
        orientations.push({ start: 0, end: 22.5 }, { start: 337.5, end: 360 })
        break
      case 'northeast':
      case 'ne':
        orientations.push({ start: 22.5, end: 67.5 })
        break
      case 'east':
      case 'e':
        orientations.push({ start: 67.5, end: 112.5 })
        break
      case 'southeast':
      case 'se':
        orientations.push({ start: 112.5, end: 157.5 })
        break
      case 'south':
      case 's':
        orientations.push({ start: 157.5, end: 202.5 })
        break
      case 'southwest':
      case 'sw':
        orientations.push({ start: 202.5, end: 247.5 })
        break
      case 'west':
      case 'w':
        orientations.push({ start: 247.5, end: 292.5 })
        break
      case 'northwest':
      case 'nw':
        orientations.push({ start: 292.5, end: 337.5 })
        break
    }
  }

  if (orientations.length > 0) {
    orientationFilter.args = orientations
    return [orientationFilter, currentQuery]
  }
  return [undefined, currentQuery]
}

/**
 * Extract keywords from a query string.
 * @param {string } query - The user's query.
 * @returns {KeywordApiFilterValue} Returns keywords string array.
 */
export function parseKeywords(
  query: string,
): KeywordApiFilterValue | undefined {
  const keywordFilter: KeywordApiFilterValue = {
    type: 'KEYWORD',
    keywords: [],
  }

  // Tokenize the query into words
  const words = query.split(/\s+/).filter((word) => word.trim() !== '')

  // Remove common words
  const stopWords = new Set([
    'stop',
    'the',
    'to',
    'and',
    'a',
    'in',
    'it',
    'is',
    'I',
    'that',
    'had',
    'on',
    'for',
    'were',
    'was',
  ])
  const filteredWords = words.filter(
    (word) => !stopWords.has(word.toLowerCase()),
  )

  keywordFilter.keywords.push(...filteredWords)

  return keywordFilter
}

/**
 * Extract information from a query string.
 * @param {string} query - The query string to be parsed.
 * @returns {ExtractionResult} The extracted information.
 */
export function parseSearchQuery(query: string): ExtractionResult {
  const result: ExtractionResult = {}

  // Validate input
  if (typeof query !== 'string') {
    result.error = 'Invalid input'
    return result
  }

  // Placeholders for the values extracted from the query
  const [dateRange, currentDateRangeQuery] = parseDateRange(query)
  result.dateRange = dateRange

  // Destructure the values returned by parseLocation into the result object
  const { location, locationType, currentLocationQuery } = parseLocation(
    currentDateRangeQuery,
  )
  result.location = location
  result.locationType = locationType

  const [sensors, currentSensorsQuery] = parseSensors(currentLocationQuery)
  result.sensors = sensors

  const [providers, currentProvidersQuery] = parseProviders(currentSensorsQuery)
  result.providers = providers

  const [quality, currentQualityFilter] = parseQuality(currentProvidersQuery)
  result.quality = quality

  const [cloudCover, currentCloudCoverQuery] =
    parseCloudCover(currentQualityFilter)
  result.cloudCover = cloudCover

  const [angle, currentNadirQuery] = parseNadir(currentCloudCoverQuery)
  result.angle = angle

  const [orientations, currentOrientationQuery] =
    parseOrientation(currentNadirQuery)
  result.orientations = orientations

  result.keywords = parseKeywords(currentOrientationQuery)

  return result
}
