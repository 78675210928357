/* eslint-disable camelcase */
export const hazardRiskSample7434 = {
  title: 'Resource from Hazard Hub',
  subtitle: '',
  geometry: {
    coordinates: ['0', '0'],
    type: 'Point',
  },
  documentId: '7f338021-92c0-4dec-9d51-e8b793fab151',
  score: 0.5,
  description: 'Resource from Hazard Hub',
  category: 'DATA',
  thumbnail: '',
  filetype: '',
  authoredOn: '2023-10-04T16:09:28',
  source: 'HAZARD',
  tags: null,
  license: 'unknown',
  properties: {
    aais_fire_protection_class_params: {
      available_water_type: 'Hydrant',
      class: 'P2, PROTECTED 2',
      hydrant_fire_station_distance:
        "<= 1000' from Hydrant or <= 1000' from Perennial water & > 1 & <= 2 Drive Miles from Fire Station",
    },
    address: '7434 SW 49th Ct, Portland, OR, 97219-1492',
    aggravated_assault: {
      score: 'C',
      text: 'Average aggravated assault rate',
      value: 103,
    },
    airport_noise: {
      decibels: null,
      score: null,
      text: null,
    },
    asbestos_sources_params: {
      desc: '> 12 miles from known asbestos source',
      score: 'A',
      site_name: null,
    },
    aspect_risk: {
      aspect: '311.3 degrees',
      direction: 'NorthWest',
      score: 'B',
    },
    base_flood_elevation: {
      distance: {
        units: 'miles',
        value: 1.0568650861631996,
      },
      elevation: {
        units: 'feet',
        value: 273,
      },
      meter: 1701,
      text: '1.057 MILES from BFE = 273 feet',
    },
    brownfield: {
      address: null,
      registry_number: null,
      score: null,
      site_name: null,
      text: null,
    },
    burglary: {
      score: 'B',
      text: 'Low burglary rate',
      value: 46,
    },
    cal_fire: {
      haz_class_local: null,
      haz_class_state: null,
      haz_code_local: null,
      haz_code_state: null,
    },
    cbrs_params: {
      cbrs_type: null,
      designation: 'OUT',
      fast_acres: null,
      fi_date: null,
      name: null,
      shore_mile: null,
      su_date: null,
      unit: null,
      wet_acres: null,
    },
    census_block: {
      block: '1012',
      block_group: '410510066011',
      county_fips_code: '051',
      geoid: '410510066011012',
      state_fips_code: '41',
      tract: '006601',
    },
    clandestine_lab: {
      address: '7125 Sw 29th Ave',
      city: 'Portland',
      date: '2004/10/05',
      distance: '0.95 miles',
      number_within_5_miles: 16,
      state: 'OR',
      zip: '97219',
    },
    coast_distance: {
      beach_distance: {
        units: 'miles',
        value: 59.61,
      },
      distance: 52.53,
      high_res_distance: {
        units: 'miles',
        value: 52.53,
      },
      low_res_distance: {
        units: 'miles',
        value: 44.86,
      },
      units: 'miles',
    },
    convection_storm: {
      score: 'A',
      text: 'Very Low',
    },
    convection_storm_per_decade: {
      value: '18',
    },
    crime: {
      score: 'B',
      text: 'Low crime rate',
      value: 71,
    },
    designated_fault: {
      score: 'A',
      text: 'Not in USGS Designated Eastern Fault Zone',
    },
    distance_to_significant_flood_params: {
      distance_to_100yr_floodplain: null,
      elevation100: null,
      elevation_difference_100: null,
      elevation_in_feet: {
        value: 373.16,
      },
    },
    drive_distance_fire_station: {
      distance: 1.45,
      units: 'miles',
    },
    drive_time_fire_station: {
      duration: 6,
      units: 'minutes',
    },
    drought: {
      score: 'C',
      text: 'Abnormally Dry - Increases the risk of wildfire at this location',
    },
    drought_frequency_index: {
      desc: '411 weeks of drought over the past 21 years',
      pct: '38.0%',
      score: 'C',
      text: 'Moderate',
    },
    drug_alcohol_deaths: {
      score: 'B',
      text: 'LOW',
    },
    drug_alcohol_deaths_deaths: {
      value: '5679',
    },
    drug_alcohol_deaths_rate100k: {
      value: '710.1',
    },
    drug_alcohol_deaths_score: {
      value: 'LOW',
    },
    earthquake: {
      MMI: 'VII',
      peak_ground_acceleration: 30,
      richter_scale: '4.6-5.2',
      score: 'C',
      shake: 'Very Strong',
      text: 'Moderate Damage',
    },
    enhanced_hail_params: {
      count_within_25_miles_for_last_decade: 3,
      haddrisk: 0.0034,
      historical_hail_events: {
        hail_rad:
          '95% chance of damaging hail occurrence in 10 years in 14.43 mile radius',
      },
      hpctrisk: '0.34%',
      nearest_hail_incident: {
        crop_dam: '0.00K',
        deaths: '0',
        distance_miles: 4.59,
        inches: '0.63',
        injuries: '0',
        prop_dam: '1.00K',
        type: 'Hail',
        year: '2007',
      },
      num_of_damaging_hail_incidents_within_25_miles_since_1996: 16,
      scale: 19,
      score: 'B',
      text: 'Low',
    },
    enhanced_hazardhub_flood: {
      score: 'A',
      text: 'Extremely Low risk of flood damage',
    },
    enhanced_hazardhub_flood_params: {
      diff: 78,
      dist: 653,
      elev_pt: 373,
      elevnff: 295,
      lines_descrip: 'Perennial Stream',
      lines_diff: 78,
      lines_distance: 653,
      lines_name: null,
      lines_score: 'A',
      polygon_descrip: 'Lake, Pond, Reservoir, Other',
      polygon_name: null,
      polygon_type: 'Lake, Pond, Reservior, Other',
      polygons_diff: null,
      polygons_distance: null,
      polygons_score: 'A',
    },
    enhanced_hh_pfs: {
      average_pct_loss: 0.2688,
      estimated_loss_amt: 247801,
      value: 'HH1',
    },
    enhanced_hurricane_params: {
      annual_chance_event_less_than_950_mb: null,
      annual_chance_event_more_than_50_knots: null,
      avg_barometric_press_mb: null,
      avg_wind_speed_knots: null,
      max_wind_speed_knots: null,
      min_barometric_press_mb: null,
      name_year_max_wind: null,
      name_year_min_press: null,
      num_cat_0_hurricanes: null,
      num_cat_1_hurricanes: null,
      num_cat_2_hurricanes: null,
      num_cat_3_hurricanes: null,
      num_cat_4_hurricanes: null,
      num_cat_5_hurricanes: null,
      num_disturbances: null,
      num_events_gt_50_knots: null,
      num_events_press_lt_950_mb: null,
      num_extratropical_systems: null,
      num_hurricanes: null,
      num_lows: null,
      num_subtropical_depressions: null,
      num_subtropical_storms: null,
      num_tropical_depressions: null,
      num_tropical_storms: null,
      num_tropical_waves: null,
      scale: null,
      score: null,
      text: null,
      total_events_since_1980: null,
    },
    enhanced_lightning_params: {
      laddrisk: 0.00022,
      lpctrisk: '0.02204%',
      lsqmi: '95% chance of 0.141083 ground strikes in 1 year per square mile',
      scale: 24,
      score: 'A',
      strikes_yr: '44 ground lightning strikes within 10 miles last year',
      text: 'Very Low',
    },
    enhanced_tornado_params: {
      count_within_25_miles_for_last_decade: 11,
      historical_tornado_events: {
        tornado_rad:
          '50% chance of damaging tornado occurrence in 10 years in 7.54 mile radius',
      },
      nearest_tornado_incident: {
        crop_dam: null,
        deaths: '0',
        distance_miles: 7.41,
        fscale: 'EF0',
        injuries: '0',
        prop_dam: '21.00K',
        type: 'Tornado',
        year: '2019',
      },
      num_of_damaging_tornado_incidents_within_25_miles_since_1996: 17,
      scale: 28,
      score: 'B',
      taddrisk: 0.003613,
      text: 'Low',
      tpctrisk: '0.3613%',
    },
    enhanced_wildfire: {
      fire_season_precip_risk: {
        desc: 'Long run monthly average inches of precipitation during the fire season',
        monthly_avg_precip: 1.416,
        score: 9,
      },
      katabatic_wind_risk: {
        desc: 'In a region prone to strong seasonal Katabatic winds',
        name: 'Cascades Katabatic Special Wind Region',
        score: 3,
      },
      landcover_risk: {
        desc: 'Distance to significant wildfire fuel load risk',
        detail: {
          area_desc: 'Non-Interface/Non-Intermix',
          site_desc: 'Developed, Low Intensity',
        },
        proximate_landcover_areas: [
          {
            distance_to: 85.36,
            pct_wildland: 0.27,
            risk_category: 'Moderate',
          },
          {
            distance_to: 278.19,
            pct_wildland: 0.52,
            risk_category: 'High',
          },
        ],
        score: 7,
      },
      risk_scores: {
        base: 3,
        composite: 22,
      },
      score: 'B',
      text: 'Low',
      vegetation_burn_site_risk: {
        desc: '> 1 mile from vegetation burn(s) occurring over the last 4 years',
        score: 0,
        within_half_mile: 0,
        within_one_mile: 0,
      },
      wildfire_perimeter_risk: {
        desc: '> 1 mile from historic wildfire perimeter',
        proximate_wildfire_perimeters: [],
        score: 0,
      },
    },
    enhanced_wind_params: {
      count_within_25_miles_for_last_decade: 16,
      historical_wind_events: {
        wind_rad:
          '95% chance of damaging wind occurrence in 10 years in a 6.25 mile radius',
      },
      nearest_wind_incident: {
        crop_dam: '0.00K',
        deaths: '0',
        distance_miles: 3.85,
        injuries: '0',
        prop_dam: '1.20K',
        type: 'Thunderstorm Wind',
        wind_spd: '56.0',
        year: '2009',
      },
      num_of_damaging_wind_incidents_within_25_miles_since_1996: 28,
      scale: 22,
      score: 'B',
      text: 'Low',
      waddrisk: 0.00595,
      wpctrisk: '0.595%',
    },
    fault_earthquake: {
      distance: '1.28 miles',
      name: 'Oatfield fault',
      score: 'C',
      text: 'Between 1 and 5 Miles to Known Earthquake Fault',
    },
    fema_all_flood: {
      score: null,
      text: 'Not covered by FEMA digital maps. Check HazardHub-Flood score',
    },
    fema_all_flood_params: {
      ar_revert: null,
      ar_subtrv: null,
      bfe_revert: -9999,
      dep_revert: -9999,
      depth: -9999,
      dfirm_id: '41051C',
      dual_zone: null,
      fld_ar_id: '41051C_2302',
      fld_zone: 'AREA NOT INCLUDED',
      len_unit: null,
      sfha_tf: 'F',
      source_cit: '41051C_FIRM1',
      static_bfe: -9999,
      study_typ: 'NP',
      v_datum: null,
      vel_unit: null,
      velocity: -9999,
      version_id: '1.1.1.0',
      zone_subty: null,
    },
    fema_claims: {
      '1999': 0,
      '2000': 0,
      '2001': 0,
      '2002': 0,
      '2003': 0,
      '2004': 0,
      '2005': 0,
      '2006': 0,
      '2007': 0,
      '2008': 0,
      '2009': 0,
      '2010': 0,
      '2011': 0,
      '2012': 0,
      '2013': 0,
      '2014': 0,
      '2015': 0,
      '2016': 0,
      '2017': 0,
      '2018': 0,
      '2019': 0,
      '2020': 0,
      '2021': 0,
      total: 0,
    },
    fema_firm_date: {
      cid: '410183',
      community_name: 'Portland, City of',
      crs_entry_date: '10/1/2001',
      current_class: '5',
      current_effective_date: '10/1/2020',
      current_effective_date_flood_map: '11/26/2010',
      date_entered_program: '10/15/1980',
      initial_firm_id_date: '10/15/1980',
      initial_flood_hazard_bounday_map_id_date: '1/10/1975',
      name: 'PORTLAND',
      percent_discount_for_non_sfha: '10',
      percent_discount_for_sfha: '25',
      status: 'C',
      type: 'CITY OF',
    },
    fema_firm_panels: {
      effective_date: '2016/11/04',
      firm_panel: '41067C0551E',
    },
    fema_map_params: {
      fema_flood_map_url:
        'https://msc.fema.gov/portal/search?AddressQuery=-122.72638%2C%2045.47148#searchresultsanchor',
    },
    fire_protection: {
      aais_proxy: 'P2',
      score: 'B',
      text: 'Within Municipality & > 1 & <= 2 Drive Miles from Fire Station',
    },
    fire_station_type: {
      value: 'Full Time',
    },
    fire_suppression: {
      category: 2,
      grade: 'B',
      score: 117,
      text: 'Well Protected',
    },
    fl_codes: {
      name: null,
      terr: null,
    },
    fl_construction_line: 'OUT',
    forcible_rape: {
      score: 'B',
      text: 'Low forcible rape rate',
      value: 50,
    },
    forcible_robbery: {
      score: 'B',
      text: 'Low forcible robbery rate',
      value: 61,
    },
    fracking_earthquake: {
      score: null,
      text: null,
    },
    frozen_pipe_index: {
      index: 18.9,
      score: 'A',
      text: 'Very Low',
    },
    google_maps_url:
      'https://www.google.com/maps/search/?api=1&query=45.471480,-122.726380',
    ground_snow_load: {
      desc: '1.1 days > 0.0 and < 9.1 lbs per sq foot',
      score: 'B',
      text: 'Low',
    },
    gun_dealers: {
      city: 'PORTLAND',
      cummulative_0_1_mile: 1,
      cummulative_0_2_mile: 3,
      cummulative_0_3_mile: 8,
      cummulative_0_4_mile: 21,
      cummulative_0_5_mile: 43,
      distance: '0.98 miles',
      license: '993051074C07074',
      name: 'BALLISTIC ARTS',
      ring_0_1_mile: 1,
      ring_1_2_mile: 2,
      ring_2_3_mile: 5,
      ring_3_4_mile: 13,
      ring_4_5_mile: 22,
      street: '4620 SW BEAVERTON HILLSDALE HIGHWAY SUITE C2',
    },
    hail_ring_params: {
      cummulative_0_1_mile: 0,
      cummulative_0_2_mile: 0,
      cummulative_0_3_mile: 0,
      cummulative_0_4_mile: 0,
      cummulative_0_5_mile: 0,
      ring_0_1_mile: 0,
      ring_1_2_mile: 0,
      ring_2_3_mile: 0,
      ring_3_4_mile: 0,
      ring_4_5_mile: 0,
    },
    hardiness: {
      avextmin: '10 to 20 F',
      avextmin5: '15 to 20 F',
      zone: '8',
      zoneseg: '8b',
    },
    hazardhub_catastrophic_flood: {
      score: 'A',
      text: 'Extremely Low risk of flood damage',
    },
    hazardhub_catastrophic_flood_params: {
      cat_diff: 78,
      cat_dist: 653,
      cat_elev_pt: 373,
      cat_elevnff: 295,
      cat_lines_descrip: 'Perennial Stream',
      cat_lines_diff: 78,
      cat_lines_distance: 653,
      cat_lines_name: null,
      cat_lines_score: 'A',
      cat_polygon_descrip: 'Lake, Pond, Reservoir, Other',
      cat_polygon_name: null,
      cat_polygon_type: 'Lake, Pond, Reservior, Other',
      cat_polygons_diff: null,
      cat_polygons_distance: null,
      cat_polygons_score: 'A',
    },
    hh_elevation: {
      aspect: '311.3 degrees',
      elevation: '373.16 feet',
      slope: '10.71 degrees',
    },
    hh_fpc_code: {
      value: 'P2',
    },
    hh_snow_load: {
      desc: 'Zero chance of structural roof failure due to snow load',
      scale: 0,
      score: 'A',
      text: 'None',
    },
    hospital: {
      address: '3710 SW U.S. VETERANS HOSPITAL RD',
      distance: '2.72 miles',
      helipad: 'No',
      name: 'PORTLAND VA MEDICAL CENTER',
      number_of_hospitals_within_10_miles: 14,
      number_of_hospitals_within_5_miles: 6,
      state: 'OR',
      type: 'MILITARY',
    },
    hydrant: {
      score: 'A',
      text: '< 250 Feet from Reported Hydrant',
    },
    hydrant_params: {
      distance_from_nearest_hydrant: '< 250 Feet',
      hydrants_within_1000_ft: 14,
    },
    ice_dam_index: {
      index: 24.4,
      score: 'A',
      text: 'Very Low',
    },
    landslide: {
      score: null,
    },
    larceny: {
      score: 'C',
      text: 'Average larceny rate',
      value: 91,
    },
    lat: 45.47148,
    lava_flow: {
      desc: null,
      score: null,
      text: null,
      zone: null,
    },
    liquor_license: {
      city: 'Portland',
      cummulative_0_1_mile: 29,
      cummulative_0_2_mile: 117,
      cummulative_0_3_mile: 291,
      cummulative_0_4_mile: 924,
      cummulative_0_5_mile: 2150,
      distance: '0.24 miles',
      license_type: 'L',
      name: 'Maplewood Coffee And Tea',
      ring_0_1_mile: 29,
      ring_1_2_mile: 88,
      ring_2_3_mile: 174,
      ring_3_4_mile: 633,
      ring_4_5_mile: 1226,
      street: '5206 Sw Custer St',
    },
    lng: -122.72638,
    location_type: 'ADDRESS',
    loma_params: {
      case: null,
      cid: null,
      community_name: null,
      date_ended: null,
      determination: null,
      doc_url: null,
      lot_type: null,
      outcome: null,
      project_cat: null,
      project_name: null,
      reval_stat: null,
      status: null,
    },
    lomr_params: {
      case_no: null,
      dfirm_id: null,
      eff_date: null,
      lomr_id: null,
      source_cit: null,
      status: null,
    },
    match_level: 'houseNumber',
    match_provider: 'primary',
    match_score: 1,
    match_type: 'rooftop',
    max_wind_params: {
      max_recorded_wind_mph: 83,
      max_wind_scale: 17,
    },
    mine_subsidence: {
      score: null,
      text: null,
    },
    mold_index: {
      index: 3589,
      score: 'D',
      text: 'High',
    },
    motor_vehicle_theft: {
      score: 'B',
      text: 'Low motor vehicle theft rate',
      value: 79,
    },
    mudslide_risk: {
      PCL: null,
      score: null,
      text: null,
    },
    municipal_boundary: {
      gid: '720',
      namelsad: 'Portland city',
      placefp: '11471',
      type: 'Incorporated Place',
    },
    murder: {
      score: 'B',
      text: 'Low murder rate',
      value: 62,
    },
    nearest_fire_station: {
      distance: 1.13,
      drive_distance_fire_station: {
        distance: 1.45,
        units: 'miles',
      },
      drive_duration_units: 'minutes',
      drive_duration_value: 6,
      lat: 45.461985,
      lng: -122.707534,
      response_area: 'Portland Fire & Rescue',
      title: 'Portland Fire And Rescue Station 18 - Multnomah Village',
      type: 'Full Time',
      units: 'miles',
    },
    nearest_fire_station_2: {
      distance: 1.72,
      drive_distance_fire_station: {
        distance: 2.87,
        units: 'miles',
      },
      drive_duration_units: 'minutes',
      drive_duration_value: 9,
      lat: 45.480345,
      lng: -122.693364,
      response_area: 'Portland Fire & Rescue',
      title: 'Portland Fire And Rescue Station 5 - Hillsdale',
      type: 'Full Time',
      units: 'miles',
    },
    nearest_fire_station_3: {
      distance: 2.18,
      drive_distance_fire_station: {
        distance: 2.92,
        units: 'miles',
      },
      drive_duration_units: 'minutes',
      drive_duration_value: 7,
      lat: 45.463465,
      lng: -122.682934,
      response_area: 'Portland Fire & Rescue',
      title: 'Portland Fire And Rescue Station 10 - Burlingame',
      type: 'Full Time',
      units: 'miles',
    },
    nuclear_site: {
      score: null,
    },
    nuclear_site_nearest: {
      distance: {
        units: null,
        value: null,
      },
      name: {
        value: null,
      },
      reactors: {
        value: null,
      },
      type: {
        value: null,
      },
    },
    number_of_fire_stations_within_10_miles: 64,
    number_of_fire_stations_within_5_miles: 21,
    perennial_water: {
      answer: 'no',
      name: null,
      text: 'More than 1000 feet from Perennial Water',
    },
    pfa: {
      score: null,
      site_name: null,
      text: null,
      type: null,
    },
    police_stations: {
      distance: '2.63 miles',
      name: 'OREGON HEALTH & SCIENCE UNIVERSITY DEPT OF PUBLIC SAFETY',
      number_within_5_miles: 8,
    },
    potential_catastrophic_precipitation: '10.86 Inches',
    potential_maximum_precipitation: '5.43 Inches',
    premium_tax: {
      text: null,
      value: null,
    },
    premium_tax_ky: {
      text: null,
      value: null,
    },
    premium_tax_ky_params: {
      county_all_others: null,
      county_auth_ta: null,
      county_cas_li_onl: null,
      county_dt_vint: null,
      county_fips: null,
      county_fips_ta: null,
      county_fire_per: null,
      county_gnis: null,
      county_health: null,
      county_in_marine: null,
      county_life: null,
      county_min_tax: null,
      county_name: null,
      county_prem_hh_id: null,
      county_state: null,
      county_tax_cd_1: null,
      county_tax_cd_2: null,
      county_vehicle: null,
      municipal_all_others: null,
      municipal_auth_ta: null,
      municipal_cas_li_onl: null,
      municipal_county: null,
      municipal_dt_vint: null,
      municipal_fips: null,
      municipal_fips_ta: null,
      municipal_fire_per: null,
      municipal_gnis: null,
      municipal_health: null,
      municipal_in_marine: null,
      municipal_life: null,
      municipal_min_tax: null,
      municipal_name: null,
      municipal_notes: null,
      municipal_prem_hh_id: null,
      municipal_state: null,
      municipal_tax_cd_1: null,
      municipal_tax_cd_2: null,
      municipal_vehicle: null,
    },
    premium_tax_params: {
      auth_ta: null,
      city: null,
      city_des: null,
      county: null,
      dt_vint: null,
      fips: null,
      fips_ta: null,
      fire_zone: null,
      gnis: null,
      prem_hh_id: null,
      tax_cd_1: null,
      tax_cd_2: null,
    },
    radon: {
      score: 'C',
      text: 'Predicted average indoor radon screening levels from 2 to 4 pCi/L',
    },
    re_tier_counties: {
      value: null,
    },
    school_district: {
      name: 'Portland School District 1J',
    },
    sea_level_rise: {
      flood_at: null,
      text: 'Not in area of potential sea level rise flooding.',
    },
    sinkhole: {
      distance: {
        units: null,
        value: null,
      },
      score: null,
      text: null,
    },
    sinkhole_ring_params: {
      cummulative_0_1_mile: null,
      cummulative_0_2_mile: null,
      cummulative_0_3_mile: null,
      cummulative_0_4_mile: null,
      cummulative_0_5_mile: null,
      ring_0_1_mile: null,
      ring_1_2_mile: null,
      ring_2_3_mile: null,
      ring_3_4_mile: null,
      ring_4_5_mile: null,
    },
    sinkhole_risk_params: {
      climate: null,
      closed_depression_grade: null,
      closed_depression_risk: null,
      karst_type: null,
      rocktype: null,
      sinkhole_distance: {
        units: null,
        value: null,
      },
      sinkhole_point_grade: null,
      sinkhole_susceptibility_grade: 'B',
      sinkhole_susceptibility_risk: 'Minimal Sinkhole Risk',
    },
    slope_risk: {
      description: 'High',
      score: 'D',
      slope: '10.71 degrees',
    },
    soil_shear_velocity: {
      units: 'm/s',
      vs30: 538,
    },
    state: 'OR',
    state_county: {
      jurisdiction: 'Multnomah County',
      state: 'Oregon',
      state_and_county_FIPS_code: '41051',
    },
    superfund: {
      address: null,
      registry_number: null,
      score: null,
      site_name: null,
      text: null,
    },
    surge_max: {
      category: 'N/A',
      category_1: 'N/A',
      category_2: 'N/A',
      category_3: 'N/A',
      category_4: 'N/A',
      desc: 'N/A',
      desc_1: 'N/A',
      desc_2: 'N/A',
      desc_3: 'N/A',
      desc_4: 'N/A',
      potential_inundation_level: 'N/A',
      potential_inundation_level_1: 'N/A',
      potential_inundation_level_2: 'N/A',
      potential_inundation_level_3: 'N/A',
      potential_inundation_level_4: 'N/A',
      score: 'N/A',
      text: 'N/A',
    },
    tornado_ring_params: {
      cummulative_0_1_mile: 0,
      cummulative_0_2_mile: 0,
      cummulative_0_3_mile: 0,
      cummulative_0_4_mile: 0,
      cummulative_0_5_mile: 0,
      ring_0_1_mile: 0,
      ring_1_2_mile: 0,
      ring_2_3_mile: 0,
      ring_3_4_mile: 0,
      ring_4_5_mile: 0,
    },
    toxic_release_facilities: {
      score: null,
      text: null,
    },
    toxic_release_facilities_params: {
      address: {
        value: null,
      },
      carcinogen: {
        value: null,
      },
      chemical: {
        value: null,
      },
      city: {
        value: null,
      },
      distance: {
        units: null,
        value: null,
      },
      fac_id: {
        value: null,
      },
      fed_id: {
        value: null,
      },
      industry: {
        value: null,
      },
      name: {
        value: null,
      },
      state: {
        value: null,
      },
      zipcode: {
        value: null,
      },
    },
    tsunami: {
      score: 'A',
      text: 'Outside Tsunami Evacuation Zone',
    },
    tx_codes: {
      code: null,
      com: null,
      county: null,
    },
    urbanicity: {
      desc: 'Settlements of 50,000 or more people',
      name: 'Portland, OR--WA Urbanized Area',
      type: 'Urbanized Area',
    },
    urgent_care: {
      address: '9250 SOUTHWEST HALL BOULEVARD',
      distance: '2.61 miles',
      name: 'THE PORTLAND CLINIC',
      state: 'OR',
      type: 'URGENT MEDICAL CARE CENTER',
    },
    ust: {
      score: 'D',
      text: '1650 Feet from Underground Storage Tank Facility',
    },
    ust_facilities_leaking_quarter_mile_radius: {
      value: null,
    },
    ust_nearest: {
      address: {
        value: '4419 SW MULTNOMAH BLVD',
      },
      city: {
        value: 'PORTLAND',
      },
      fac_id: {
        value: '859',
      },
      leaking: {
        value: 'Yes',
      },
      name: {
        value: 'C & J ENTERPRISES OF OREGON, INC.',
      },
      num_tanks: {
        value: '9',
      },
      residential: {
        value: 'N/A',
      },
      state: {
        value: 'OR',
      },
      zipcode: {
        value: null,
      },
    },
    ust_nearest_leaking: {
      address: {
        value: '4419 SW MULTNOMAH BLVD',
      },
      city: {
        value: 'PORTLAND',
      },
      fac_id: {
        value: '859',
      },
      leaking: {
        value: 'Yes',
      },
      name: {
        value: 'C & J ENTERPRISES OF OREGON, INC.',
      },
      num_tanks: {
        value: '9',
      },
      residential: {
        value: 'N/A',
      },
      state: {
        value: 'OR',
      },
      text: '1650 Feet from Leaking Underground Storage Tank Facility',
      zipcode: {
        value: null,
      },
    },
    ust_tanks_num_in_1_mile_radius: {
      value: 56,
    },
    ust_tanks_num_in_half_mile_radius: {
      value: 19,
    },
    ust_tanks_num_in_quarter_mile_radius: {
      value: null,
    },
    volcano: {
      score: 'D',
      text: 'High',
    },
    volcano_nearest: {
      volcano_distance: {
        units: 'miles',
        value: 42.09,
      },
      volcano_elev: {
        value: 1329,
      },
      volcano_name: {
        value: 'West Crater',
      },
      volcano_status: {
        value: 'Radiocarbon',
      },
      volcano_type: {
        value: 'Volcanic field',
      },
    },
    water_type: {
      value: 'Hydrant',
    },
    weather_params: {
      annual_average_days_less_than_0: '0.0 Days',
      annual_average_days_less_than_10: '0.0 Days',
      annual_average_days_less_than_20: '1.6 Days',
      annual_average_days_more_than_40: '353.0 Days',
      annual_average_days_more_than_50: '287.5 Days',
      annual_fall_days_less_than_0: '0.0 Days',
      annual_fall_days_less_than_10: '0.0 Days',
      annual_fall_days_less_than_20: '0.0 Days',
      annual_fall_days_less_than_32: '1.5 Days',
      annual_spring_days_less_than_0: '0.0 Days',
      annual_spring_days_less_than_10: '0.0 Days',
      annual_spring_days_less_than_20: '0.0 Days',
      annual_spring_days_less_than_32: '0.8 Days',
      annual_winter_days_less_than_0: '0.0 Days',
      annual_winter_days_less_than_10: '0.0 Days',
      annual_winter_days_less_than_20: '1.5 Days',
      annual_winter_days_less_than_40: '58.4 Days',
      average_annual_precipitation: '42.9 Inches',
      average_annual_snowfall: '2.8 Inches',
      average_annual_temperature_max: '62.5 Degrees F',
      average_annual_temperature_min: '46.6 Degrees F',
      average_days_snowfall_greater_than_10_inches: '0.0 Days',
      average_days_snowfall_greater_than_1_inch: '1.1 Days',
      average_fall_snowfall: '0.0 Inches',
      average_spring_snowfall: '0.1 Inches',
      average_winter_snowfall: '2.7 Inches',
      avg_days_snow_depth_above_10_in: '0.0 Days',
      avg_days_snowfall_above_1_in: '1.1 Days',
      avg_num_days_below_32_degrees: '2.0 Days',
      avg_num_winter_days_below_32_degrees: '15.4 Days',
      cooling_degree_days: '413.0 Degrees F',
      fall_days_snow_depth_greater_than_10_inches: '0.0 Days',
      fall_days_snow_depth_greater_than_1_inch: '0.0 Days',
      fall_days_snow_depth_greater_than_3_inches: '0.0 Days',
      fall_days_snow_depth_greater_than_5_inches: '0.0 Days',
      fall_days_with_max_temp_less_than_32: '0.0 Days',
      fall_diurnal_range: '15.4 Degrees F',
      heating_degree_days: '4235.0 Degrees F',
      spring_days_snow_depth_greater_than_10_inches: '0.0 Days',
      spring_days_snow_depth_greater_than_1_inch: '0.1 Days',
      spring_days_snow_depth_greater_than_3_inches: '0.0 Days',
      spring_days_snow_depth_greater_than_5_inches: '0.0 Days',
      spring_days_with_max_temp_less_than_32: '0.0 Days',
      spring_diurnal_range: '16.7 Degrees F',
      winter_days_snow_depth_greater_than_10_inches: '0.0 Days',
      winter_days_snow_depth_greater_than_1_inch: '1.0 Days',
      winter_days_snow_depth_greater_than_3_inches: '0.3 Days',
      winter_days_snow_depth_greater_than_5_inches: '0.1 Days',
      winter_days_with_max_temp_less_than_32: '2.0 Days',
      winter_diurnal_range: '10.1 Degrees F',
    },
    wildfire_mitigation_communities: {
      frr: {
        county: null,
        name: null,
        state: null,
      },
      minimal_access: {
        county: null,
        name: null,
        state: null,
        unit: null,
      },
      wfmc: {
        county: null,
        name: null,
        state: null,
      },
    },
    wind_born_debris: {
      score: null,
      terrain: null,
      text: null,
      wbd_area: null,
      year_2001: {
        score: null,
        text: null,
        wbd_area: null,
      },
      year_2007: {
        score: null,
        text: null,
        wbd_area: null,
      },
      year_2010: {
        score: null,
        text: null,
        wbd_area: null,
      },
      year_2014: {
        score: null,
        text: null,
        wbd_area: null,
      },
    },
    wind_pool: {
      name: null,
      score: null,
      text: null,
    },
    wind_region: {
      score: 'B',
      text: 'Strong seasonal wind risks: Cascades Katabatic Special Wind Region',
      text2: null,
    },
    wind_ring_params: {
      cummulative_0_1_mile: 0,
      cummulative_0_2_mile: 0,
      cummulative_0_3_mile: 0,
      cummulative_0_4_mile: 1,
      cummulative_0_5_mile: 2,
      ring_0_1_mile: 0,
      ring_1_2_mile: 0,
      ring_2_3_mile: 0,
      ring_3_4_mile: 1,
      ring_4_5_mile: 1,
    },
    zip: '97219-1492',
  },
  asset: '',
}
