import { Anchor, Box, Group, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { AutoAskWidget } from '@/components/lib/single-address/AutoAskWidget.tsx'
import { useChat } from '@/hooks/use-chat.ts'
import type { PropertyData } from '@/hooks/use-single-address-components.ts'
import type { AttomPermitRecordBuildingPermit } from '@/utils/types/single-address/attom-permit-types.ts'
import type { FC } from 'react'

type PermitPanelProps = {
  propertyData: PropertyData
  permitRecords: AttomPermitRecordBuildingPermit[] | undefined
}
export const PermitPanel: FC<PermitPanelProps> = ({ permitRecords }) => {
  const { sendPrompt } = useChat()

  const [expanded, expandHandlers] = useDisclosure(false)

  const shownRecords = expanded ? permitRecords : permitRecords?.slice(0, 3)

  return (
    <Stack gap={2} w="100%">
      <Group gap="xs">
        <Text fw="bold">Permit History</Text>
        <AutoAskWidget prompt="Please give me a paragraph summarizing the building permits for this property." />
      </Group>
      {shownRecords?.length === 0 && (
        <Text size="sm">Loading permit data...</Text>
      )}
      {shownRecords?.map((r, index) => {
        return (
          <Group key={`permit_stack_${index}`} gap={5} align="start" grow>
            <Stack gap={0} style={{ flex: 0 }}>
              <Box
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: 8,
                  backgroundColor: '#bdbdbd',
                  marginTop: 8,
                }}
              />
              <Box
                style={{
                  marginLeft: 3,
                  marginTop: 8,
                  borderLeft: '2px solid #bdbdbd',
                  height: 100,
                  width: 1,
                }}
              />
            </Stack>
            <Stack gap={1} style={{ flex: 1, maxWidth: 'revert' }}>
              <Text size="sm">{r.effectiveDate}</Text>
              <Box
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #bdbdbd',
                  padding: 12,
                  marginBottom: 32,
                  fontSize: 14,
                }}
              >
                <Stack
                  gap={1}
                  style={{ cursor: 'help' }}
                  onClick={() =>
                    sendPrompt(
                      `Please tell me more about the ${r.type} permit from ${r.effectiveDate}`,
                    )
                  }
                >
                  <Text fw="bold">{r.description}</Text>
                  <Text size="xs">{r.type}</Text>
                  <Text size="xs">{r.subType}</Text>
                </Stack>
              </Box>
            </Stack>
          </Group>
        )
      })}
      {permitRecords && shownRecords && permitRecords.length > 3 && (
        <Anchor size="xs" onClick={() => expandHandlers.toggle()}>
          {expanded ? 'Collapse' : `And ${permitRecords.length - 3} more...`}
        </Anchor>
      )}
    </Stack>
  )
}
