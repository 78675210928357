import { Button } from '@mantine/core'
import type { FC, MouseEventHandler } from 'react'

type MapSearchHereButtonProps = {
  onClick: MouseEventHandler
}

export const MapSearchHereButton: FC<MapSearchHereButtonProps> = ({
  onClick,
}) => {
  return (
    <Button
      variant="filled"
      onClick={onClick}
      size="md"
      p="sm"
      px="sm"
      py="xs"
      fw="normal"
    >
      Search Here
    </Button>
  )
}
