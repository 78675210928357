import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BackgroundImage, Box, em, Stack, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { images } from '@/assets/images'
import { MainSearch } from '@/components/lib/MainSearch.tsx'

import styles from './explore.module.css'

const intervalDuration = 6500
const fadeTransition = 1000
const delayFadeOut = intervalDuration - fadeTransition

export default function Explore() {
  const location = useLocation()
  const isLogIn = location.pathname === '/log-in'
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)

  const [imageIndex, setImageIndex] = useState(0)
  const backgroundRef = useRef<HTMLImageElement>(null)
  const placeholder =
    'Search by any query or sample-data type, address, coordinates, city, property, features...'

  useEffect(() => {
    const backgroundInterval = setInterval(() => {
      setImageIndex((index) => index + 1)
    }, intervalDuration)

    return () => {
      clearInterval(backgroundInterval)
    }
  }, [])

  useLayoutEffect(() => {
    if (backgroundRef.current) {
      const timeout = setTimeout(() => {
        backgroundRef.current?.classList.add(styles['fadeOut'])
      }, delayFadeOut)

      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex])

  return (
    <Box mx="auto" px={{ xs: 'lg', md: 'xl', xl: 190 }} h="100%">
      <Fragment key={imageIndex}>
        <BackgroundImage
          src={images[(imageIndex + 1) % images.length]}
          pos="absolute"
          h="100%"
          top={0}
          left={0}
          style={{
            zIndex: -1,
            backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
          }}
        />
        <BackgroundImage
          src={images[imageIndex % images.length]}
          ref={backgroundRef}
          pos="absolute"
          h="100%"
          top={0}
          left={0}
          style={{
            zIndex: -1,
            opacity: 1,
            backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
          }}
        />
        <div
          style={{
            backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
            position: 'absolute',
            inset: 0,
            zIndex: -1,
          }}
        />
      </Fragment>
      <Stack h="100%" w="100%" justify="flex-end">
        <Title
          size={isMobile ? 55 : 100}
          c="white"
          lh={1}
          mb="1rem"
          style={{
            textShadow: '3px 3px 7px #0006',
            letterSpacing: '-0.03em',
          }}
        >
          Search Earth&apos;s data like never before.
        </Title>
        <Stack gap="xl" w="100%" mb={isLogIn ? '-70px' : '-90px'}>
          {!isLogIn && (
            <MainSearch
              placeholder={placeholder}
              style={{
                textShadow: 'none',
                width: '100%',
                minWidth: 300,
              }}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
