import React, { type CSSProperties } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ActionIcon, Autocomplete, em, Textarea } from '@mantine/core'
import {
  useDebouncedValue,
  useHotkeys,
  useLocalStorage,
  useMediaQuery,
} from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  type AutocompleteOption,
  resolveLocation,
  separatorString,
} from '@/utils/data-fetching/poi-lookup.ts'

import classes from './MainSearch.module.css'

const DEBOUNCE_TIME = 350

interface SearchInputProps {
  placeholder?: string
  style?: CSSProperties
}

export function MainSearch({ placeholder, style }: SearchInputProps) {
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
  const { pathname } = useLocation()
  const isExplore = pathname === '/new'

  const unifiedRef = React.useRef<HTMLInputElement>(null)
  const [unifiedValue, setUnifiedValue] = React.useState('')

  const [isSplit, setIsSplit] = useLocalStorage({
    key: 'split-search-active',
    defaultValue: false,
  })
  const [locationValue, setLocationValue] = React.useState('')
  const [debouncedLocationValue] = useDebouncedValue(
    locationValue,
    DEBOUNCE_TIME,
  )
  const [_, setFormattedLocation] = React.useState('')
  const [_a, setLocation] = React.useState('')
  const [locationOptions, setLocationOptions] = React.useState<
    AutocompleteOption[]
  >([])
  const [subjectValue, setSubjectValue] = React.useState('')
  const [_b, setIsUsAddress] = React.useState(false)
  const locationRef = React.useRef<HTMLInputElement>(null)
  const subjectRef = React.useRef<HTMLInputElement>(null)

  function handleSplitHotkey(event: KeyboardEvent | React.KeyboardEvent) {
    event.preventDefault()
    setIsSplit((state) => !state)
    setTimeout(() => {}, 0)
    if (isSplit) {
      locationRef.current?.focus()
    } else {
      unifiedRef.current?.focus()
    }
  }

  function setSplitLocationValues(value: string) {
    const formatted = value.split(separatorString)[1]
    const formattedWithoutCountry = formatted.replace(', USA', '')
    setFormattedLocation(formattedWithoutCountry)
    setLocation(formattedWithoutCountry)
  }

  function handleSubmit() {
    navigate('/new')
  }

  useHotkeys([
    ['mod+k', handleSplitHotkey],
    ['mod+Enter', handleSubmit],
  ])

  function handleLocationUpdate({
    label,
    value,
    isUsAddress: isUSA,
  }: AutocompleteOption) {
    setIsUsAddress(isUSA ?? false)
    setLocationOptions([{ label, value }])
  }

  React.useEffect(() => {
    // Use `locationValue` but only trigger when `debouncedLocationValue` changes
    void resolveLocation(locationValue, handleLocationUpdate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocationValue])

  function handleInlineHotkeyPress(event: KeyboardEvent | React.KeyboardEvent) {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === 'k') {
        handleSplitHotkey(event)
      } else if (event.key === 'Enter') {
        handleSubmit()
      }
    }
  }

  return isSplit ? (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        gap: '1rem',
        ...style,
      }}
    >
      <Autocomplete
        id="location-search"
        w="100%"
        required
        radius="1rem"
        leftSection={<Icon name="my_location" />}
        value={locationValue}
        onChange={setLocationValue}
        onOptionSubmit={(value) => {
          setSplitLocationValues(value)
          subjectRef.current?.focus()
        }}
        onKeyDown={handleInlineHotkeyPress}
        data={locationOptions.map(({ label, value }) => ({ label, value }))}
        placeholder="Where do you want to search? (CTRL/CMD+K to switch Search Mode)"
        ref={locationRef}
        filter={({ options }) => options}
      />
      {/* TODO: Add some validation and feedback for requiring a location */}
      <Autocomplete
        id="subject-search"
        w="100%"
        radius="1rem"
        leftSection={<Icon name="search" />}
        rightSection={
          <ActionIcon
            variant="transparent"
            c="gray"
            type="submit"
            disabled={!locationValue}
          >
            <Icon name="send" filled />
          </ActionIcon>
        }
        value={subjectValue}
        onChange={setSubjectValue}
        onKeyDown={handleInlineHotkeyPress}
        placeholder="What do you want to know?"
        ref={subjectRef}
      />
    </form>
  ) : (
    <form onSubmit={handleSubmit} style={style}>
      <Textarea
        id="search"
        rows={isMobile && !isExplore ? 3 : 1}
        radius="1rem"
        leftSection={<Icon name="search" />}
        rightSection={
          <ActionIcon
            c="white"
            style={{
              background: '#0091EA',
              borderRadius: '50%',
              marginRight: 10,
            }}
            type="submit"
            disabled={!unifiedValue}
          >
            <Icon name="arrow_forward" filled={true} color="blue" />
          </ActionIcon>
        }
        classNames={{
          input: isExplore ? undefined : classes['input'],
        }}
        value={unifiedValue}
        onChange={(event) => setUnifiedValue(event.currentTarget.value)}
        // onOptionSubmit={handleSetQuery}
        onKeyDown={handleInlineHotkeyPress}
        placeholder={
          placeholder ??
          'Search by any query or data type, address, coordinates, city, property features…'
        }
        // ref={unifiedRef}
      />
    </form>
  )
}
