import {
  type CategorizedResults,
  resultsStore,
} from '@/stores/results-store.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'

export const flattenCategorizedResults = (
  categorized: CategorizedResults,
): ResultWithId[] => {
  const withoutInsights: Partial<CategorizedResults> = { ...categorized }
  delete withoutInsights.insightResults
  return Object.values(withoutInsights).flat() as ResultWithId[]
}
export const useAllResults = () => {
  const categorized = resultsStore((s) => s.categorized)
  return flattenCategorizedResults(categorized)
}
