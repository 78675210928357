/* eslint-disable camelcase */
import type { DataResult, ResultWithId } from '@/utils/types/result-types.ts'
import { parseUsAddress } from '@/utils/types/single-address/us-address-types.ts'
import type { ParsedAddress } from 'vladdress'

export interface ScoredRisk {
  score: 'A' | 'B' | 'C' | 'D' | 'F' | 'N/A' | null
  text: string | null | undefined
}

export const ScoredRiskTypes = {
  aggravated_assault: 'Aggravated Asault',
  airport_noise: 'Airport Noise',
  asbestos_sources_params: 'Asbestos',
  aspect_risk: 'Aspect',
  brownfield: 'Brownfield',
  burglary: 'Burglary',
  convection_storm: 'Convection Storm',
  crime: 'Crime',
  designated_fault: 'Designated Fault',
  drought: 'Drought',
  drought_frequency_index: 'Drought Frequency',
  drug_alcohol_deaths: 'Drug & Alcohol Deaths',
  earthquake: 'Earthquake',
  enhanced_hail_params: 'Hail',
  enhanced_hazardhub_flood: 'Flood',
  enhanced_hurricane_params: 'Hurricane',
  enhanced_lightning_params: 'Lightning',
  enhanced_tornado_params: 'Tornado',
  enhanced_wildfire: 'Wildfire',
  enhanced_wind_params: 'Wind',
  fault_earthquake: 'Fault Earthquake',
  fema_all_flood: 'FEMA Flood',
  fire_protection: 'Fire Protection',
  forcible_rape: 'Forcible Rape',
  forcible_robbery: 'Forcible Robbery',
  fracking_earthquake: 'Fracking Earthquake',
  frozen_pipe_index: 'Frozen Pipe Index',
  ground_snow_load: 'Ground Snow Load',
  hazardhub_catastrophic_flood: 'Catastrophic Flood',
  hh_snow_load: 'Snow Load',
  hydrant: 'Fire Hydrant',
  ice_dam_index: 'Ice Damage',
  landslide: 'Landslide',
  larceny: 'Larceny',
  lava_flow: 'Lava Flow',
  mine_subsidence: 'Mine Subsidence',
  mold_index: 'Mold',
  motor_vehicle_theft: 'Motor Vehicle Theft',
  mudslide_risk: 'Mudslide',
  murder: 'Murder',
  nuclear_site: 'Nuclear Site',
  pfa: 'PFA',
  radon: 'Radon',
  sinkhole: 'Sinkhole',
  slope_risk: 'Slope',
  superfund: 'Superfund',
  surge_max: 'Surge',
  toxic_release_facilities: 'Toxic Release Facilities',
  tsunami: 'Tsunami',
  ust: 'Underground Storage Tank',
  volcano: 'Volcano',
  wind_born_debris: 'Wind Born Debris',
  wind_pool: 'Wind Pool',
  wind_region: 'Wind Region',
} as const
export type ScoredRiskKey = keyof typeof ScoredRiskTypes
export type HazardRiskRecord = {
  [key in ScoredRiskKey]: ScoredRisk
} & { address: string }

export interface LabeledScoredRisk {
  record: ScoredRisk
  type: string
  label: (typeof ScoredRiskTypes)[ScoredRiskKey]
}

export type HazardRiskResult = DataResult & {
  properties: HazardRiskRecord
}

export const isHazardRiskResult = (r: ResultWithId): r is HazardRiskResult => {
  return (
    r.source.toLowerCase() === 'hazard' &&
    Object.keys(r.properties).includes('aais_fire_protection_class_params')
  )
}

export const parseHazardRiskAddress = (
  r: HazardRiskRecord,
): Partial<ParsedAddress> => {
  return parseUsAddress(r.address)
}
