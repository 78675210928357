import type { DataResult, ResultWithId } from '@/utils/types/result-types.ts'

export interface AttomPropertyRecord {
  address: {
    line1: string
    line2: string
  }
  building: {
    construction: {
      roofCover: string
    }
    rooms: {
      bathsTotal: number
      beds: number
    }
    size: {
      bldgSize: number
      grossSize: number
    }
  }
  location: {
    latitude: string
    longitude: string
  }
  lot?: {
    lotSize1: number
    lotSize2: number
  }
  sale: {
    amount: {
      saleAmt: number
    }
    saleTransDate: string
  }
  summary: {
    propClass: string
    yearBuilt: number
  }
}

export type AttomPropertyResult = DataResult & {
  properties: AttomPropertyRecord
}

export const isAttomPropertyResult = (
  r: ResultWithId,
): r is AttomPropertyResult => {
  return (
    r.source.toLowerCase() === 'attom' &&
    Object.keys(r.properties).includes('assessment')
  )
}
