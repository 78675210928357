import { type MutableRefObject, useCallback, useEffect } from 'react'
import { addInteractionListeners } from '@/components/lib/map-search/hooks/util/add-interaction-listeners.ts'
import { addLayers } from '@/components/lib/map-search/hooks/util/add-layers.ts'
import { addSources } from '@/components/lib/map-search/hooks/util/add-sources.ts'
import type { HexGrid } from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import {
  makeImageryResultPolygons,
  makeResultPinPoints,
} from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { mapStore } from '@/stores/map-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  type FireResult as FireResultType,
  isFireResult,
  isShipResult,
  type ShipResult as ShipResultType,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'

type UseStyleLoadedCallbackProps = {
  map: MutableRefObject<mapboxgl.Map | null>
  hexGrid: HexGrid
}

export const useStyleLoadedCallback: (
  props: UseStyleLoadedCallbackProps,
) => void = ({ map, hexGrid }) => {
  const [
    visualizationMode,
    toggleSelectedHexGridId,
    setHoveredHexGridId,
    setHoveredExtentIds,
    setSelectedExtentIds,
    setSelectedPointId,
  ] = mapStore((s) => [
    s.visualizationMode,
    s.toggleSelectedHexGridId,
    s.setHoveredHexGridId,
    s.setHoveredExtentIds,
    s.setSelectedExtentIds,
    s.setSelectedPointId,
  ])
  const {
    categorized: { publicationResults },
  } = useTimelineFilteredResults()
  const fireResults: FireResultType[] = resultsStore((state) =>
    state.categorized.dataResults.filter(isFireResult),
  )
  const shipResults: ShipResultType[] = resultsStore((state) =>
    state.categorized.dataResults.filter(isShipResult),
  )
  const socialResults: SocialMediaResult[] = resultsStore(
    (state) => state.categorized.socialMediaResults,
  )

  const imageResults = usePostfilterImageResults()
  const extentsData = makeImageryResultPolygons(imageResults)
  const newsData = makeResultPinPoints(publicationResults)
  const fireData = makeResultPinPoints(fireResults)
  const shipData = makeResultPinPoints(shipResults)
  const socialData = makeResultPinPoints(socialResults)

  const styleDataCallback = useCallback(() => {
    addSources({
      map,
      hexGrid,
      extentsData,
      fireData,
      shipData,
      socialData,
      newsData,
    })
    addLayers(map, visualizationMode)
    addInteractionListeners({
      map,
      toggleSelectedHexGridId,
      setHoveredHexGridId,
      setSelectedExtentIds,
      setHoveredExtentIds,
      setSelectedPointId,
    })
  }, [
    extentsData,
    fireData,
    hexGrid,
    map,
    newsData,
    setHoveredExtentIds,
    setHoveredHexGridId,
    setSelectedExtentIds,
    setSelectedPointId,
    shipData,
    socialData,
    toggleSelectedHexGridId,
    visualizationMode,
  ])

  useEffect(() => {
    if (map.current?.isStyleLoaded()) {
      addSources({
        map,
        hexGrid,
        extentsData,
        fireData,
        shipData,
        newsData,
        socialData,
      })
    }
  }, [extentsData, fireData, hexGrid, map, shipData, socialData, newsData])

  useEffect(() => {
    map.current?.on('styledata', styleDataCallback)
  }, [map, styleDataCallback])
}
