import { SimpleGrid } from '@mantine/core'
import { SocialResult } from '@/components/lib/results/Result'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  isSocialMediaResult,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'
import { PanelBase } from './'

interface SocialPanelProps {
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
}

export function SocialPanel({
  isFullscreen = false,
  position,
}: SocialPanelProps) {
  const unfilteredSocialResults: SocialMediaResult[] = resultsStore(
    (state) => state.categorized.socialMediaResults,
  )
  const filteredSocialResults =
    useTimelineFilteredResults().categorized.socialMediaResults
  const socialResults = isFullscreen
    ? filteredSocialResults
    : unfilteredSocialResults

  const shouldDisplay = socialResults.length > 0 || isFullscreen

  return (
    shouldDisplay && (
      <PanelBase
        titleShort="Social"
        titleLong="All Social Data"
        isFullscreen={isFullscreen}
        isolateType={isSocialMediaResult}
        link="/social"
        position={position}
      >
        <SimpleGrid
          cols={isFullscreen ? { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 } : 3}
          spacing={isFullscreen ? 'xl' : 'lg'}
          verticalSpacing={isFullscreen ? 'md' : 70}
        >
          {socialResults
            .slice(0, isFullscreen ? undefined : 3)
            .map((result) => (
              <SocialResult
                key={result.documentId}
                result={result}
                isFullscreen
              />
            ))}
        </SimpleGrid>
      </PanelBase>
    )
  )
}
