import { Box, Grid, Loader, Stack, Text, Title } from '@mantine/core'
import { Skeleton } from '@/components/lib/Skeleton.tsx'
import { SearchStatuses, useSearchStatus } from '@/hooks/use-search-status.ts'
import type { FC, ReactNode } from 'react'

interface ResultStatusProps {
  title: string
  text: string | ReactNode
  isLoading?: boolean
}
function ResultStatus({ title, text, isLoading = false }: ResultStatusProps) {
  return (
    <Stack align="center" gap="0.5rem" mt="xl">
      {isLoading && <Loader size="lg" mb="md" />}
      <Title fw={500}>{title}</Title>
      <Text size="1rem" c="gray" ta="center">
        {text}
      </Text>
    </Stack>
  )
}

type ImagePanelItem = { source: string; id: string; authoredOn: string }

type ImagesPanelProps = {
  imageResults: ImagePanelItem[]
  makeThumbnail: (item: ImagePanelItem) => ReactNode
  isEmbedded: boolean
}
export const ImagesPanel: FC<ImagesPanelProps> = ({
  imageResults,
  makeThumbnail,
  isEmbedded,
}) => {
  const { status } = useSearchStatus()
  return (
    <Box>
      {status === SearchStatuses.STARTED_LONG_AGO ? (
        <ResultStatus
          isLoading
          title="This might take a minute"
          text="Danti is still searching. Please wait."
        />
      ) : status === SearchStatuses.EDS_IN_PROGRESS ? (
        <ResultStatus
          isLoading
          title="Exploring new data"
          text="We're searching our sources for newer data. Please wait."
        />
      ) : status === SearchStatuses.NO_RESULTS ? (
        <ResultStatus
          title="No results"
          text={
            <>
              Danti searched everywhere, but couldn&apos;t find any images,
              news, or data.
              <br />
              Please modify your search and try again.
            </>
          }
        />
      ) : (
        <Grid columns={60} gutter={isEmbedded ? 0 : 'sm'}>
          {imageResults.map((result) => (
            <Grid.Col
              key={result.id}
              span={{
                base: 60 / (isEmbedded ? 1 : 3),
                sm: 60 / (isEmbedded ? 1 : 3),
                md: 60 / (isEmbedded ? 1 : 4),
                lg: 60 / (isEmbedded ? 1 : 5),
                xl: 60 / (isEmbedded ? 1 : 6),
              }}
            >
              {makeThumbnail(result)}
            </Grid.Col>
          ))}
          {status === SearchStatuses.STARTED_RECENTLY &&
            imageResults.length < 12 &&
            Array.from({ length: 12 - imageResults.length }).map((_, index) => (
              <Grid.Col
                key={index}
                span={{
                  base: 60 / (isEmbedded ? 1 : 3),
                  sm: 60 / (isEmbedded ? 1 : 3),
                  md: 60 / (isEmbedded ? 1 : 4),
                  lg: 60 / (isEmbedded ? 1 : 5),
                  xl: 60 / (isEmbedded ? 1 : 6),
                }}
              >
                <Skeleton showDanti />
              </Grid.Col>
            ))}
        </Grid>
      )}
    </Box>
  )
}
