import { createStore } from '@/utils/create-store.ts'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'
import type { Feature, Polygon } from 'geojson'
import type { Marker } from 'mapbox-gl'

export const UPLOADED_FEATURE_ID = 'uploaded-feature'

export interface MapDrawState {
  drawController: MapboxDraw | null

  currentSearchPolygon: Polygon | null
  lastSearchPolygon: Polygon | null
  uploadedFeature: Feature | null
  searchHereMarker: Marker | null

  isDrawing: boolean

  setDrawController: (drawController: MapboxDraw) => void
  startDrawingPolygon: () => void
  stopDrawingPolygon: () => void
  setIsDrawing: (isDrawing: boolean) => void
  clearSearchPolygon: () => void
  clearSearchHereMarker: () => void
  setCurrentSearchPolygon: (searchPolygon: Polygon | null) => void
  setLastSearchPolygon: (lastSearchPolygon: Polygon | null) => void
  setSearchHereMarker: (marker: Marker | null) => void
  setUploadedFeature: (feature: Feature) => void
}

export const mapDrawStore = createStore<MapDrawState>({
  initialState: {
    drawController: null,
    currentSearchPolygon: null,
    lastSearchPolygon: null,
    uploadedFeature: null,
    searchHereMarker: null,
    isDrawing: false,
  },
  actions: (setState, getState) => ({
    setDrawController: (drawController: MapboxDraw) => {
      setState({ drawController, isDrawing: false })
    },
    startDrawingPolygon: () => {
      getState().clearSearchPolygon?.()
      setState({ isDrawing: true })
      getState().drawController?.changeMode('draw_polygon')
    },
    stopDrawingPolygon: () => {
      getState().drawController?.changeMode('simple_select', {
        featureIds: [],
      })
      setState({ isDrawing: false })
    },
    setIsDrawing: (isDrawing: boolean) => setState({ isDrawing }),
    clearSearchPolygon: () => {
      getState().drawController?.deleteAll()
      setState({ currentSearchPolygon: null })
      getState().clearSearchHereMarker?.()
    },
    clearSearchHereMarker: () => {
      const { searchHereMarker } = getState()
      if (searchHereMarker) {
        searchHereMarker.remove()
        setState({ searchHereMarker: null })
      }
    },
    setCurrentSearchPolygon: (currentSearchPolygon: Polygon | null) => {
      setState({ currentSearchPolygon })
    },
    setLastSearchPolygon: (lastSearchPolygon) =>
      setState({ lastSearchPolygon }),
    setSearchHereMarker: (searchHereMarker: Marker | null) =>
      setState({ searchHereMarker }),
    setUploadedFeature: (feature: Feature) => {
      setState({
        uploadedFeature: { ...feature, id: UPLOADED_FEATURE_ID },
        currentSearchPolygon: feature.geometry as Polygon,
      })
    },
  }),
})
