import { useMemo } from 'react'
import { queryStore } from '@/stores/queries-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import { attomPermitSample7434 } from '@/utils/types/single-address/__tests__/sample-data/attom/permit-sample-7434.ts'
import { attomPropertySample7434 } from '@/utils/types/single-address/__tests__/sample-data/attom/property-sample-7434.ts'
import {
  isAttomPermitResult,
  parseAttomAddress,
} from '@/utils/types/single-address/attom-permit-types.ts'
import { isAttomPropertyResult } from '@/utils/types/single-address/attom-property-types.ts'
import { matchesRawAddress } from '@/utils/us-address-utils.ts'

export const useAttom = (isMock: boolean) => {
  const dataResults = resultsStore((s) => s.categorized.dataResults)
  const currentQuery = queryStore((s) => s.currentQuery)

  const attomPermitRecord = useMemo(
    () =>
      isMock
        ? attomPermitSample7434.properties
        : dataResults
            .filter(isAttomPermitResult)
            .find((r) =>
              matchesRawAddress(currentQuery, parseAttomAddress(r.properties)),
            )?.properties,
    [currentQuery, isMock, dataResults],
  )
  const attomPropertyRecord = useMemo(
    () =>
      isMock
        ? attomPropertySample7434.properties
        : dataResults
            .filter(isAttomPropertyResult)
            .find((r) =>
              matchesRawAddress(currentQuery, parseAttomAddress(r.properties)),
            )?.properties,
    [currentQuery, isMock, dataResults],
  )

  const permitRecords = useMemo(
    () =>
      attomPermitRecord?.buildingPermits.sort(
        (a, b) =>
          new Date(b.effectiveDate).valueOf() -
          new Date(a.effectiveDate).valueOf(),
      ),
    [attomPermitRecord?.buildingPermits],
  )

  return { attomPermitRecord, attomPropertyRecord, permitRecords }
}
