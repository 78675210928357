import React from 'react'
import { type CardProps, Image, Stack, Text } from '@mantine/core'
import type { ActionMenuOption } from '@/components/lib/results/Result/action-menu-options.ts'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import type { SocialMediaResult } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface SocialResultProps extends CardProps {
  result: SocialMediaResult
  isFullscreen?: boolean
  hideActions?: ActionMenuOption[]
}

const actionsToHide: ActionMenuOption[] = [
  'addToCollection',
  'downloadImage',
  'share',
  'sendToAnalytics',
]

export function SocialResult({
  result,
  isFullscreen,
  hideActions = [],
  ...props
}: SocialResultProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const imageRef = React.useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = React.useState(100)

  React.useLayoutEffect(() => {
    if (containerRef.current) {
      setMaxHeight(
        containerRef.current.clientHeight -
          (imageRef.current?.clientHeight ?? 0) -
          32,
      )
    }
  }, [])

  const datetimeDay = dayjs.utc(result.properties.datetime)

  return (
    <ResultBase
      ref={containerRef}
      result={result}
      hideActions={[...actionsToHide, ...hideActions]}
      onClick="visitArticle"
      withBorder
      radius="sm"
      style={{ borderColor: 'var(--mantine-color-gray-2)' }}
      data-cy="socialResult"
      {...props}
    >
      {result.properties.src_image_url && (
        <div
          ref={imageRef}
          style={{
            position: 'relative',
            overflow: 'hidden',
            aspectRatio: `${isFullscreen ? 2.4 : 1.95}`,
            backgroundColor: 'var(--mantine-color-gray-2)',
          }}
        >
          <Image src={result.properties.src_image_url} h="100%" />
        </div>
      )}

      <Stack
        gap="xs"
        my="sm"
        mr="xs"
        pl="sm"
        pr="xs"
        mah={maxHeight}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          wordBreak: 'break-word',
          scrollbarWidth: 'thin',
          scrollbarColor: 'var(--mantine-color-gray-4) transparent',
        }}
      >
        <Text size="xs">
          {result.properties.source ?? '??'}
          {' • '}
          {datetimeDay &&
            datetimeDay
              .fromNow(true)
              .replaceAll(/^(\d*)\s(\w)\w+$/g, '$1$2')
              .replaceAll(/^a\s(\w)\w+$/g, '1$1')}
        </Text>
        <Text size="xs">{result.properties.summary.replaceAll('�', '')}</Text>
      </Stack>
    </ResultBase>
  )
}
