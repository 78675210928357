import { Title } from '@mantine/core'
import type { PropertyData } from '@/hooks/use-single-address-components.ts'
import type { FC } from 'react'

type AddressDisplayPanelProps = {
  propertyData: PropertyData
  currentQuery: string
}
export const AddressDisplayPanel: FC<AddressDisplayPanelProps> = ({
  propertyData,
  currentQuery,
}) => {
  const line1 =
    propertyData.attomProperty?.address?.line1 ??
    propertyData.attomPermit?.address?.line1
  const line2 =
    propertyData.attomProperty?.address?.line2 ??
    propertyData.attomPermit?.address?.line2
  return (
    <Title size="x-large">
      {line1 && line2
        ? `${line1}, ${line2}`
        : `Searching for ${currentQuery} ...`}
    </Title>
  )
}
