import React from 'react'
import { SimpleGrid } from '@mantine/core'
import { ImageResult } from '@/components/lib/results/Result'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { queryStore } from '@/stores/queries-store.ts'
import { isImageryResult } from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'
import { PanelBase } from './'

interface ImagePanelProps {
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
}

export function ImagePanel({
  isFullscreen = false,
  position,
}: ImagePanelProps) {
  const sortPreference = queryStore((state) => state.sortPreference)
  const imageResults = usePostfilterImageResults()

  const sortedResults = React.useMemo(() => {
    return orderBy(imageResults, sortPreference || undefined, 'desc')
  }, [imageResults, sortPreference])

  return (
    <PanelBase
      titleShort="Image results"
      titleLong="All Images"
      isFullscreen={isFullscreen}
      isolateType={isImageryResult}
      link="/images"
      position={position}
    >
      <SimpleGrid
        cols={isFullscreen ? { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 } : 3}
        spacing={isFullscreen ? 'xl' : 'lg'}
        verticalSpacing={isFullscreen ? 'md' : 70}
      >
        {sortedResults.slice(0, isFullscreen ? undefined : 6).map((result) => (
          <ImageResult key={result.documentId} result={result} isFullscreen />
        ))}
      </SimpleGrid>
    </PanelBase>
  )
}
