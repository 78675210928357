import { type MutableRefObject, useEffect } from 'react'
import { safeSetLayoutProperty } from '@/components/lib/map-search/hooks/util/safe-map-setters.ts'
import { mapStore } from '@/stores/map-store.ts'

export const useLayerToggling = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const [visualizationMode] = mapStore((s) => [s.visualizationMode])
  useEffect(() => {
    const visValue = visualizationMode === 'extents' ? 'visible' : 'none'
    void safeSetLayoutProperty(map, 'extents-fills', 'visibility', visValue)
    void safeSetLayoutProperty(map, 'extents-outlines', 'visibility', visValue)
  }, [map, visualizationMode])

  useEffect(() => {
    const visValue = visualizationMode === 'grid' ? 'visible' : 'none'
    void safeSetLayoutProperty(map, 'hexgrid-fills', 'visibility', visValue)
    void safeSetLayoutProperty(map, 'hexgrid-outlines', 'visibility', visValue)
  }, [map, visualizationMode])

}
