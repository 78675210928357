/* eslint-disable no-template-curly-in-string */
import { createGetKcContext } from "keycloakify/login";

export const { getKcContext } = createGetKcContext({
	mockData: [
		{
			pageId: "login.ftl",
			locale: {
				currentLanguageTag: "en",
			},
		},
		{
			pageId: "register-user-profile.ftl",
			locale: {
				currentLanguageTag: "en"
			},
			profile: {
				"attributes": [
					// {
					// 	"name": "firstName",
					// 	"displayName": "${firstName}",
					// 	"group": "names"
					// },
					// {
					// 	"name": "lastName",
					// 	"displayName": "${lastName}",
					// 	"annotations": {},
					// 	"group": "names"
					// },
					// // {
					// // 	"name": "email",
					// // 	"displayName": "${email}",
					// // },
				],
			}
		},
	]
});

export const { kcContext } = getKcContext({
	// Uncomment to test the login page for development.
	//mockPageId: "login.ftl",
});


export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;