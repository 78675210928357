import { parseLocation } from '@/utils/search-filters/parse-filters.ts'
import { distance } from 'fastest-levenshtein'
import type { ParsedAddress } from 'vladdress'

export const areSameAddress = (
  a1: Partial<ParsedAddress>,
  a2: Partial<ParsedAddress>,
): boolean => {
  // This ignores the zip code, as we allow the user not to enter this
  return (
    a1.addressLine1 === a2.addressLine1 &&
    a1.placeName === a2.placeName &&
    a1.stateName === a2.stateName
  )
}

const LEV_DISTANCE_THRESHOLD = 7
export const matchesRawAddress = (
  unparsedAddress: string,
  parsedAddress: Partial<ParsedAddress>,
): boolean => {
  const { location } = parseLocation(unparsedAddress)
  if (!location || !parsedAddress.addressLine1) {
    return false
  }

  const parsedStreetNumber = location.slice(
    0,
    Math.max(0, location.search(/[^\d\s/]/)),
  )

  const normalizedA = location.toUpperCase()
  const normalizedB = `${parsedAddress.addressLine1},${
    parsedAddress.placeName
  },${parsedAddress.stateAbbreviation}${
    /\d$/.test(location.trim()) ? `,${parsedAddress.zipCode}` : ''
  }`.toUpperCase()

  const levDistance = distance(normalizedA, normalizedB)

  return (
    parsedStreetNumber.trim() === parsedAddress.streetNumber &&
    levDistance < LEV_DISTANCE_THRESHOLD
  )
}
