import { Navigate, type PathRouteProps } from 'react-router-dom'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { oidcClientId } from '@/utils/constants'
import { useFeature } from 'featurehub-react-sdk'

interface FlaggedRouteProps extends PathRouteProps {
  feature: string
  predicate?: (featValue: any) => boolean
  fallbackPath?: string
  isProtected?: boolean
}

export function FlaggedRoute({
  element,
  feature,
  predicate,
  fallbackPath,
  isProtected,
}: FlaggedRouteProps) {
  const featureValue = useFeature(feature)
  const { user } = useDantiAuth()
  if (user && user.profile && user.profile['resource_access']) {
    const resourceAccess = user.profile['resource_access'] as {
      [key: string]: { roles: string[] }
    }
    const roles = resourceAccess[oidcClientId].roles
    const isAuthorizedUser = roles.includes('full-access')

    if (isProtected && !isAuthorizedUser) {
      return <Navigate to="/access-denied" replace />
    }
    if (isProtected && isAuthorizedUser) {
      return element
    }

    return predicate?.(featureValue) ?? featureValue ? (
      element
    ) : (
      <Navigate to={fallbackPath ?? '/404'} replace />
    )
  } else {
    return <Navigate to={fallbackPath ?? '/404'} replace />
  }
}
