// import { ExploreTimeline } from './ExploreTimeline'
import React, { useEffect } from 'react'
import { Paper, Stack } from '@mantine/core'
import { MainSearch } from '@/components/lib/MainSearch'
import type { SymbolName } from '@/utils/types/material-icons'
import { ExploreContent } from './ExploreContent'
import { ExploreFooter } from './ExploreFooter'
import ExploreHeader from './ExploreHeader'
import { ExploreSummary } from './ExploreSummary'

const suggestions = [
  'Did you mean Russian air strikes on Ukraine?',
  'Related: Air strikes from Belarus',
  'Related: Ukraine’s counteroffensive',
  'Related: Dam bombing near Kherson',
  'Related: Risks associated with this property',
  'Related: Recent construction associated with this property',
]

const items: { title: string; icon: SymbolName }[] = [
  {
    title: 'Key Events',
    icon: 'today',
  },
  {
    title: 'Related News',
    icon: 'newspaper',
  },
  {
    title: 'Trending\nSocial',
    icon: 'chat',
  },
  {
    title: 'Ongoing\nConflicts',
    icon: 'report',
  },
  {
    title: 'Terrorist\nActivity',
    icon: 'warning',
  },
  {
    title: 'Impacts on\nTransport',
    icon: 'local_shipping',
  },
]
// const years = [1990, 1993, 1995, 2000, 2005, 2009, 2015, 2024]

const handleSelect = (suggestion: string) => {
  console.log(suggestion)
}

export function NewExplore() {
  const [showContentOne, setShowContentOne] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  // const [year, setYear] = React.useState(Math.min(...years))

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  return (
    <Stack
      style={{
        maxWidth: '932px',
        margin: '0 auto',
        paddingBottom: 100,
      }}
    >
      <ExploreHeader suggestions={suggestions} onSelect={handleSelect} />
      <ExploreSummary showContentOne={showContentOne} />
      <ExploreContent
        showContentOne={showContentOne}
        setShowContentOne={setShowContentOne}
        setIsLoading={(value) => setIsLoading(value)}
        isLoading={isLoading}
      />
      {/* <ExploreTimeline year={year} years={years} onChange={setYear} /> */}
      <ExploreFooter items={items} />
      <Paper
        pos="fixed"
        bg="#000b"
        fz={14}
        bottom={0}
        left={0}
        right={0}
        shadow="xs"
        radius={0}
        p={10}
        pb={50}
        style={{ zIndex: 3 }}
      >
        <MainSearch
          placeholder="Search"
          style={{ maxWidth: '932px', margin: '0 auto', height: 0 }}
        />
      </Paper>
    </Stack>
  )
}
