import { ActionIcon } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { makeSearchFiltersFromCurrentPanelValues } from '@/stores/filters-store.ts'
import type { MouseEventHandler } from 'react'

type FilterToggleButtonProps = {
  isFilterOpen: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}
export function FilterToggleButton({
  isFilterOpen,
  onClick,
}: FilterToggleButtonProps) {
  const currentFilters = makeSearchFiltersFromCurrentPanelValues()

  return (
    <ActionIcon
      className="filterToggle"
      variant={isFilterOpen ? 'filled' : 'subtle'}
      size="lg"
      fw="normal"
      onClick={onClick}
    >
      <Icon name="filter_alt" filled={currentFilters.length > 0} />
    </ActionIcon>
  )
}
