import React, { useCallback, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { AutoAskWidget } from '@/components/lib/single-address/AutoAskWidget.tsx'
import { useChat } from '@/hooks/use-chat.ts'
import { chatStore } from '@/stores/chat-store.ts'
import { isChatRequest } from '@/utils/types/message-types.ts'
import ReactMarkdown from 'react-markdown'

export function ChatPanel() {
  const chatMessages = chatStore((state) => state.messages)
  const { sendPrompt } = useChat()
  const [prompt, setPrompt] = React.useState('')

  const viewport = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (chatMessages.length > 0) {
      viewport.current?.scrollTo({
        top: viewport.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [chatMessages.length])

  const enterListenerCallback = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!event.repeat && submitButtonRef.current) {
        submitButtonRef.current.click()
      }
      event.preventDefault() // Prevents the addition of a new line in the text field
    }
  }, [])

  useEffect(() => {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.addEventListener('keydown', enterListenerCallback)
    }
    return () => textArea?.removeEventListener('keydown', enterListenerCallback)
  }, [enterListenerCallback])

  return (
    <Stack
      style={{ border: '1px solid #e0e0e0', backgroundColor: '#fafafa' }}
      h="90vh"
      gap={8}
    >
      <Group style={{ backgroundColor: 'black', padding: 10 }} gap="xs">
        <Text c="white" fz={10}>
          ASK DANTI
        </Text>
        <AutoAskWidget prompt="What kinds of information do you know about?" />
      </Group>

      <Stack dir="column-reverse" style={{ padding: 5 }}>
        <ScrollArea
          viewportRef={viewport}
          type="auto"
          style={{ position: 'relative' }}
        >
          <Stack dir="column-reverse" mih="75vh" mah="75vh" gap={0} pr="sm">
            {chatMessages.map((message, index) => {
              if (isChatRequest(message)) {
                return (
                  <Box
                    key={`sent_${message.timestamp}_${index}`}
                    style={{ justifySelf: 'flex-end', marginBottom: 10 }}
                  >
                    <Text fw="bold" size="xs">
                      {message.prompt}
                    </Text>
                  </Box>
                )
              }
              return (
                <Box
                  key={`received_${message.messageId}_${index}`}
                  style={{ fontSize: 13 }}
                >
                  <ReactMarkdown>{message.message}</ReactMarkdown>
                </Box>
              )
            })}
            {chatMessages.at(-1) && isChatRequest(chatMessages.at(-1)!) && (
              <Group gap={8}>
                <Loader color="#546e7a" size="xs" />
                <Text size="xs" c="#546e7a" style={{ flex: 1 }}>
                  Searching for that now. This may take a moment.
                </Text>
              </Group>
            )}
          </Stack>
        </ScrollArea>
      </Stack>
      <Box style={{ flex: 1 }} />
      <Box style={{ justifySelf: 'end' }}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            sendPrompt(prompt)
            setPrompt('')
          }}
        >
          <Group gap={0} style={{ position: 'relative' }} align="center">
            <Textarea
              value={prompt}
              onChange={(event) => {
                setPrompt(event.target.value)
              }}
              placeholder="ask a question"
              style={{ flex: 1, flexGrow: 1 }}
              maw="100%"
              ref={textAreaRef}
            />
            <Button
              type="submit"
              variant="white"
              size="xs"
              ref={submitButtonRef}
            >
              <Icon name="send" />
            </Button>
          </Group>
        </form>
      </Box>
    </Stack>
  )
}
