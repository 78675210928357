import { useIdFilterResults } from '@/hooks/results/result-filter-utilities.ts'
import { resultsFilterStore } from '@/stores/results-filter-store.ts'
import { type Results, resultsStore } from '@/stores/results-store.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'

export const useHexgridFilteredResults = (
  isolateType?: (result: ResultWithId) => boolean,
): Results => {
  const [currentQueryId, ids, categorized] = resultsStore((state) => [
    state.currentQueryId,
    state.ids,
    state.categorized,
  ])

  const hexgridSelectedIds = resultsFilterStore(
    (state) => state.hexgridSelectedIds,
  )

  const resultsBundle: Results = { ids, categorized, currentQueryId }

  return useIdFilterResults(
    (r) =>
      hexgridSelectedIds && hexgridSelectedIds.length > 0
        ? hexgridSelectedIds.includes(r.id)
        : isolateType
          ? isolateType(r)
          : true,
    resultsBundle,
  )
}
