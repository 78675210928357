export const {
  VITE_APP_ENV: appEnv,
  VITE_API_URL: apiUrl,
  VITE_SERVICE_API_URL: serviceApiUrl,
  VITE_GRAPHQL_WS: graphWs,
  VITE_OIDC_AUTHORITY: oidcAuthority,
  VITE_OIDC_CLIENT_ID: oidcClientId,
  VITE_OIDC_REDIRECT_URI: oidcRedirectUri,
  VITE_MAPBOX_TOKEN: mapboxToken,
  VITE_HEAP_ENV_ID: heapEnvId,
  VITE_FEATUREHUB_API_KEY: featureHubApiKey,
}: ImportMetaEnv = import.meta.env

export const isLocal = appEnv === 'local'
export const isDevelopment = appEnv === 'dev'
export const isStaging = appEnv === 'stage'
export const isDemonstration = appEnv === 'demo'
export const isProduction = appEnv === 'prod'

export const isGov = appEnv === 'gov'

export const REDIRECT_COOKIE_NAME = 'RedirectAfterLogin' as const
export const REDIRECT_PATH = '/redirect' as const
