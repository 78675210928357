import { Stack, ActionIcon, Group, Menu, Button } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon'
import { ExploreContentOne } from './ExploreContentOne'
import { ExploreContentTwo } from './ExploreContentTwo'
import { useEffect, useState } from 'react'

export interface ExploreContentProps {
  showContentOne: boolean
  setShowContentOne: (value: boolean) => void
  isLoading: boolean
  setIsLoading: (value: boolean) => void
}

export function ExploreContent({
  showContentOne,
  setShowContentOne,
  isLoading,
  setIsLoading,
}: ExploreContentProps) {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null])
  const [opened, setOpened] = useState(false)
  const [openedBefore, setOpenedBefore] = useState(false)

  useEffect(() => {
    if (opened) {
      setOpenedBefore(true)
    }
  }, [opened, openedBefore])

  useEffect(() => {
    console.log('open', opened, 'openedBefore', openedBefore)
    if (!opened && openedBefore) {
      setIsLoading(true)
      setTimeout(() => {
        setShowContentOne(false)
        setTimeout(() => {
          setIsLoading(false)
        }, 3000)
      }, 2000)
    }
  }, [opened, openedBefore])

  return (
    <Stack>
      <Group justify="end">
        <Menu
          shadow="md"
          position="top-end"
          opened={opened}
          onChange={setOpened}
        >
          <Menu.Target>
            <ActionIcon variant="transparent" c="gray" type="submit">
              <Icon name="calendar_month" filled />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Stack gap={4}>
              <DatePicker
                type="range"
                value={value}
                onChange={setValue}
                color="blue"
              />
              <Group justify="space-between">
                <Button
                  variant="transparent"
                  ml={-3}
                  style={{ fontWeight: 'normal' }}
                >
                  Today
                </Button>
                <Button
                  variant="transparent"
                  style={{ fontWeight: 'normal' }}
                  onClick={() => setOpened(false)}
                >
                  Done
                </Button>
              </Group>
            </Stack>
          </Menu.Dropdown>
        </Menu>

        <ActionIcon variant="transparent" c="gray" type="submit">
          <Icon name="filter_alt" />
        </ActionIcon>
      </Group>
      {showContentOne ? (
        <ExploreContentOne isLoading={isLoading} />
      ) : (
        <ExploreContentTwo isLoading={isLoading} />
      )}
    </Stack>
  )
}
