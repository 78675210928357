import { createStore } from '@/utils/create-store.ts'
import { avatarColorOptions } from '@/utils/theme/colors'
import type { User } from 'oidc-client-ts'

export interface UserState {
  avatarBackgroundColor: string
  id: string | undefined
  username: string
  firstName: string
  lastName: string
  email: string
  setProfile: (authUser?: User | null) => void
}

export const userStore = createStore<UserState>({
  storageKey: 'UserStore',
  version: 0.1,
  initialState: {
    avatarBackgroundColor:
      avatarColorOptions[Math.floor(Math.random() * avatarColorOptions.length)],
    id: undefined,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  actions: (set): Partial<UserState> => ({
    setProfile: (authUser) => {
      const profile: Partial<User['profile']> = authUser?.profile ?? {}
      set({
        id: profile.sub,
        username: profile.preferred_username ?? '',
        firstName: profile.given_name ?? '',
        lastName: profile.family_name ?? '',
        email: profile.email ?? '',
      })
    },
  }),
})

export function getUserInitials(): string {
  const { firstName, lastName } = userStore.getState()
  return `${firstName[0]}${lastName[0]}`
}
