import {
  MantineTransition,
  Transition,
  Image,
  // Box,
  StyleProp,
} from '@mantine/core'
// import { faker } from '@faker-js/faker'
import { useEffect, useState } from 'react'
import { Skeleton } from '@/components/lib/Skeleton'

export interface ContentBoxProps {
  // height?: string
  width?: string
  imageSrc: string
  transition: MantineTransition
  isLoading: boolean
  h?: StyleProp<React.CSSProperties['height']>
  w?: StyleProp<React.CSSProperties['width']>
}

export function ContentBox({
  h,
  w,
  transition,
  imageSrc,
  isLoading,
}: ContentBoxProps) {
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setOpened(true)
    }, 3200)
  }, [])

  if (isLoading) {
    return <Skeleton h={h} />
  }
  return (
    <Transition
      mounted={opened}
      transition={transition}
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <Image
          src={imageSrc}
          h={w}
          w={w}
          style={{ ...styles, objectFit: 'cover' }}
        />
      )}
    </Transition>
  )
}

// w={{ base: '100px', md: width }}

/*
<div
          style={{
            ...styles,
            background: faker.color.rgb(),
            height,
          }}
        ></div>
*/
