import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { MantineProvider } from '@mantine/core'
import { configureSentry } from '@/hooks/use-sentry-metadata-syncing.ts'
import { DantiAuthProvider } from '@/utils/authentication.tsx'
import { DantiFeatureHubProvider } from '@/utils/featurehub.tsx'
import { QueryProvider } from '@/utils/query-provider.tsx'
import { theme } from '@/utils/theme/theme.ts'
import { SingletonHooksContainer } from 'react-singleton-hook'
import { kcContext as kcLoginThemeContext } from './keycloak-theme/login/KcContext.ts'
import { router } from './utils/routing/router.tsx'

import './main.css'

configureSentry()

// eslint-disable-next-line react-refresh/only-export-components
const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'))

const rootElement = document.querySelector('#root') as Element

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <Suspense>
        {(() => {
          if (kcLoginThemeContext !== undefined) {
            return (
              <MantineProvider theme={theme}>
                <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
              </MantineProvider>
            )
          }

          return (
            <DantiAuthProvider>
              <QueryProvider>
                <SingletonHooksContainer />
                <DantiFeatureHubProvider>
                  <MantineProvider theme={theme}>
                    <RouterProvider router={router} />
                  </MantineProvider>
                </DantiFeatureHubProvider>
              </QueryProvider>
            </DantiAuthProvider>
          )
        })()}
      </Suspense>
    </React.StrictMode>,
  )
}
