import {
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { addResultToGrid } from '@/components/lib/map-search/hooks/util/add-result-to-grid.ts'
import {
  type HexGrid,
  makeBareHexGrid,
} from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import { safeSetMapSourceData } from '@/components/lib/map-search/hooks/util/safe-map-setters.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { type HexGridCellProperties, mapStore } from '@/stores/map-store.ts'
import { resultsStore } from '@/stores/results-store.ts'
import {
  isImageryResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'
import * as turf from '@turf/turf'
import type { Polygon } from 'geojson'
import type mapboxgl from 'mapbox-gl'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'
import { setHexGridFills } from './util/set-hex-grid-fills.ts'

export const useHexGrid = (map: MutableRefObject<mapboxgl.Map | null>) => {
  const imageResults = useDeepCompareMemoize(usePostfilterImageResults())
  const allResults = resultsStore((s) => s.categorized.imageResults)
  const selectedHexGridId = mapStore((s) => s.selectedHexGridId)
  const processedIds = useRef(new Set<string>())
  const [computedGrid, setComputedGrid] = useState<HexGrid>(
    turf.featureCollection([]),
  )

  const addResults = (grid: HexGrid, results: ResultWithId[]): HexGrid => {
    if (results.length === 0) {
      return grid
    }

    return results.reduce((hexGrid, result) => {
      processedIds.current.add(result.id)
      return addResultToGrid({ result, hexGrid })
    }, grid)
  }

  const recomputeHexGrid = useCallback(
    (results: ResultWithId[]) => {
      const innerRecompute = () => {
        if (map.current) {
          processedIds.current.clear()
          const bareGrid = makeBareHexGrid({
            currentMapBounds: map.current?.getBounds(),
          })
          const imageResults = results.filter(isImageryResult)

          const fullGrid = addResults(bareGrid, imageResults)

          const culledHexGrid = turf.featureCollection<
            Polygon,
            HexGridCellProperties
          >(
            fullGrid.features.filter((f) => {
              return f.properties.resultCount > 0
            }),
          )

          safeSetMapSourceData(map, 'hexgrid', culledHexGrid)
            .then(() => {
              setComputedGrid(culledHexGrid)
              return setHexGridFills(map, culledHexGrid)
            })
            .catch((error) => Sentry.captureException(error))
        }
      }
      if (!map.current?.isMoving()) {
        innerRecompute()
      }
    },
    [map],
  )

  // When new results come in, add them to grid
  useEffect(() => {
    if (selectedHexGridId) {
      recomputeHexGrid(allResults)
    } else {
      recomputeHexGrid(imageResults)
    }
  }, [allResults, imageResults, recomputeHexGrid, selectedHexGridId])

  useEffect(() => {
    map.current?.on('moveend', (event) => {
      if (event['recomputeHexGrid']) {
        void recomputeHexGrid(imageResults)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recomputeHexGrid, map])

  return {
    recomputeHexGrid,
    hexGrid: computedGrid,
  }
}
