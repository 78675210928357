import { createStore } from '@/utils/create-store.ts'
import {
  type ChatMessage,
  isChatResponse,
} from '@/utils/types/message-types.ts'

export interface ChatState {
  ids?: string[]
  messages: (ChatMessage & { timestamp: number })[]
}

export const chatStore = createStore<ChatState>({
  initialState: { ids: [], messages: [] },
})

export function addChatMessage(message: ChatMessage) {
  const { ids } = chatStore.getState()

  const duplicateMessage =
    isChatResponse(message) && ids?.includes(message.messageId)

  if (!duplicateMessage) {
    chatStore.setState((state) => ({
      messages: [...state.messages, { timestamp: Date.now(), ...message }],
      ids: isChatResponse(message)
        ? [...(state.ids || []), message.messageId]
        : state.ids,
    }))
  }
}

export function clearChat() {
  chatStore.setState({
    messages: [],
  })
}
