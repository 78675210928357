import { ActionIcon, em, Group, Menu, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon.tsx'

export function ExploreNavBar() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)

  return (
    <Group>
      <Text style={{ fontSize: '19px', fontWeight: 'bold' }}>
        Q: Where are the russian airbases near ukraine?
      </Text>
      {!isMobile && (
        <>
          <div style={{ flex: 1 }} />
          <Menu shadow="md" position="top-end">
            <Menu.Target>
              <ActionIcon variant="transparent" c="gray" type="submit">
                <Icon name="more_horiz" filled />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item leftSection={<Icon name="add" filled />}>
                Add to collection
              </Menu.Item>
              <Menu.Item leftSection={<Icon name="share" filled />}>
                Share
              </Menu.Item>
              <Menu.Item leftSection={<Icon name="save" filled />}>
                Save this search
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      )}
    </Group>
  )
}
