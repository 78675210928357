import { Tooltip } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import type { SymbolName } from '@/utils/types/material-icons.ts'
import { QuerySourceState } from '@/utils/types/message-types.ts'

type StatusObject = Record<
  QuerySourceState,
  { icon: SymbolName; color: string; text: string }
>

const status: StatusObject = {
  [QuerySourceState.NOT_STARTED]: {
    icon: 'circle',
    color: '#bdbdbd',
    text: 'Not Started',
  },
  [QuerySourceState.IN_PROGRESS]: {
    icon: 'pending',
    color: '#1f5cb9',
    text: 'In Progress',
  },
  [QuerySourceState.COMPLETED]: {
    icon: 'check_circle',
    color: '#2e7d32',
    text: 'Complete',
  },
  [QuerySourceState.ERROR]: {
    icon: 'cancel',
    color: '#bdbdbd',
    text: 'Unavailable',
  },
}

interface StatusBallProps {
  label: string
  state: QuerySourceState
}

export const StatusBall = ({ label, state }: StatusBallProps) => {
  const sourceStatus = status[state]

  return (
    <Tooltip
      label={`${label} - ${sourceStatus.text}`}
      color="#707070"
      withArrow={true}
      arrowPosition="center"
      arrowSize={8}
    >
      <Icon
        name={sourceStatus.icon}
        size={16}
        weight={400}
        style={{ color: sourceStatus.color }}
      />
    </Tooltip>
  )
}
