import type { DataResult, ResultWithId } from '@/utils/types/result-types.ts'
import type { AttomPropertyRecord } from '@/utils/types/single-address/attom-property-types.ts'
import { parseUsAddress } from '@/utils/types/single-address/us-address-types.ts'
import type { ParsedAddress } from 'vladdress'

export interface AttomPermitRecordBuildingPermit {
  businessName: string
  description: string
  effectiveDate: string
  subType: string
  type: string
}
export interface AttomPermitRecord {
  address: {
    line1: string
    line2: string
  }
  building: {
    rooms: {
      bathsTotal: number
      beds: number
    }
    size: { universalSize: number }
  }
  buildingPermits: AttomPermitRecordBuildingPermit[]
  location: {
    latitude: string
    longitude: string
  }
  summary: {
    propClass: string
    yearBuilt: number
  }
  lot: {
    lotSize1: number
  }
}

export type AttomPermitResult = DataResult & { properties: AttomPermitRecord }

export const isAttomPermitResult = (
  r: ResultWithId,
): r is AttomPermitResult => {
  return (
    r.source.toLowerCase() === 'attom' &&
    Object.keys(r.properties).includes('buildingPermits')
  )
}

export const parseAttomAddress = (
  r: AttomPermitRecord | AttomPropertyRecord,
): Partial<ParsedAddress> => {
  return parseUsAddress(`${r.address.line1}, ${r.address.line2}`)
}
