import { createStore } from '@/utils/create-store.ts'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters.ts'
import {
  type DateValue,
  TimePreset,
} from '@/utils/types/filter-types.ts'

export interface Filters {
  timePreset: TimePreset
  customTimeStart: DateValue
  customTimeEnd: DateValue

  sensors: string[]
  providers: string[]
  quality: string
  cloudCover: number
  angle: string
  orientations: { start: number; end: number }[]
  location: string
  locationType: string
  keywords: string[]
}

export const initialFilterState: Filters = {
  timePreset: TimePreset.None,
  customTimeStart: null,
  customTimeEnd: null,
  sensors: [],
  providers: [],
  quality: '5000',
  cloudCover: 100,
  angle: '50',
  orientations: [],
  location: '',
  locationType: '',
  keywords: [],
}

export const filtersStore = createStore<Filters>({
  initialState: initialFilterState,
})

export function getFilters() {
  return filtersStore.getState()
}

export function setFilters(filters: Partial<Filters>) {
  filtersStore.setState(filters)
}

export function makeSearchFiltersFromCurrentPanelValues() {
  const filters = getFilters()
  return makeApiFilters(filters)
}

export function setTimePreset(
  timePreset: TimePreset,
  start: DateValue,
  end: DateValue,
) {
  setFilters({
    timePreset,
    customTimeStart: start,
    customTimeEnd: end,
  })
}

export function toggleSensors(sensor: string) {
  const currentSensors = new Set(getFilters().sensors)
  if (currentSensors.has(sensor)) {
    currentSensors.delete(sensor)
  } else {
    currentSensors.add(sensor)
  }
  setFilters({ sensors: [...currentSensors] })
}

export function setProviders(providers: string[]) {
  setFilters({ providers })
}

export function setLocation(location: string, locationType: string) {
  setFilters({ location, locationType })
}

export function resetFilters() {
  setFilters(initialFilterState)
}
