import { type FC, useEffect, useRef, useState } from 'react'
import { Button, Group, Stack, Text } from '@mantine/core'
import { formatMapboxStyle } from '@/components/lib/map-search/hooks/use-base-layer-selection.ts'
import type { ImageTileLayerConfig } from '@/hooks/single-address/use-regrid.ts'
import type { PropertyData } from '@/hooks/use-single-address-components.ts'
import { mapStore } from '@/stores/map-store.ts'
import { getProxiedDataUrl } from '@/utils/data-fetching/thumbnail.ts'
import type { VexcelOrthoCoverageAoi } from '@/utils/data-fetching/vexcel-ortho-coverage.ts'
import * as turf from '@turf/turf'
import { format, parseISO } from 'date-fns'
import type { Polygon } from 'geojson'
import mapboxgl, { type GeoJSONSource } from 'mapbox-gl'

type MapPanelProps = {
  propertyData: PropertyData
  parcelPolygon: Polygon | undefined
  makeTileLayerOptions: (z: number) => ImageTileLayerConfig[]
  orthoCoverage: VexcelOrthoCoverageAoi[]
}
export const MapPanel: FC<MapPanelProps> = ({
  propertyData,
  parcelPolygon,
  makeTileLayerOptions,
  orthoCoverage,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)

  const [baseLayer] = useState(mapStore((s) => s.baseLayer))
  const [isMapLoaded, setMapLoaded] = useState(false)

  const [currentAoiIndex, setCurrentAoiIndex] = useState(0)
  useEffect(() => {
    if (isMapLoaded && orthoCoverage[currentAoiIndex]) {
      Object.keys(map.current?.getStyle().sources || {}).forEach((s) => {
        if (s.startsWith('proxiedSource_')) {
          console.log(`removing source ${s}`)
          map.current?.removeLayer(`${s}-layer`)
          map.current?.removeSource(s)
        }
      })
      const tileLayerOptions = makeTileLayerOptions(
        orthoCoverage[currentAoiIndex].maxZoom,
      )
      console.log(`Adding ${tileLayerOptions?.length} image tiles`)
      tileLayerOptions?.forEach((o, index) => {
        const addSourceToMap = async () => {
          const sourceName = `proxiedSource_${index}`

          const baseThumbnail = `https://api.gic.org/images/GetOrthoImageTile/${
            orthoCoverage[currentAoiIndex].layerName || 'bluesky-high'
          }/${orthoCoverage[currentAoiIndex].maxZoom}/${o.tileX}/${o.tileY}`
          const aoiParam =
            orthoCoverage.length > 0
              ? `?aoi=${orthoCoverage[currentAoiIndex].aoi}`
              : ''
          const dataUrl = await getProxiedDataUrl({
            thumbnail: `${baseThumbnail}${aoiParam}`,
            source: 'VEXCEL',
          })
          if (!map.current?.getSource(sourceName)) {
            map.current?.addSource(sourceName, {
              type: 'image',
              url: dataUrl,
              coordinates: o.coordinates,
            })
            map.current?.addLayer(
              {
                id: `${sourceName}-layer`,
                type: 'raster',
                source: sourceName,
                paint: {
                  'raster-fade-duration': 0,
                },
              },
              'parcel-outline2',
            )
          }
        }
        void addSourceToMap()
      })
    }
  }, [isMapLoaded, currentAoiIndex, orthoCoverage, makeTileLayerOptions])

  useEffect(() => {
    if (map.current || !mapContainer.current) {
      return
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: formatMapboxStyle(baseLayer),
      center: [-95.712, 37],
      zoom: 5,
      pitchWithRotate: false,
      touchPitch: false,
      dragRotate: false,
      touchZoomRotate: false,
    })

    map.current.addControl(
      new mapboxgl.NavigationControl({
        visualizePitch: false,
        showCompass: false,
      }),
      'top-left',
    )

    map.current.on('styledata', () => {
      setMapLoaded(true)
      if (!map.current?.getSource('parcel')) {
        map.current?.addSource('parcel', {
          type: 'geojson',
          data: parcelPolygon,
        })

        map.current?.addLayer({
          id: 'parcel-outline2',
          type: 'line',
          source: 'parcel',
          paint: {
            'line-color': '#ffff00',
            'line-width': 3,
          },
        })
        map.current?.addLayer({
          id: 'parcel-outline1',
          type: 'line',
          source: 'parcel',
          paint: {
            'line-color': '#000000',
            'line-width': 1,
            'line-opacity': 0.8,
          },
        })
      }
    })
  }, [baseLayer, parcelPolygon])

  useEffect(() => {
    if (parcelPolygon) {
      map.current?.flyTo({
        center: turf.centroid(parcelPolygon).geometry.coordinates as [
          number,
          number,
        ],
        zoom: 18,
      })

      const parcelSource = map.current?.getSource('parcel') as GeoJSONSource
      parcelSource?.setData(turf.feature(parcelPolygon))
    }
  }, [parcelPolygon, propertyData.regrid])

  return (
    <Stack>
      <div ref={mapContainer} style={{ height: 500 }} />
      {orthoCoverage.length > 0 && (
        <Group>
          <Button
            onClick={() => setCurrentAoiIndex(Math.max(0, currentAoiIndex - 1))}
            disabled={currentAoiIndex == 0}
          >
            &lt;
          </Button>
          <Button
            onClick={() =>
              setCurrentAoiIndex(
                Math.min(orthoCoverage.length - 1, currentAoiIndex + 1),
              )
            }
            disabled={currentAoiIndex == orthoCoverage.length - 1}
          >
            &gt;
          </Button>
          <Stack gap={1}>
            <Text>
              {format(
                parseISO(orthoCoverage[currentAoiIndex].minDate),
                'MMMM yyyy',
              )}
            </Text>
            <Text size="xs">{orthoCoverage[currentAoiIndex].aoi}</Text>
            <Text size="xs">{orthoCoverage[currentAoiIndex].childAoi}</Text>
          </Stack>
        </Group>
      )}
    </Stack>
  )
}
