import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { apiUrl } from '@/utils/constants.ts'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Polygon } from 'geojson'

const SKYSAT_TYPE = 'SkySatScene'

export const makePlanetOrthoLayer = (ir: ImageryResult): OrthoImageryLayer => {
  const planetType =
    /item-types\/([^/]*)\//.exec(ir.thumbnail)?.[1] || SKYSAT_TYPE
  const planetId = ir.properties['_dId'] || ir.id
  return {
    source: 'Planet Labs' as const,
    formattedSource: 'Planet Labs',
    score: ir.score,
    id: `${planetType}/${planetId}`,
    tileUrl: `${apiUrl}/provider/planet/data/v1/${planetType}/${planetId}/{z}/{x}/{y}.png`,
    authoredOn: ir.authoredOn,
    geometry: ir.geometry as Polygon,
    imageResult: ir,
    groupKey:
      ir.properties._dId?.split('_').slice(0, 2).join('_') ||
      ir.properties._dId ||
      ir.id,
  }
}

export const isPlanet = (ir: ImageryResult) =>
  ir.source.toLowerCase() === 'planet labs'
export const usePlanet = () => {
  const imageResults = usePostfilterImageResults()

  const planetOrthoLayers = imageResults
    .filter(isPlanet)
    .map(makePlanetOrthoLayer)

  return { planetOrthoLayers }
}
